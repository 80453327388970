import React, { act, useContext, useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, CircularProgress, Typography, Tooltip, Divider } from '@mui/material';
import { UserContext } from 'src/contexts/UserContext';
import { generateClient, get } from 'aws-amplify/api';
import { esQuery, searchCampaignLogs } from 'src/graphql/queries';
import { BarChart } from "@mui/x-charts/BarChart";
import { actionTwoButtonStyle } from 'src/theme';
import { InfoOutlined, PercentOutlined, PersonOutline, PhoneOutlined } from '@mui/icons-material';


const PenetrationDialog = ({ open, campaignId, totalContacts, freshCount, onClose }) => {
	const userContext = useContext(UserContext);
	const client = generateClient();
	const [aggCounts, setAggCounts] = useState([]);
	const [loading, setLoading] = useState(false);
	const [lastStartDate, setLastStartDate] = useState();
	const [totalDials, setTotalDials] = useState(0);

	useEffect(() => {
		if (open && campaignId) {
			getPenetrationData();
		}
	}, [open, campaignId]);

	function returnQuery(startDate, endDate) {

		return {
			"aggs": {
				"contact": {
					"terms": {
						"field": "activityLogContactId.keyword",
						"size": 2147483647
					}
				}
			},
			"query": {
				"bool": {
					"must": [
						{
							"match_phrase": {
								"type": "Dial"
							}
						},
						{
							"term": {
								"tenant.keyword": userContext.tenantId
							}
						},
						{
							"match_phrase": {
								"activityLogCampaignId": campaignId
							}
						},
						{
							"range": {
								"createdAt": {
									"gte": startDate,
									"lte": endDate
								}
							}
						}
					]
				}
			},
			"size": 0
		}
	}

	async function getLastCampaignStartDate() {
		const response = await client.graphql({
			query: searchCampaignLogs,
			variables: {
				filter: {
					campaign: { eq: campaignId },
					type: { eq: "Load" }
				}
			}
		});
		const campaignLogs = response.data.searchCampaignLogs.items;
		let lastStartDate;
		// Compare each log and get the last start date
		for (const log of campaignLogs) {
			if (!lastStartDate || new Date(log.createdAt) > new Date(lastStartDate)) {
				lastStartDate = log.createdAt;
			}
		}
		setLastStartDate(lastStartDate);
		return lastStartDate;
	}

	async function getPenetrationData() {
		setLoading(true);
		getLastCampaignStartDate();
		const response = await client.graphql({
			query: esQuery,
			variables: {
				model: "activitylog",
				action: "_search",
				query: JSON.stringify(returnQuery(await getLastCampaignStartDate(), new Date().toISOString()))
			}
		});

		const data = JSON.parse(response.data.esQuery).aggregations.contact.buckets;

		let callCount = 0;
		let attemptAggregations = {};

		// Aggregate how many times contacts have been dialed
		for (const contact of data) {
			callCount += contact.doc_count;
			attemptAggregations[contact.doc_count] = attemptAggregations[contact.doc_count] ? attemptAggregations[contact.doc_count] + 1 : 1;
		}

		const chartData = [];
		for (const key in attemptAggregations) {
			chartData.push({ contacts: key, count: attemptAggregations[key] });
		}

		if (freshCount && freshCount > 0) {
			chartData.push({ contacts: 0, count: freshCount });
		}

		setAggCounts(chartData);
		setTotalDials(callCount);
	}

	useEffect(() => {
		setLoading(false);
	}, [aggCounts]);

	return (
		<Dialog open={open} onClose={onClose} maxWidth="xl" >
			<DialogTitle>
				<Box display="flex" gap={2} alignItems="center">
					<Typography variant="h6">Penetration Details</Typography>
					<Tooltip title={`Penetration numbers are based on counts from the last campaign start/reset: ${new Date(lastStartDate).toLocaleDateString()}`} >
						<InfoOutlined color="primary" />
					</Tooltip>
				</Box>
			</DialogTitle>
			<DialogContent sx={{ padding: 3 }}>
				<Box sx={{ display: 'flex', justifyContent: 'center' }}>

					{!loading &&
						<Box display="grid" gridTemplateColumns="2fr 1fr" gap={2} alignItems="center">
							<BarChart
								dataset={aggCounts}
								yAxis={[{ scaleType: "band", dataKey: "contacts" }]}
								series={[{ dataKey: "count", color: '#b9cde8' }]}
								leftAxis={null}
								layout="horizontal"
								borderRadius={50}
								grid={{ vertical: true }}
								height={300}
								width={700}
								barLabel={(item) => {
									// Label the contact key
									const key = aggCounts[item.dataIndex].contacts;
									return `${key} Dial${key > 1 ? "s" : ""}`;
								}}
							/>
							<Box display="flex" flexDirection="column" gap={2}>
								<Box display="flex" gap={2} alignItems="center">
									<PhoneOutlined color="primary" fontSize="large" />
									<Box display="flex" flexDirection="column">
										<Typography variant="h4">{totalDials} Dials</Typography>
										<Typography variant="h6">{totalContacts} Total Contacts</Typography>
									</Box>
								</Box>
								<Divider />
								<Box display="flex" gap={2} alignItems="center">
									<PersonOutline color="primary" fontSize="large" />
									<Box display="flex" flexDirection="column">
										<Typography variant="h6">Average Calls Per Contact</Typography>
										<Typography variant="h4">{(totalDials / totalContacts).toFixed(2)}</Typography>
									</Box>
								</Box>
							</Box>


						</Box>
					}
					{loading && <CircularProgress color='primary' />}
				</Box>
			</DialogContent>
			<DialogActions>
				<Button sx={actionTwoButtonStyle} onClick={onClose} color="primary">
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default PenetrationDialog;
