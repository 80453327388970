import { Box, CircularProgress, Dialog, DialogTitle, DialogContent, FormControl, MenuItem, InputLabel, Select, Button, Typography, Card, CardMedia, CardContent, List, ListItem, IconButton, Tooltip, Tabs, Tab, Fade } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../contexts/UserContext";
import { generateClient, get } from "aws-amplify/api";
import { campaignsByTenant, getContact } from "src/graphql/queries";
import { fetchAuthSession } from "aws-amplify/auth";
import { PubSub } from '@aws-amplify/pubsub';
import { IS_PROD } from "src/models/globals";
import { CallStatusPane } from "src/components/engagement/callStatusPane";
import { actionTwoButtonStyle, tabStyle } from "src/theme";
import { DeleteForeverOutlined, PersonOutline, PhoneOutlined, ReplayOutlined, RestoreOutlined } from "@mui/icons-material";
import { ActivityLog } from "src/components/contacts/activityLog";

export function PreviewDialing(props) {
	const client = generateClient();

	/** The user context state */
	const userContext = useContext(UserContext);

	/** Whether something is loading */
	const [loading, setLoading] = useState(true);

	/** Campaigns agent can preview dial from */
	const [campaigns, setCampaigns] = useState([]);

	/** Which Campaign Agent is dialing for */
	const [dialingCampaign, setDialingCampaign] = useState('');

	const [retrievingContact, setRetrievingContact] = useState(false);

	/** The full active contact data being preview dialed */
	const [activeContact, setActiveContact] = useState('');

	const [callingContact, setCallingContact] = useState(false);

	const [callStatus, setCallStatus] = useState({});

	const [phoneFields, setPhoneFields] = useState([]);

	const [currentTab, setCurrentTab] = useState(0);

	/** The hash of the active contact */
	const contactHash = useRef(null);


	const pubsubRef = useRef(
		new PubSub({
			region: IS_PROD ? 'us-east-2' : 'us-east-1',
			endpoint: IS_PROD ? 'wss://a3b8mw0zsvy66t-ats.iot.us-east-2.amazonaws.com/mqtt' : 'wss://a3b8mw0zsvy66t-ats.iot.us-east-1.amazonaws.com/mqtt',
		})
	);

	const callStatusSubscription = useRef(null);

	useEffect(() => {
		if (userContext.apiKey) {
			getCampaigns();
		}
		const tempPhoneFields = ['phone', 'cell', ...userContext.customFields.filter(field => field.type === 'Phone').map(field => field.name)];
		setPhoneFields(tempPhoneFields);
	}, [userContext.apiKey]);

	useEffect(() => {
		setLoading(false);
	}, [campaigns]);

	useEffect(() => {
		if (dialingCampaign && !activeContact && !contactHash.current) {
			getContactForDialing();
		}
	}, [dialingCampaign, activeContact]);

	useEffect(() => {
		subscribeToCallStatus();
	}, [dialingCampaign])

	async function subscribeToCallStatus() {
		try {
			callStatusSubscription.current?.unsubscribe();
			callStatusSubscription.current =
				pubsubRef.current.subscribe({
					topics: `approvals/${process.env.REACT_APP_ENV}/${userContext.tenantId}/agent-call-statuses/${props.agentId}`,
				}).subscribe({
					next: (message) => {
						handleCallStatusChangeMessage(message);
					},
					error: (error) => {
						console.error('Error subscribing to call status', error);
					},
					complete: () => {
						console.log('Call Status subscription complete');
					}
				});

		} catch (error) {
			console.error('Error subscribing to call status', error);
		}
	}

	/**
 * Updates the call status on the approved contact when the status changes.
 *
 * @param {*} message The message received from the WebSocket.
 */
	async function handleCallStatusChangeMessage(message) {
		console.log('Call status change message received', message);
		setCallStatus({ callStatus: message.callStatus });
		return;

	}

	async function getCampaigns() {
		try {
			setLoading(true);
			const idToken = (await fetchAuthSession()).tokens.idToken;;

			const campaignsResponse = await client.graphql({
				query: campaignsByTenant,
				variables: {
					tenant: idToken.payload['custom:tenant'],
					filter: { status: { eq: 'Active' } },
					limit: 1000
				},
			});

			const returnedCampaigns = campaignsResponse.data.campaignsByTenant?.items ?? [];

			// Filter campaigns to previewDialing on the profile and the agent has the required skills
			let filteredCampaigns = [];
			for (let campaign of returnedCampaigns) {
				console.log(campaign);
				if (campaign.profile.previewDialing && props.agentSkillIds.includes(campaign.skillId)) {
					filteredCampaigns.push(campaign);
				}
			}
			setCampaigns(filteredCampaigns);

		} catch (error) {
			console.error(error);
		}
	}

	async function getContactForDialing() {
		try {
			setRetrievingContact(true);
			const contactRequest = await get({
				apiName: 'cdyxoutreach',
				path: `/contacts/${dialingCampaign.skillId}`,
				options: {
					headers: {
						Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken}`,
						'x-api-key': userContext.apiKey
					},
					queryParams: {
						total: 1
					}
				}
			}).response;

			try {
				const contactHashResponse = await contactRequest.body.json();
				console.log(contactHashResponse);
				contactHash.current = contactHashResponse[0];
				const fullContact = await getDynamoContact(contactHashResponse[0].id);
				setActiveContact(fullContact);
			} catch (err) {
				console.log('No contact available for dialing');
			}

		} catch (error) {
			console.error(error);
		}
		setRetrievingContact(false);
	}

	async function getDynamoContact(contactId) {
		try {
			const contactRequest = await client.graphql({
				query: getContact,
				variables: {
					id: contactId
				}
			});
			return contactRequest.data.getContact;
		} catch (err) {
			console.error('Unable to get contact from DynamoDB', err);
		}
	}

	async function handleContactAction(phoneNumber, type) {
		try {
			setCallingContact(true);

			contactHash.current.phone = phoneNumber;

			await pubsubRef.current.publish({
				topics: [`approvals/${process.env.REACT_APP_ENV}/${userContext.tenantId}/${dialingCampaign.id}`],
				message: {
					type,
					skillId: dialingCampaign.skillId,
					agent: props.agentId,
					...contactHash.current,
					phone: phoneNumber,
					jwtToken: (await fetchAuthSession()).tokens.idToken,
					apiKey: userContext.apiKey,
					preview: true
				}
			});

			if (type !== 'approve') {
				setActiveContact('');
				setCallingContact(false);
				setCallStatus({});
				contactHash.current = null;
			}

		} catch (error) {
			console.error('Error calling contact', error);
		}
	}

	async function closePreview() {
		if (activeContact) {
			await handleContactAction(contactHash.current.phone, 'recycle');
			setActiveContact('');
		}
		callStatusSubscription.current.unsubscribe();
		setDialingCampaign('');
		setCallingContact(false);
		setCallStatus({});
		contactHash.current = null;
		props.setDialMode('');
	}

	function getPhoneLabel(field) {
		switch (field) {
			case 'phone':
				return 'Phone';
			case 'cell':
				return 'Cell';
			default:
				userContext.customFields.find((customField) => {
					if (customField.name === field) {
						return customField.label;
					}
				});
		}
	}


	return (
		<>

			{/* Preview Dialing */}
			<Box sx={{ width: '100%' }} display="flex" flexDirection="column" alignItems="center" justifyContent="space-around" gap={2}>
				<Box sx={{ maxWidth: '1200px', width: '100%' }} display="flex" flexDirection="column" alignItems="center" gap={2}>

					{/* Campaign Control */}

					<Box sx={{ width: '100%', padding: '10px' }} display="grid" gridTemplateColumns="5fr 1fr" gap={2} alignItems="center">
						<FormControl>
							<InputLabel>Dialing Campaign</InputLabel>
							<Select
								label="Dialing Campaign"
								value={dialingCampaign}
								onChange={(e) => setDialingCampaign(e.target.value)}
							>
								{campaigns.map((campaign) => (
									<MenuItem key={campaign.id} value={campaign}>{campaign.name}</MenuItem>
								))}
							</Select>
						</FormControl>
						{!callingContact && <Button onClick={closePreview} sx={actionTwoButtonStyle}>Close</Button>}
					</Box>

					{/* Contact Control */}

					<Box sx={{ width: '100%', padding: '10px' }} display="flex" flexDirection="column" alignItems="center">
						<Card sx={{ width: '100%', borderRadius: '7px' }} variant="outlined">
							<CardMedia sx={{ height: '200px', backgroundColor: '#b9cde8' }}>
								<Box height="100%" >
									{!dialingCampaign && <Fade in={!dialingCampaign}>
										<Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' sx={{ height: '100%' }}>
											<Typography variant="h5">Select a campaign to begin dialing</Typography>
										</Box>
									</Fade>}
									{retrievingContact && <Fade in={retrievingContact}>
										<Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' sx={{ height: '100%' }}>
											<Typography variant="h5">Getting Contact...</Typography>
										</Box>
									</Fade>}
									{callingContact &&
										<CallStatusPane
											contact={callStatus}
											onDismiss={() => {
												setActiveContact('');
												setCallingContact(false);
												setCallStatus({});
												contactHash.current = null;
											}}
										/>
									}
								</Box>
							</CardMedia>
							<Box sx={{ padding: '10px' }} display="grid" gridTemplateColumns="1fr 1fr 1fr">
								<Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
									{/* <List> */}
									{/* Display the phone field if it exists on the contact */}
									{phoneFields.map((field) => {
										if (activeContact && activeContact[field]) {
											return (
												<ListItem key={field} secondaryAction={!callingContact &&
													// <Tooltip title={`Call ${getPhoneLabel(field)}`} >
													<IconButton sx={[
														{ backgroundColor: '#1559b3' },
														{ '&:hover': { backgroundColor: '#0c3e9a', } }
													]} edge="end" onClick={() => handleContactAction(activeContact[field], 'approve')}>
														<PhoneOutlined fontSize="small" color="white" />
													</IconButton>
													// </Tooltip>
												}>
													<Box display="flex" flexDirection="column">
														<Typography variant="body1">{activeContact[field]}</Typography>
														<Typography variant="body2">{getPhoneLabel(field)}</Typography>
													</Box>
												</ListItem>
											);
										}
									})}
									{/* </List> */}
								</Box>
								<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
									<Typography variant="h5">{activeContact.firstName} {activeContact.lastName}</Typography>
									{activeContact.externalId && <Typography variant="h6">{`External ID: ${activeContact.externalId}`}</Typography>}
								</Box>
								<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
									{activeContact && !callingContact && <Box display="flex" flexDirection="row" gap={2} justifyContent="center">
										<Tooltip title="Recycle the contact to be presented at a later time">
											<IconButton sx={[
												{ backgroundColor: '#1559b3' },
												{ '&:hover': { backgroundColor: '#0c3e9a', } }
											]} onClick={() => handleContactAction(contactHash.current.phone, 'recycle')}>
												<ReplayOutlined fontSize="small" color="white" />
											</IconButton>
										</Tooltip>
										<Tooltip title="Suppress the contact from being presented again">
											<IconButton
												sx={[
													{ backgroundColor: '#f44336' },
													{ '&:hover': { backgroundColor: '#d32f2f', } }
												]}
												onClick={() => handleContactAction(contactHash.current.phone, 'suppress')}>
												<DeleteForeverOutlined fontSize="small" color="white" />
											</IconButton>
										</Tooltip>
									</Box>}
								</Box>
							</Box>
							<Box sx={{ backgroundColor: '#f2f6fa', borderRadius: '7px' }} display="flex" flexDirection="row" justifyContent="flex-end">
								{activeContact && <Tabs
									value={currentTab}
									onChange={(e, newValue) => setCurrentTab(newValue)}
									indicatorColor="primary"
									textColor="primary"
								>
									<Tab iconPosition="start" icon={<PersonOutline />} sx={tabStyle} label='Contact Info'></Tab>
									<Tab iconPosition="start" icon={<RestoreOutlined />} sx={tabStyle} label='Activity Log'></Tab>
								</Tabs>}
							</Box>
						</Card>
					</Box >

					{/* Contact Info */}
					{
						activeContact && <Box sx={{ width: '100%', padding: '10px' }} display="flex" flexDirection="column" alignItems="center">
							<Card elevation={0} sx={{ width: '100%' }}>
								<Box>
									{currentTab === 0 && <Box display="grid" gridTemplateColumns="1fr 2fr" gap={2}>
										<Card variant="outlined">
											<CardContent>
												<Typography variant="h5">Contact Info</Typography>
												<List>
													{activeContact?.source && <ListItem>
														<Box display="flex" flexDirection="column">
															<Typography variant="body1">{activeContact.source ? activeContact.source : 'No Source'}</Typography>
															<Typography variant="body2">Source</Typography>
														</Box>
													</ListItem>}
													{activeContact?.email && <ListItem>
														<Box display="flex" flexDirection="column">
															<Typography variant="body1">{activeContact.email ? activeContact.email : 'No Email'}</Typography>
															<Typography variant="body2">Email</Typography>
														</Box>
													</ListItem>}
													{activeContact?.address1 && <ListItem>
														<Box display="flex" flexDirection="column">
															<Typography variant="body1">{activeContact.address1 ? activeContact.address1 : 'No Address 1'}</Typography>
															<Typography variant="body2">Address 1</Typography>
														</Box>
													</ListItem>}
													{activeContact?.address2 && <ListItem>
														<Box display="flex" flexDirection="column">
															<Typography variant="body1">{activeContact.address2 ? activeContact.address2 : 'No Address 2'}</Typography>
															<Typography variant="body2">Address 2</Typography>
														</Box>
													</ListItem>}
													{activeContact?.city && <ListItem>
														<Box display="flex" flexDirection="column">
															<Typography variant="body1">{activeContact.city ? activeContact.city : 'No City'}</Typography>
															<Typography variant="body2">City</Typography>
														</Box>
													</ListItem>}
													{activeContact?.state && <ListItem>
														<Box display="flex" flexDirection="column">
															<Typography variant="body1">{activeContact.state ? activeContact.state : 'No State'}</Typography>
															<Typography variant="body2">State</Typography>
														</Box>
													</ListItem>}
													{activeContact?.zip && <ListItem>
														<Box display="flex" flexDirection="column">
															<Typography variant="body1">{activeContact.zip ? activeContact.zip : 'No Zip'}</Typography>
															<Typography variant="body2">Zip</Typography>
														</Box>
													</ListItem>}
													{!activeContact.email && !activeContact.address1 && !activeContact.address2 && !activeContact.city && !activeContact.state && !activeContact.zip &&
														<Typography sx={{ fontStyle: 'italic' }} variant="body1">No Additional Contact Info</Typography>}
												</List>
											</CardContent>
										</Card>
										<Card variant="outlined">
											<CardContent>
												<Typography variant="h5">Custom Fields</Typography>
												<Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap={2}>
													{userContext.customFields.map((field) => {
														if (activeContact[field.name] && field.type !== 'Phone') {
															return (
																<ListItem key={field.name}>
																	<Box display="flex" flexDirection="column">
																		<Typography variant="body1">{activeContact[field.name]}</Typography>
																		<Typography variant="body2">{field.name}</Typography>
																	</Box>
																</ListItem>
															);
														}
													})}
												</Box>
											</CardContent>
										</Card>

									</Box>}
									{currentTab === 1 &&
										<Card variant="outlined">
											<Box sx={{ padding: '10px' }}>
												<ActivityLog contactId={activeContact.id} />
											</Box>
										</Card>}
								</Box>
							</Card>
						</Box>
					}
				</Box >
			</Box >
		</>
	)
}
