import { useState, useContext, useEffect, useRef } from 'react';
import { generateClient, get, post } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';
import { enqueueSnackbar } from 'notistack';
import {
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	Box,
	TextField,
	Typography,
	Card,
	CardContent,
	Button,
	CardActions,
	useMediaQuery
} from '@mui/material';
import { actionTwoButtonStyle, cardStyle } from 'src/theme';
import { Formik } from 'formik';
import { UserContext } from '../../contexts/UserContext';
import {
	tenantMappings,
	ftpImportsByTenant,
} from '../../graphql/queries';
import { actionOneButtonStyle, formFieldStyle, selectInputStyle } from '../../theme';
// import { useSnackbar } from 'notistack';
import { object, string, number } from 'yup';
import { TextAreaField } from '@aws-amplify/ui-react';
import MaterialTable from '@material-table/core';
import { ConfirmDialog } from '../confirmDialog/confirmDialog';
import { createFtpImport, deleteFtpImport, updateFtpImport } from 'src/graphql/mutations';
import moment from 'moment';
import { DeleteOutlineOutlined, EditOutlined } from '@mui/icons-material';


/**
 * This page component handles uploading CSV files to an S3 bucket so we can then process them with
 * a lambda function and insert the rows in the database.
 *
 * The UI contains the ability to specify how fields from their files can be mapped to our existing fields.
 * @category Pages
 * @component
 * @param {any} props React props object
 */
export function SFTPImport(props) {
	const useSingleColumn = useMediaQuery('(max-width:845px)');
	const client = generateClient();
	const userContext = useContext(UserContext);
	const newConnection = { name: '', host: '', username: '', password: '', path: '', privateKey: '', port: '', fieldMappingId: '', reportEmail: '', delimiter: ',' };
	const [fieldMappings, setFieldMappings] = useState([]);
	const [testing, setTesting] = useState(false);
	const [testSuccessful, setTestSuccessful] = useState(false);
	const [connections, setConnections] = useState([]);
	const [connection, setConnection] = useState(null);

	const [loading, setLoading] = useState(false);
	const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
	const [toDelete, setToDelete] = useState(null);

	async function getConnections() {
		setLoading(true);
		const data = await client.graphql({
			query: ftpImportsByTenant,
			variables: { tenant: userContext.tenantId }
		});

		setConnections(data.data.ftpImportsByTenant.items);
}

useEffect(() => {
	async function getData() {
		const promises = [];

		promises.push(getConnections());

		promises.push(client.graphql({
			query: tenantMappings,
			variables: { tenant: userContext.tenantId }
		}));

		await Promise.all(promises).then((results) => {
			setFieldMappings(results[1].data.tenantMappings.items);
		});

	}

	if (userContext.tenantId) {
		getData();
	}

}, [userContext.tenantId]);

useEffect(() => {
	setLoading(false);
}, [connections]);

useEffect(() => {
	if (props.newConnection) {
		setConnection(newConnection);
	}
}, [props.newConnection]);


const testConnection = (values) => async () => {
	if (values.username === '' || values.host === '' || (values.password === '' && values.privateKey === '')) {
		enqueueSnackbar('You must provide a URL, UserName, and either a sftp key or a password for a valid connection', {
			variant: 'error',
			autoHideDuration: 5000
		});
	}
	else {
		try {
			setTesting(true);
			setTestSuccessful(false);
			const conxResponse = await post({
				apiName: 'cdyxoutreach',
				path: '/ftpcheck',
				options: {
					headers: {
						Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken}`,
						'x-api-key': userContext.apiKey
					},
					body: values
				}
			}).response;

			const response = await conxResponse.body.json();
			if (response.success) {
				enqueueSnackbar('Connection Test Successful!', {
					autoHideDuration: 3500
				});
				setTestSuccessful(true);
			}
			else {
				enqueueSnackbar('Connection unsuccessful, Please check connection settings.', {
					variant: 'error',
					autoHideDuration: 5000
				});
				setTestSuccessful(false);
			}
			setTesting(false);
		} catch (err) {
			console.log(err);
			enqueueSnackbar('Connection unsuccessful, Please check connection settings.', {
				variant: 'error',
				autoHideDuration: 5000
			});
			setTestSuccessful(false);
		}
	}
}

async function handleDelete() {
	try {
		await client.graphql({
			query: deleteFtpImport,
			variables: {
				input: {
					id: toDelete,
				}
			}
		});

		enqueueSnackbar('Connection Deleted', {
			variant: 'success',
			autoHideDuration: 3500
		});
		setConfirmDeleteOpen(false);
		setToDelete(null);
		await getConnections();

	} catch (err) {
		enqueueSnackbar('Delete Failed. ' + err, {
			variant: 'error',
			autoHideDuration: 3500
		});
	}
}


return (
	<Box>
		{connection && <Formik
			initialValues={connection}
			enableReinitialize={true}
			validationSchema={object(
				{
					name: string()
						.required('You must provide a name for this connection'),
					host: string()
						.min(4, 'The Import Host must be a valid domain or IP')
						.max(80, 'The Import Host must be a valid domain or IP'),
					username: string()
						.required('You must provide a user name'),
					privateKey: string()
						.min(256, 'The Import Key must be at least 256 Characters long'),
					password: string()
						.min(8, 'the password must be at least 8 characters').nullable(),
					port: number()
						.min(0, "Ports must be at least 0.")
						.max(65535, 'Ports must be at most 65535').nullable(),
					fieldMappingId: string()
						.required('A mapping is required to handle importing contacts')
				}
			)}
			onSubmit={async values => {
				//dynamodb doesn't like empty strings turn empty into null
				try {
					for (const key in values) {
						if (values[key] === '') {
							values[key] = null;
						}
					}

					if (!values.id) {
						await client.graphql({
							query: createFtpImport,
							variables: {
								input: {
									...values,
									lastCheck: moment().unix(),
									tenant: userContext.tenantId,
								}
							}
						});

						enqueueSnackbar('Connection Created', {
							variant: 'success',
							autoHideDuration: 3500
						});
					} else {
						delete values.tableData;
						delete values.createdAt;
						delete values.updatedAt;
						await client.graphql({
							query: updateFtpImport,
							variables: {
								input: { ...values }
							}
						});
						enqueueSnackbar('Connection Updated', {
							variant: 'success',
							autoHideDuration: 3500
						});
					}

					await getConnections();
					props.setNewConnection(false);
					setConnection(null);
					setTestSuccessful(false);

				} catch (err) {
					enqueueSnackbar('Save Failed. ' + err);
				}

			}}
		>
			{formikprops => (
				<form onSubmit={formikprops.handleSubmit}>

					<Card
						style={cardStyle}
						elevation={0}
					>
						<CardContent>
							<Box display='flex' sx={{
								flexDirection: 'column',
							}} >
								<Typography variant="overline">Connection</Typography>
								<Box display='flex' flexDirection='column'>
									<Box display="grid"
										gridTemplateColumns={useSingleColumn ? "1fr" : "1fr 1fr"}
										gap="20px">
										<TextField
											label="Connection Name"
											color="primary"
											margin="dense"
											name="name"
											type="text"
											onChange={formikprops.handleChange}
											onBlur={formikprops.handleBlur}
											value={formikprops.values.name}
											error={formikprops.errors.name && formikprops.touched.name}
										/>

										<TextField
											label="SFTP Host"
											color="primary"
											margin="dense"
											name="host"
											type="text"
											onChange={formikprops.handleChange}
											onBlur={formikprops.handleBlur}
											value={formikprops.values.host}
											error={formikprops.errors.host && formikprops.touched.host}
											helperText={formikprops.errors.host} />

										<TextField
											label="Port"
											color="primary"
											margin="dense"
											aria-label='Port'
											name="port"
											type="number"
											onChange={formikprops.handleChange}
											onBlur={formikprops.handleBlur}
											value={formikprops.values.port}
											error={formikprops.errors.port && formikprops.touched.port}
											helperText={formikprops.errors.port} />

										<TextField
											label="Path"
											color="primary"
											margin="dense"
											aria-label='Folder'
											name="path"
											type="text"
											onChange={formikprops.handleChange}
											onBlur={formikprops.handleBlur}
											value={formikprops.values.path}
											error={formikprops.errors.path && formikprops.touched.path}
											helperText={formikprops.errors.path} />

										<TextField
											color="primary"
											margin="dense"
											name="username"
											label="User Name"
											type="text"
											onChange={formikprops.handleChange}
											onBlur={formikprops.handleBlur}
											value={formikprops.values.username}
											error={formikprops.errors.username && formikprops.touched.username}
											helperText={formikprops.errors.username} />

										<TextField
											color="primary"
											margin="dense"
											name="password"
											label="Password"
											type="password"
											onChange={formikprops.handleChange}
											onBlur={formikprops.handleBlur}
											value={formikprops.values.password}
											error={formikprops.errors.password && formikprops.touched.password}
											helperText={formikprops.errors.password} />

									</Box>


									<TextAreaField
										sx={{ width: '100%' }}
										color="primary"
										margin="dense"
										name="privateKey"
										label="Private Key"
										type="text"
										onChange={formikprops.handleChange}
										onBlur={formikprops.handleBlur}
										value={formikprops.values.privateKey}
										error={formikprops.errors.privateKey && formikprops.touched.privateKey}
										helperText={formikprops.errors.privateKey} />

									<br></br><br></br>

									<Typography variant="overline">Configuration</Typography>

									<Box display="grid" alignItems='center'
										gridTemplateColumns={useSingleColumn ? "1fr" : "1fr 1fr 1fr"}
										gap="20px">
										<TextField
											color="primary"
											margin="dense"
											name="reportEmail"
											label="Report Email"
											type="email"
											onChange={formikprops.handleChange}
											onBlur={formikprops.handleBlur}
											value={formikprops.values.reportEmail}
											error={formikprops.errors.reportEmail && formikprops.touched.reportEmail}
											helperText={formikprops.errors.reportEmail} />

										<FormControl>
											<InputLabel id="fieldMappingId">Field Mapping</InputLabel>
											<Select
												labelId="fieldMappingId"
												label="Field Mapping"
												name="fieldMappingId"
												value={formikprops.values.fieldMappingId}
												onChange={formikprops.handleChange}
												onBlur={formikprops.handleBlur}
												sx={{ minWidth: '200px' }}
												error={formikprops.errors.fieldMappingId && formikprops.touched.fieldMappingId}
											>
												{fieldMappings.map((mapping, index) => (
													<MenuItem key={index} value={mapping.id}>{mapping.name}</MenuItem>
												))}
											</Select>
										</FormControl>

										<TextField
											color="primary"
											margin="dense"
											name="delimiter"
											label="Delimiter"
											type="text"
											onChange={formikprops.handleChange}
											onBlur={formikprops.handleBlur}
											value={formikprops.values.delimiter}
											error={formikprops.errors.delimiter && formikprops.touched.delimiter}
											helperText={formikprops.errors.delimiter} />
									</Box>
								</Box>
							</Box>

						</CardContent>
						<CardActions>
							<Box width="100%"
								display="flex"
								flexDirection="row"
								justifyContent="end"
								alignItems="center">
								<Button sx={actionTwoButtonStyle} disabled={testing} onClick={() => {
									setConnection(null)
									props.setNewConnection(false);
									setTestSuccessful(false);
									}}>Cancel</Button>
								<Button sx={actionTwoButtonStyle} disabled={testing} onClick={testConnection(formikprops.values)}>Test Connection</Button>
								<Button sx={actionOneButtonStyle} disabled={!testSuccessful} type='submit' >Save</Button>
							</Box>
						</CardActions>
					</Card>
				</form>
			)}
		</Formik>}
		{!connection && <MaterialTable
			components={{
				Container: (props) => <Box {...props} elevation={0} />,
			}}
			title=""
			data={connections}
			columns={[{ title: 'Name', field: 'name' },
			{ title: 'Host', field: 'host' },
			{ title: 'Path', field: 'path' }
			]}
			isLoading={loading}
			options={{
				actionsColumnIndex: -1,
				pageSize: 10,
				searchFieldStyle: {
					marginBottom: '16px',
					marginleft: '-28px',
				},
				headerStyle: {
					borderTop: '1px solid #e0e0e0',
					padding: '16px',
				},
				searchFieldVariant: 'outlined',
				paginationType: 'stepped',
			}}
			actions={[
				{
					icon: () => <EditOutlined color="primary" />,
					tooltip: 'Edit Connection',
					onClick: (event, rowData) => {
						setConnection(rowData);
					},
				},
				{
					icon: () => <DeleteOutlineOutlined color="primary" />,
					tooltip: 'Delete DNC List',
					onClick: (event, rowData) => {
						setToDelete(rowData.id);
						setConfirmDeleteOpen(true);
					},
				},
			]}
		/>}

		<ConfirmDialog
			open={confirmDeleteOpen}
			title="Delete Connection"
			description="Are you sure you want to delete this SFTP Connection?"
			actionOneText="Delete"
			actionOneHandler={() => {
				handleDelete();
			}}
			actionTwoText="Cancel"
			actionTwoHandler={() => {
				setConfirmDeleteOpen(false);
				setToDelete(null);
			}}
		/>
	</Box >
);
}
