import { string, object, number, mixed, boolean } from 'yup';
import phone from 'phone';
import { IS_PROD } from '../models/globals';

const phoneBase = (label, country, canBeNull) => string()
    .test(
        `${label} test`, `\${value} is not a valid ${label} number`,
        val => val ? validateNumber(val, country) : canBeNull
    )
    .transform(x => {
        try {
            if (x.startsWith("+")) {
                return x;
            }
            const number = phone(x, { country, validateMobilePrefix: false })
            return number.isValid ? number.phoneNumber : x;
        } catch (error) {
            console.error("Error transforming phone number: ", error);
            return x;
        }
    });

function customFieldVerification(type, country) {
	switch (type) {
		case "Phone":
			return phoneBase("phone", country, true).nullable();
		case "String":
			return string().nullable();
		case "DateTime":
		// This will be in epoch time or a string, so we don't need to do anything to it, just
		return;
		case "Boolean":
			return boolean().nullable().transform(x => {
				const lower = `${x}`.toLowerCase();
				if (lower === 'true' || lower === '1') {
					return true;
				} else {
					return false;
				}
			});
		case "Number":
			return number().nullable().typeError(context => `${context.originalValue} is not a valid number`);
		default:
			return string().nullable();
	}

}

export const contactValidation = (customFields, country) => object().shape({
	firstName: string()
		.max(124, 'Source must be 124 characters or less')
		.required('Please supply a first name'),
	lastName: string()
		.max(124, 'Source must be 124 characters or less')
		.required('Please supply a last name'),
	source: string()
		.nullable()
		.max(40, 'Source must be 40 characters or less'),
	phone: phoneBase("phone", country, true)
		.when('cell', {
			is: (cell) => !cell || cell === "",
			then: () => phoneBase("phone", country, false).required('Please supply a phone number'),
			otherwise: () => phoneBase("phone", country, true).nullable(),
		}),
	cell: phoneBase("cell phone", country, true)
		.when('phone', {
			is: (phone) => !phone || phone === "",
			then: () => phoneBase("cell", country, false).required('Please supply a cell phone number'),
			otherwise: () => phoneBase("cell", country, true).nullable()
		}),
	address1: string()
		.nullable()
		.max(50, 'Address1 must be 50 characters or less'),
	address2: string()
		.nullable()
		.max(50, 'Address2 must be 50 characters or less'),
	city: string()
		.nullable(),
	state: string()
		.nullable()
		.transform(x => `${x}`.toUpperCase())
		.test({
			name: () => "Valid state",
			message: () => "${value} is an invalid state",
			test: () => val => Boolean(val) ? validStates.has(val) : true
		}),
	zip: string()
		.nullable()
		.matches(/^[0-9]{5}(?:-[0-9]{4})?$/, { message: '${value} is not a valid zip code', excludeEmptyString: true }),
	email: string()
		.nullable()
		.email('${value} is not a valid email address'),
	customFields: object((customFields ? customFields : []).reduce((schema, currentField) => Object.assign(schema, {
		[currentField.name]: (currentField.type === "String" && currentField.isPoc) ? customFieldVerification(currentField.type, country).email("${value} is not a valid email") : customFieldVerification(currentField.type, country)
	}), {}))
}, [['phone', 'cell']]);

const segmentValidation = object({
	name: string()
		.max(50, 'Segment name must be 20 characters or less')
		.required('Please supply a segment name'),
});

const cxOneValidation = object({
	businessUnit: string()
		.matches(/^\d+$/, 'Must only contain numbers')
		.max(9, 'Must be at most 9 digits long')
		.min(7, 'Must be at least 7 digits long')
		.required('Business unit is required'),
	accessKey: string()
		.max(80, 'Must be 80 characters or less')
		.required('Access key is required'),
	appSecret: string()
		.max(80, 'Must be 80 characters or less')
		.required('Application secret is required'),
	authTokenUrl: string()
		.max(80, 'Must be 80 characters or less')
		.url('Must be a valid URL')
});

export const webexCCValidation = object({
	region: string().required('Region is required'),
	organizationId: string()
		.max(80, 'Must be 80 characters or less')
		.required('Organization ID is required'),
	clientId: string()
		.max(80, 'Must be 80 characters or less')
		.required('Client ID is required'),
	clientSecret: string()
		.max(80, 'Must be 80 characters or less')
		.required('Client secret is required')
});

export const webexConnectValidation = object({
	region: string().required('Region is required'),
	serviceKey: string().required('Service key is required'),
});

const inContactValidation = object({
	icUserName: string()
		.nullable()
		.max(50, 'Must be 20 characters or less')
		.required('Please supply an inContact User Name'),
	icPassword: string()
		.nullable()
		.max(48, 'Must be 48 characters or less')
		.required('Please supply an inContact Password'),
	icBU: string()
		.nullable()
		.max(7, 'The Business Unit Number is 7 Characters Long')
		.min(7, 'The Business Unit Number is 7 Characters Long')
		.required('Please supply a business unit number')
		.matches(/^\d{7}$/, 'Please supply a valid business unit number'),
	icApp: string()
		.nullable()
		.max(20, 'Please Make your application name less than 20 characters')
		.required('Please supply an application name'),
	icVendor: string()
		.nullable()
		.max(50, 'Please Make your application name less than 20 characters')
		.required('Please supply a vendor name'),
	icGetAuthTokenURL: string()
		.nullable()
		.required('This field is required')
		.url('Please enter a valid authentication token URL'),
});

function validateNumber(number, country) {
	try {
		console.log('number', number);
		// Check to see if the phone number starts with a + sign
		if (number.startsWith("+")) {
			// If it does, return the phone number as is
			return phone(number, { validateMobilePrefix: false }).isValid;
		} else {
			return phone(number, { validateMobilePrefix: false, country }).isValid;
		}
	} catch (error) {
		console.error("Error validating phone number: ", error);
		return false;
	}
}

// eslint-disable-next-line
export { segmentValidation, cxOneValidation, inContactValidation };
