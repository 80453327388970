/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "cdyxmaxpromptpay",
            "endpoint": "https://j01talp6l9.execute-api.us-east-2.amazonaws.com/vlctyprod",
            "region": "us-east-2"
        },
        {
            "name": "cdyxoutreach",
            "endpoint": "https://tdg60x6olf.execute-api.us-east-2.amazonaws.com/vlctyprod",
            "region": "us-east-2"
        },
        {
            "name": "cdyxpromptpay",
            "endpoint": "https://hzsiw40un5.execute-api.us-east-2.amazonaws.com/vlctyprod",
            "region": "us-east-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://gkl2trcoqzgq5o2h43dlu72bvi.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-2:831d788f-2d2c-4442-8cee-236b5b1be661",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_b91IQjPbk",
    "aws_user_pools_web_client_id": "1a2k85k3bp34vsimenuu61vno8",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "ors-20201101153733-hostingbucket-vlctyprod",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "https://d3wf5sa184h5c.cloudfront.net",
    "aws_user_files_s3_bucket": "cdyxoutreach112946-vlctyprod",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
