import { useEffect, useState } from 'react';
import { Divider, Box, Tabs, Tab, Paper, Card, CardHeader, CardContent } from '@mui/material';
import { FileImport } from '../../components/contacts/fileImport';
import { PageAppBar } from 'src/components/pageAppBar';
import { cardHeader, cardStyle, tabStyle } from 'src/theme';
import { SFTPImport } from 'src/components/contacts/sftpImport';
/**
 * This page component handles uploading CSV files to an S3 bucket so we can then process them with
 * a lambda function and insert the rows in the database.
 *
 * The UI contains the ability to specify how fields from their files can be mapped to our existing fields.
 * @category Pages
 * @component
 * @param {any} props React props object
 */
export function Import() {
	// const classes = useStyles();

	const [currentTab, setCurrentTab] = useState(0);
	const [newConnection, setNewConnection] = useState(false);

	return (
		<Box>
			<PageAppBar
				title="Import Contact Data"
				description="Import contact data from a file or SFTP server."
				actionOneText={currentTab === 1 ? '+ Connection' : ''}
				actionOneHandler={() => {
					if(!newConnection) {
						setNewConnection(true);
					}
				}}
			/>
			<Tabs
				value={currentTab}
				indicatorColor='primary'
				textColor='primary'
				onChange={(e, newValue) => setCurrentTab(newValue)}
			>
				<Tab sx={tabStyle} label="File Import" />
				<Tab sx={tabStyle} label="SFTP Import" />
			</Tabs>
			{currentTab === 0 && <FileImport />}
			{currentTab === 1 && <SFTPImport newConnection={newConnection} setNewConnection={setNewConnection} />}

		</Box>
	);
}
