import { useEffect, useState, useContext } from 'react';
import {
	Button,
	Dialog,
	Card,
	CardContent,
	CardHeader,
	Divider,
	IconButton,
	MenuItem,
	Paper,
	Select,
	Tab,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tabs,
	TextField,
	ToggleButton,
	ToggleButtonGroup,
	DialogTitle,
	DialogActions,
	DialogContent,
	Grid,
	Typography,
	Box,
	FormControl,
	InputLabel
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { AddCircleOutline, DeleteOutline, } from '@mui/icons-material';
import { UserContext } from '../../contexts/UserContext';
import moment from 'moment';
import _, { set } from 'lodash';
import { generateClient, post } from 'aws-amplify/api';
import { updateCampaign } from 'src/graphql/mutations';
import { fetchAuthSession } from 'aws-amplify/auth';
import { actionOneButtonStyle, actionTwoButtonStyle, tabStyle } from 'src/theme';


/**
 * The ExportDialog component is used Allow users to choose how the export data
 * Expects No params our query string values on the route
 * * @category Components
 *  @component
 *
 */
const CampaignScheduleDialog = function (props) {
	const client = generateClient();
	const userContext = useContext(UserContext);
	const actions = [
		{ value: 'start', label: 'Start Campaign' },
		{ value: 'pause', label: 'Pause Campaign' },
		{ value: 'restart', label: 'Resume Campaign' },
		{ value: 'reset', label: 'Reset Campaign' },
		{ value: 'stop', label: 'End/Finalize Campaign' }
	];
	const [tab, setTab] = useState(0);
	const [campaign, setCampaign] = useState();
	const [newActionEvent, setNewActionEvent] = useState({
		time: '08:00',
		days: [],
		action: '',
		timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
	});
	const [newRatioEvent, setNewRatioEvent] = useState({
		time: '08:00',
		days: [],
		segments: [],
		timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
	});
	const [newReport, setNewReport] = useState({
		time: '10:00',
		days: [],
		type: '',
		timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
		channel: 'Dial',
		emailAddress: ''
	});
	const [actionEvents, setActionEvents] = useState([]);
	const [ratioEvents, setRatioEvents] = useState([]);
	const [reportEvents, setReportEvents] = useState([]);
	const [toDelete, setToDelete] = useState({});
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);



	useEffect(() => {
		if (props.campaign) {
			setCampaign(props.campaign);
			if (props.campaign.campaignSchedule) {
				setActionEvents(JSON.parse(props.campaign.campaignSchedule));
			} else {
				setActionEvents([]);
			}
			if (props.campaign.segmentSchedule) {
				setRatioEvents(JSON.parse(props.campaign.segmentSchedule));
			} else {
				setRatioEvents([]);
			}
			if (props.campaign.segments && props.campaign.segments.length > 0) {
				const campaignSegments = JSON.parse(JSON.stringify(props.campaign.segments));
				setNewRatioEvent({ ...newRatioEvent, segments: JSON.parse(campaignSegments) });
			}

			if (props.campaign.reportSchedule) {
				setReportEvents(JSON.parse(props.campaign.reportSchedule));
			} else {
				setReportEvents([]);
			}

		}
	}, [props.campaign]);

	/** Close refund dialog */
	async function closeDialog() {
		if (props.onCancel) {
			props.onCancel();
		}
	}

	async function addEvent(type) {
		try {
			if ((newActionEvent.time || newRatioEvent.time || newReport.time) && (newActionEvent.days.length || newRatioEvent.days.length || newReport.days) && (newActionEvent.action || newRatioEvent.segments.length || newReport.report)) {
				const eventId = Math.floor(100000 + Math.random() * 900000);


				let body = {
					tenant: userContext.tenantId,
					campaignId: campaign.id,
					eventType: type,
				}

				if (type === 'campaignStatus') {
					newActionEvent.id = eventId;
					body.event = newActionEvent;
				} else if (type === 'segmentRatio') {
					newRatioEvent.id = eventId;
					body.event = newRatioEvent;
				} else if (type === 'report') {
					newReport.id = eventId;
					body.event = newReport;
				}

				const create = await post({
					apiName: 'cdyxoutreach',
					path: '/approver/create-schedule',
					options: {
						headers: {
							Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken}`,
							'x-api-key': userContext.apiKey
						},
						body: {
							...body
						}
					}
				}).response;

				const result = await create.body.json();

				newActionEvent.scheduleArn = result.ScheduleArn;

				if (type === 'campaignStatus') {
					await client.graphql({
						query: updateCampaign,
						variables: {
							input: {
								id: campaign.id,
								campaignSchedule: JSON.stringify([...actionEvents, newActionEvent])
							}
						}
					});

					setActionEvents([...actionEvents, newActionEvent]);
					setNewActionEvent({ time: moment(newActionEvent.time, 'HH:mm').add(5, 'minutes').format('HH:mm'), days: newActionEvent.days, action: '', timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
				} else if (type === 'segmentRatio') {
					await client.graphql({
						query: updateCampaign,
						variables: {
							input: {
								id: campaign.id,
								segmentSchedule: JSON.stringify([...ratioEvents, newRatioEvent])
							}
						}
					});

					setRatioEvents([...ratioEvents, newRatioEvent]);
					setNewRatioEvent({ time: moment(newRatioEvent.time, 'HH:mm').add(5, 'minutes').format('HH:mm'), days: newRatioEvent.days, segments: newRatioEvent.segments, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });

				} else if (type === 'report') {
					await client.graphql({
						query: updateCampaign,
						variables: {
							input: {
								id: campaign.id,
								reportSchedule: JSON.stringify([...reportEvents, newReport])
							}
						}
					});

					setReportEvents([...reportEvents, newReport]);
					setNewReport({ time: moment(newReport.time, 'HH:mm').add(5, 'minutes').format('HH:mm'), days: newReport.days, report: '', timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, reportLength: 1 });

				}

			} else {
				enqueueSnackbar('Please fill out all fields', { variant: 'error' });
			}
		} catch (error) {
			console.error('Error adding event', error);
		}

	}

	async function deleteEvent() {
		try {
			let type = 'campaignStatus';
			if (toDelete.segments) {
				type = 'segmentRatio';
			} else if (toDelete.type) {
				type = 'report';
			}



			const deleteSchedule = await post({
				apiName: 'cdyxoutreach',
				path: '/approver/create-schedule',
				options: {
					headers: {
						Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken}`,
						'x-api-key': userContext.apiKey
					},
					body: {
						delete: true,
						tenant: userContext.tenantId,
						campaignId: campaign.id,
						event: toDelete
					}
				}
			}).response;

			const result = await deleteSchedule.body.json();


			if (type === 'campaignStatus') {
				const updatedEvents = actionEvents.filter(event => event.id !== toDelete.id);
				await client.graphql({
					query: updateCampaign,
					variables: {
						input: {
							id: campaign.id,
							campaignSchedule: JSON.stringify(updatedEvents)
						}
					}
				});

				setActionEvents(updatedEvents);
			} else if (type === 'segmentRatio') {
				const updatedEvents = ratioEvents.filter(event => event.id !== toDelete.id);
				await client.graphql({
					query: updateCampaign,
					variables: {
						input: {
							id: campaign.id,
							segmentSchedule: JSON.stringify(updatedEvents)
						}
					}
				});

				setRatioEvents(updatedEvents);
			} else if (type === 'report') {
				const updatedEvents = reportEvents.filter(event => event.id !== toDelete.id);
				await client.graphql({
					query: updateCampaign,
					variables: {
						input: {
							id: campaign.id,
							reportSchedule: JSON.stringify(updatedEvents)
						}
					}
				});

				setReportEvents(updatedEvents);
			}
		} catch (err) {
			enqueueSnackbar('Error deleting event', { variant: 'error' });
			console.error('Error deleting event', err);
		}
		setDeleteDialogOpen(false);
	}


	return (
		<div>
			<Dialog maxWidth="sm" open={props.open}>
				<DialogTitle id="dp-dialog-title">Scheduling {props.campaign?.name}</DialogTitle>
				<DialogContent>
					<Grid container>
						<Grid item xs={12}>
							<Tabs
								value={tab}
								indicatorColor="primary"
								textColor="primary"
								variant="fullWidth"
							>
								<Tab sx={tabStyle} onClick={() => setTab(0)} label="Campaign Actions" value={0} />
								<Tab sx={tabStyle} onClick={() => setTab(1)} label="Segment Ratios" value={1} />
								<Tab sx={tabStyle} onClick={() => setTab(2)} label="Reports" value={2} />
							</Tabs>
							{tab === 0 &&
								<Box display="flex" flexDirection="column" gap={2}>
									<Card variant='outlined'>
										<CardHeader title='New Action Event' titleTypographyProps={{
											variant: "body2",
											color: "textSecondary",
										}} />
										<CardContent>
											<Box display="grid" gridTemplateColumns="1fr 1fr" gap={2} alignItems="center">
												<ToggleButtonGroup
													value={newActionEvent.days} onChange={(e, days) => setNewActionEvent({ ...newActionEvent, days })}
												>
													<ToggleButton value={0}>SU.</ToggleButton>
													<ToggleButton value={1}>MO.</ToggleButton>
													<ToggleButton value={2}>TU.</ToggleButton>
													<ToggleButton value={3}>WE.</ToggleButton>
													<ToggleButton value={4}>TH.</ToggleButton>
													<ToggleButton value={5}>FR.</ToggleButton>
													<ToggleButton value={6}>SA.</ToggleButton>
												</ToggleButtonGroup>

												<TextField
													variant='outlined'
													type='time'
													value={newActionEvent.time}
													onChange={e => setNewActionEvent({ ...newActionEvent, time: e.target.value })}
												/>

												<FormControl>
													<InputLabel id="action">Action</InputLabel>
													<Select
														labelId="action"
														label="Action"
														value={newActionEvent.action}
														onChange={e => setNewActionEvent({ ...newActionEvent, action: e.target.value })}
													>
														{actions.map(action => <MenuItem key={action.value} value={action.value}>{action.label}</MenuItem>)}
													</Select>
												</FormControl>

												<Button sx={actionOneButtonStyle} onClick={() => addEvent('campaignStatus')} variant='contained' color='primary'>Add Event</Button>

												<Typography variant='body2' color='textSecondary'>This event's timing is based off of the {Intl.DateTimeFormat().resolvedOptions().timeZone} timezone</Typography>

											</Box>
										</CardContent>
									</Card>
									<Card variant='outlined'>
										<CardHeader title='Scheduled Action Events' titleTypographyProps={{
											variant: "body2",
											color: "textSecondary",
										}}
										/>
										<CardContent>
											<Table>
												<TableHead>
													<TableRow>
														<TableCell>Time</TableCell>
														<TableCell>Timezone</TableCell>
														<TableCell>Days</TableCell>
														<TableCell>Action</TableCell>
														<TableCell>Delete</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{actionEvents.map(event => <TableRow key={event.id}>
														<TableCell>{moment(event.time, 'HH:mm').format('hh:mm a')}</TableCell>
														<TableCell>{event.timeZone}</TableCell>
														<TableCell>
															{event.days.includes(0) && <ToggleButton style={{ width: '1.5em', height: '1em' }}>SU.</ToggleButton>}
															{event.days.includes(1) && <ToggleButton style={{ width: '1.5em', height: '1em' }}>MO.</ToggleButton>}
															{event.days.includes(2) && <ToggleButton style={{ width: '1.5em', height: '1em' }}>TU.</ToggleButton>}
															{event.days.includes(3) && <ToggleButton style={{ width: '1.5em', height: '1em' }}>WE.</ToggleButton>}
															{event.days.includes(4) && <ToggleButton style={{ width: '1.5em', height: '1em' }}>TH.</ToggleButton>}
															{event.days.includes(5) && <ToggleButton style={{ width: '1.5em', height: '1em' }}>FR.</ToggleButton>}
															{event.days.includes(6) && <ToggleButton style={{ width: '1.5em', height: '1em' }}>SA.</ToggleButton>}
														</TableCell>
														<TableCell>
															{event.action === 'start' && 'Start'}
															{event.action === 'pause' && 'Pause'}
															{event.action === 'restart' && 'Resume'}
															{event.action === 'reset' && 'Reset'}
															{event.action === 'stop' && 'End'}
														</TableCell>
														<TableCell><IconButton onClick={() => {
															setToDelete(event);
															setDeleteDialogOpen(true);
														}} ><DeleteOutline color="primary" /></IconButton></TableCell>
													</TableRow>)}
												</TableBody>
											</Table>
										</CardContent>
									</Card>
								</Box>}
							{tab === 1 &&
								<Box display="flex" flexDirection="column" gap={2}>
									<Card variant='outlined'>
										<CardHeader title='New Segment Ratio Change Event'
											titleTypographyProps={{
												variant: "body2",
												color: "textSecondary",
											}}
										/>
										<CardContent>
											<Box display="grid" gridTemplateColumns="1fr 1fr" gap={2} alignItems="center">
												<ToggleButtonGroup value={newRatioEvent.days} onChange={(e, days) => setNewRatioEvent({ ...newRatioEvent, days })}>
													<ToggleButton value={0}>SU.
													</ToggleButton>
													<ToggleButton value={1}>MO.
													</ToggleButton>
													<ToggleButton value={2}>TU.
													</ToggleButton>
													<ToggleButton value={3}>WE.
													</ToggleButton>
													<ToggleButton value={4}>TH.
													</ToggleButton>
													<ToggleButton value={5}>FR.
													</ToggleButton>
													<ToggleButton value={6}>SA.
													</ToggleButton>
												</ToggleButtonGroup>

												<TextField
													variant='outlined'
													type='time'
													value={newRatioEvent.time}
													onChange={e => setNewRatioEvent({ ...newRatioEvent, time: e.target.value })}
												/>

												{newRatioEvent.segments && newRatioEvent.segments.map(segment =>
													<TextField
														label={segment.segmentName}
														variant="outlined"
														type='number'
														InputProps={{
															inputProps: {
																max: 100, min: 0
															}
														}}
														value={segment.segmentRatio}
														onChange={e => {
															const updatedSegments = newRatioEvent.segments.map(s => {
																if (s.segmentId === segment.segmentId) {
																	return { ...s, segmentRatio: e.target.value }
																}
																return s;
															});
															setNewRatioEvent({ ...newRatioEvent, segments: updatedSegments });
														}}
													/>
												)}

												<Button sx={actionOneButtonStyle} onClick={() => addEvent('segmentRatio')} variant='contained' color='primary'>Add Event</Button>

												<Typography variant='body2' color='textSecondary'>This event's timing is based off of the {Intl.DateTimeFormat().resolvedOptions().timeZone} timezone</Typography>

											</Box>
										</CardContent>
									</Card>

									<Card variant='outlined'>
										<CardHeader title='Scheduled Ratio Change Events' titleTypographyProps={{
											variant: "body2",
											color: "textSecondary",
										}}
										/>
										<CardContent>
											<Table>
												<TableHead>
													<TableRow>
														<TableCell>Time</TableCell>
														<TableCell>Timezone</TableCell>
														<TableCell>Days</TableCell>
														<TableCell>Delete</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{ratioEvents.map(event => <TableRow key={event.id}>
														<TableCell>{moment(event.time, 'HH:mm').format('hh:mm a')}</TableCell>
														<TableCell>{event.timeZone}</TableCell>
														<TableCell>
															{event.days.includes(0) && <ToggleButton style={{ width: '1.5em', height: '1em' }}>SU.</ToggleButton>}
															{event.days.includes(1) && <ToggleButton style={{ width: '1.5em', height: '1em' }}>MO.</ToggleButton>}
															{event.days.includes(2) && <ToggleButton style={{ width: '1.5em', height: '1em' }}>TU.</ToggleButton>}
															{event.days.includes(3) && <ToggleButton style={{ width: '1.5em', height: '1em' }}>WE.</ToggleButton>}
															{event.days.includes(4) && <ToggleButton style={{ width: '1.5em', height: '1em' }}>TH.</ToggleButton>}
															{event.days.includes(5) && <ToggleButton style={{ width: '1.5em', height: '1em' }}>FR.</ToggleButton>}
															{event.days.includes(6) && <ToggleButton style={{ width: '1.5em', height: '1em' }}>SA.</ToggleButton>}
														</TableCell>
														<TableCell><IconButton onClick={() => {
															setToDelete(event);
															setDeleteDialogOpen(true);
														}} ><DeleteOutline color="primary" /></IconButton></TableCell>
													</TableRow>)}
												</TableBody>
											</Table>
										</CardContent>
									</Card>
								</Box>}
							{tab === 2 &&
								<Box display="flex" flexDirection="column" gap={2}>
									<Card variant='outlined'>
										<CardHeader title='New Report Event'
											titleTypographyProps={{
												variant: "body2",
												color: "textSecondary",
											}}
										/>
										<CardContent>
											<Box display="grid" gridTemplateColumns="1fr 1fr" gap={2} alignItems="center">
												<ToggleButtonGroup value={newReport.days} onChange={(e, days) => setNewReport({ ...newReport, days })}>
													<ToggleButton value={0}>SU.
													</ToggleButton>
													<ToggleButton value={1}>MO.
													</ToggleButton>
													<ToggleButton value={2}>TU.
													</ToggleButton>
													<ToggleButton value={3}>WE.
													</ToggleButton>
													<ToggleButton value={4}>TH.
													</ToggleButton>
													<ToggleButton value={5}>FR.
													</ToggleButton>
													<ToggleButton value={6}>SA.
													</ToggleButton>
												</ToggleButtonGroup>

												<TextField
													variant='outlined'
													type='time'
													shouldDisableTime={(value, view) =>
														view === 'hours' && value.hour() > 5 && value.hour() < 10
													}
													value={newReport.time}
													onChange={e => setNewReport({ ...newReport, time: e.target.value })}
												/>

												<FormControl>
													<InputLabel id="report">Report</InputLabel>
													<Select
														labelId="report"
														label="Report"
														value={newReport.type}
														onChange={(e) => {
															setNewReport({ ...newReport, type: e.target.value })
														}}
													>
														<MenuItem value='rollingReport'>Rolling Call Count</MenuItem>
														{/* <MenuItem value='count'>Dial Counts By Day</MenuItem>
														<MenuItem value='dispositionCount'>Disposition Counts</MenuItem>
														<MenuItem value='agentCount'>Agent Dial Counts</MenuItem>
														<MenuItem value='agentDispositions'>Disposition By Agent</MenuItem> */}
													</Select>
												</FormControl>

												{/* <TextField
													disabled={newReport.report === 'full' ? true : false}
													label="Past Days"
													variant='outlined'
													type='number'
													inputProps={{ min: 1, max: 7 }}
													value={newReport.reportLength}
													onChange={(e) => {
														setNewReport({ ...newReport, reportLength: e.target.value, aggType: e.target.value === 1 ? 'daily' : newReport.aggType })
													}}
												/> */}

												{/* <FormControl>
													<InputLabel id="aggType">Aggregation Type</InputLabel>
													<Select
														disabled={(newReport.report === 'full' || newReport.reportLength < 2 ? true : false)}
														labelId="aggType"
														label="Aggregation Type"
														value={newReport.aggType}
														onChange={(e) => setNewReport({ ...newReport, aggType: e.target.value })}
													>
														<MenuItem value='daily'>Daily</MenuItem>
														<MenuItem value='weekly'>Combined</MenuItem>
													</Select>
												</FormControl> */}

												{/* <FormControl>
													<InputLabel id="channel">Channel</InputLabel>
													<Select
														labelId="channel"
														label="Channel"
														value={newReport.channel}
														onChange={(e) => setNewReport({ ...newReport, channel: e.target.value })}
													>
														<MenuItem value='Dial'>Voice</MenuItem>
														<MenuItem value='SMS'>SMS</MenuItem>
														<MenuItem value='Email'>Email</MenuItem>
													</Select>
												</FormControl> */}


												<TextField
													label="Email"
													variant='outlined'
													type='email'
													value={newReport.emailAddress}
													onChange={e => setNewReport({ ...newReport, emailAddress: e.target.value })}
												/>


												<Button sx={actionOneButtonStyle} onClick={() => addEvent('report')} variant='contained' color='primary'>Add Report</Button>

												<Typography variant='body2' color='textSecondary'>This event's timing is based off of the {Intl.DateTimeFormat().resolvedOptions().timeZone} timezone</Typography>

											</Box>
										</CardContent>
									</Card>

									<Card variant='outlined'>
										<CardHeader title='Scheduled Reports' titleTypographyProps={{
											variant: "body2",
											color: "textSecondary",
										}}
										/>
										<CardContent>
											<Table>
												<TableHead>
													<TableRow>
														<TableCell>Time</TableCell>
														<TableCell>Timezone</TableCell>
														<TableCell>Days</TableCell>
														<TableCell>Report</TableCell>
														<TableCell>Delete</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{reportEvents.map(event => <TableRow key={event.id}>
														<TableCell>{moment(event.time, 'HH:mm').format('hh:mm a')}</TableCell>
														<TableCell>{event.timeZone}</TableCell>
														<TableCell>
															{event.days.includes(0) && <ToggleButton style={{ width: '1.5em', height: '1em' }}>SU.</ToggleButton>}
															{event.days.includes(1) && <ToggleButton style={{ width: '1.5em', height: '1em' }}>MO.</ToggleButton>}
															{event.days.includes(2) && <ToggleButton style={{ width: '1.5em', height: '1em' }}>TU.</ToggleButton>}
															{event.days.includes(3) && <ToggleButton style={{ width: '1.5em', height: '1em' }}>WE.</ToggleButton>}
															{event.days.includes(4) && <ToggleButton style={{ width: '1.5em', height: '1em' }}>TH.</ToggleButton>}
															{event.days.includes(5) && <ToggleButton style={{ width: '1.5em', height: '1em' }}>FR.</ToggleButton>}
															{event.days.includes(6) && <ToggleButton style={{ width: '1.5em', height: '1em' }}>SA.</ToggleButton>}
														</TableCell>
														<TableCell>
															{event.report === 'rollingReport' && 'Activity Data'}
															{event.report === 'full' && 'All Call Data'}
															{event.report === 'count' && 'Dial Counts'}
															{event.report === 'dispositionCount' && 'Disposition Counts'}
															{event.report === 'agentCount' && 'Agent Dial Counts'}
															{event.report === 'agentDispositions' && 'Disposition By Agent'}
														</TableCell>
														<TableCell><IconButton onClick={() => {
															setToDelete(event);
															setDeleteDialogOpen(true);
														}} ><DeleteOutline color="primary" /></IconButton></TableCell>
													</TableRow>)}
												</TableBody>
											</Table>
										</CardContent>
									</Card>
								</Box>}
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={closeDialog} sx={actionTwoButtonStyle} color="primary">
						Close
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog open={deleteDialogOpen}>
				<DialogTitle>Delete Event</DialogTitle>
				<DialogContent>
					<Typography>Are you sure you want to delete this event?</Typography>
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" onClick={() => setDeleteDialogOpen(false)} color="primary">
						Cancel
					</Button>
					<Button variant="contained" onClick={() => deleteEvent()} color="primary">
						Delete
					</Button>
				</DialogActions>
			</Dialog>
		</div >
	)
}
export default CampaignScheduleDialog;
