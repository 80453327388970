import { useState, useEffect, useRef, useContext } from 'react';
import { Box, Drawer, useMediaQuery, useTheme } from '@mui/material';
import { BubbleGame } from '../../components/engagement/bubbleGame';
import { enqueueSnackbar } from 'notistack';
import { UserContext } from '../../contexts/UserContext';
import { nanoid } from 'nanoid';
import { Mutex } from 'async-mutex';
import { nthInvocation } from '../../tools/nthInvocation';
import { ApproverControls } from '../../components/engagement/approverControls';
import { InactiveGameMessage } from '../../components/engagement/inactiveGameMessage';
import { WhackAMoleGame } from '../../components/engagement/whackAMoleGame';
import { CallStatusPane } from '../../components/engagement/callStatusPane';
import { generateClient, get, post } from 'aws-amplify/api';
import { campaignsByTenant } from '../../graphql/queries';
import { fetchAuthSession } from 'aws-amplify/auth';
import { PubSub } from '@aws-amplify/pubsub';
import { IS_PROD } from '../../models/globals';

import { CampaignInfoDrawer } from 'src/components/engagement/campaignInfoDrawer';
import { Cache } from 'aws-amplify/utils';

const containerStyles = {
	animation: 'fadeIn 0.25s',
	'@keyframes fadeIn': {
		'0%': {
			opacity: 0,
		},
		'100%': {
			opacity: 1,
		},
	},
};

/** The maximum number of campaigns that can be selected for the normal approvals. */
const MAX_SELECTABLE_CAMPAIGNS = 6;

/** The maximum number of campaigns that can be selected for Click2Call. */
const MAX_CLICK2CALL_SELECTABLE_CAMPAIGNS = 3;

/** The amount of time between reporting needed contacts, represented in milliseconds. */
const DELAY_BETWEEN_CONTACT_NEEDED_REPORTS = 5000;

/** The number of approver users per approval group */
const APPROVERS_PER_APPROVAL_GROUP = 2;

const DRAWER_WIDTH = 420;

/**
 *
 * @param {{
 *  click2Call: boolean,
 *  agentId?: string,
 *  agentSkillIds?: string[],
 * }} props
 * @returns
 */
export default function Approver(props) {
	const theme = useTheme();
	const client = new generateClient();
	const userContext = useContext(UserContext);

	const pubsubRef = useRef(
		new PubSub({
			region: IS_PROD ? 'us-east-2' : 'us-east-1',
			endpoint: IS_PROD ? 'wss://a3b8mw0zsvy66t-ats.iot.us-east-2.amazonaws.com/mqtt' : 'wss://a3b8mw0zsvy66t-ats.iot.us-east-1.amazonaws.com/mqtt',
		}),
	);

	const usePersistentDrawer = useMediaQuery('(min-width:1200px)');

	const [showStatusDrawer, setShowStatusDrawer] = useState(false);
	useEffect(() => {
		Cache.setItem('showStatusDrawer', showStatusDrawer);
	}, [showStatusDrawer]);

	// Element refs
	const gameContainerRef = useRef();

	// Game

	const [isApprover, setIsApprover] = useState(true);

	/** The selected channel for approving (not in Click2Call). */
	const selectedChannelRef = useRef('voice');

	/** The number of contacts to display (for Click2Call). */
	const [numberOfContactsToDisplay, setNumberOfContactsToDisplay] = useState(5);

	/** Click action reference to the state. */
	const clickActionRef = useRef('approve');

	// Campaigns

	/** Whether campaigns are currently loading. */
	const [gettingCampaigns, setGettingCampaigns] = useState(false);

	/** All of the available campaigns. */
	const [allCampaigns, setAllCampaigns] = useState([]);

	/** Reference to the selected campaigns state. */
	const selectedCampaignsRef = useRef([]);

	/** Subscription for changes about the status of selected campaigns. */
	const campaignStatusSubscriptionRef = useRef(null);

	/** Subscription for notifications about any contacts clicked by other agents in any of the selected campaigns. */
	const contactClickSubscriptionRef = useRef(null);

	/** Subscription for contacts for any selected campaign. */
	const campaignContactSubscriptionRef = useRef(null);

	/** Subscription for status updates about active calls in progress for the agent. Click2Call only. */
	const agentCallStatusesSubscriptionRef = useRef(null);

	/** Subscription for the go-ahead on allowing the agent to dial a number. Click2Call only. */
	const agentAllowDialSubscriptionRef = useRef(null);

	/** The campaign to be deselected because it was paused or completed. */
	const [campaignToDeselect, setCampaignToDeselect] = useState(null);

	// Contacts

	/** Whether additional contacts are being retrieved. */
	const [gettingContacts, setGettingContacts] = useState(false);

	/**
	 * The list of available contacts needing approval mapped by each selected campaign. The key is the
	 * campaign ID and the value is the array of contacts.
	 */
	const [availableContactsPerCampaign, setAvailableContactsPerCampaign] =
		useState(new Map());

	// TODO: Figure out if there's a better way to do this?
	/** Available contacts per campaign reference to the state. Used to allow retrieving this value in a callback. */
	const availableContactsPerCampaignRef = useRef(new Map());

	useEffect(() => {
		availableContactsPerCampaignRef.current = availableContactsPerCampaign;

		// If any campaigns don't have contacts, report that more are needed.
		selectedCampaignsRef.current.forEach((campaign) => {
			if (availableContactsPerCampaign.get(campaign.id)?.length === 0) {
				reportEmptyContacts(campaign);
			}
		});
	}, [availableContactsPerCampaign]);

	/**
	 * The list of filtered contacts for this user's approval group mapped by each selected campaign. The key is the
	 * campaign ID and the value is the array of contacts.
	 */
	const [filteredContactsPerCampaign, setFilteredContactsPerCampaign] =
		useState(new Map());

	// TODO: Figure out if there's a better way to do this?
	/** Filtered contacts per campaign reference to the state. Used to allow retrieving this value in a callback. */
	const filteredContactsPerCampaignRef = useRef(new Map());

	useEffect(() => {
		filteredContactsPerCampaignRef.current = filteredContactsPerCampaign;
	}, [filteredContactsPerCampaign]);

	/** A map of boolean values indicating whether more contacts can be requested for a campaign. Used to prevent constant requests. */
	const canRequestMoreContactsPerCampaignRef = useRef(new Map());

	/** The current list of approved contacts that are to be dialed. */
	const [approvedContacts, setApprovedContacts] = useState([]);

	// TODO: Figure out if there's a better way to do this?
	/** The current list of approved contacts reference to the state. Used to allow retrieving this value in a callback. */
	const approvedContactsRef = useRef([]);
	useEffect(() => {
		approvedContactsRef.current = approvedContacts;
	}, [approvedContacts]);

	/** Whether or not the agent is allowed to approve multiple calls at a time to be dialed for Click2Call. */
	const [allowClick2CallMultiApprove] = useState(false);

	// Agents
	/**
	 * The list of available agents mapped by each selected campaign. The key is the campaign ID and the value is the number of
	 * agents available.
	 */
	const [availableAgentsPerCampaign, setAvailableAgentsPerCampaign] = useState(
		new Map(),
	);

	/** The leaderboard for each selected campaign. The key is the campaign ID and the value is the leaderboard. */
	const [leaderboardPerCampaign, setLeaderboardPerCampaign] = useState(
		new Map(),
	);

	/** The list of groups of approvers. Pairs the highest performing approvers with the lowest performing approvers. */
	const [approvalGroupsPerCampaign, setApprovalGroupsPerCampaign] = useState(
		new Map(),
	);

	const newContactsMessageMutex = new Mutex();

	/**
	 * Authorizes the approver and sets up the UI.
	 */
	useEffect(() => {
		rehydrateFromCache();
		getAllCampaigns();
		if (userContext.telephonyProvider === 'Webex') {
			addWebexCallEventListeners();
		}

		// Clean up subscriptions and listeners.
		return () => {
			unsubscribeFromAll();
			if (userContext.telephonyProvider === 'Webex') {
				removeWebexCallEventListeners();
			}
		};
	}, [userContext]);

	useEffect(() => {
		console.log('Approved contacts', approvedContacts);
	}, [approvedContacts]);

	useEffect(() => {
		if (userContext.apiKey) {
			signInAsApprover();
		}
	}, [userContext.apiKey]);

	/**
	 * Recalculates the approval groups anytime the leaderboard changes.
	 */
	useEffect(() => {
		calculateApprovalGroups();
	}, [leaderboardPerCampaign]);

	/**
	 * Anytime the approval groups or available contacts change, recalculate the contacts to show.
	 */
	useEffect(() => {
		filterContactsByApproverGroup();
	}, [approvalGroupsPerCampaign, availableContactsPerCampaign]);

	/**
	 * Re-hydrates the UI state from the cache.
	 */
	async function rehydrateFromCache() {
		const showStatusDrawer = await Cache.getItem('showStatusDrawer');
		if (showStatusDrawer) {
			setShowStatusDrawer(showStatusDrawer);
		}
		if (props.click2Call) {
			const approvedContact = await Cache.getItem('approvedContacts');
			if (approvedContact) {

				if (userContext.telephonyProvider === 'Webex') {
					const { Desktop } = await import('@wxcc-desktop/sdk');
					const taskMap = await Desktop.actions.getTaskMap();
					const mapKeys = Array.from(taskMap.keys());

					// Not on a call, clear the approved contacts.
					if (mapKeys.length === 0) {
						console.debug('The agent is no longer on a call.');
						await Cache.removeItem('approvedContacts');
						return;
					}

					// On a different call, clear the approved contacts.
					const taskId = mapKeys[0];
					const task = taskMap.get(taskId);
					if (task.interaction.callAssociatedDetails.dn !== approvedContact[0].phone) {
						console.debug('The agent is on a different call.');
						await Cache.removeItem('approvedContacts');
						return;
					}

					// Determine the call state for the approved contact.
					const state = task.interaction.state;
					approvedContact[0].callStatus = state === 'wrapUp' ? 'Call Ended - Awaiting Wrap Up' : 'On Call';
				} else {
					// NICE
					approvedContact[0].callStatus = 'On Call';
				}
				setApprovedContacts(approvedContact);
			}
		}
	}

	/****** API Calls ******/

	/**
	 * Gets the Cognito user and signs in as an approver through an API call.
	 */
	async function signInAsApprover() {
		try {
			const session = await fetchAuthSession();
			session.tokens.idToken.payload['custom:approver'] = '1';

			// Check if the user is an approver.
			const approver = (session.tokens.idToken.payload['custom:approver'] =
				'1');
			setIsApprover(approver);
			if (!approver) {
				return;
			}

			if (!session.tokens.idToken.payload['custom:identityId']) {
				await post({
					apiName: 'cdyxoutreach',
					path: '/approver',
					options: {
						headers: {
							Authorization: `Bearer ${session.tokens.idToken}`,
							'x-api-key': userContext.apiKey,
						},
						body: {
							identityId: session.identityId,
							accessToken: session.tokens.accessToken.toString(),
						},
					},
				}).response;
			}
		} catch (error) {
			console.error('Error signing in as approver', error);
		}
	}

	/**
	 * Gets and saves the list of all non Click2Call-enabled campaigns for the user through an API call.
	 *
	 * FIXME: Getting these campaigns is extremely inefficient. We should condense this into a single API call that filters/queries on the back end.
	 */
	async function getAllCampaigns() {
		setGettingCampaigns(true);
		try {

			// Get all campaigns for the tenant.
			const idToken = (await fetchAuthSession()).tokens.idToken;
			const graphqlResponse = await client.graphql({
				query: campaignsByTenant,
				variables: {
					tenant: idToken.payload['custom:tenant'],
					filter: { status: { eq: 'Active' } },
					limit: 1000,
				},
			});
			const tenantCampaigns = graphqlResponse.data.campaignsByTenant?.items ?? [];

			// Filter the campaigns based on whether they are Click2Call-enabled or not.
			let filteredCampaigns = tenantCampaigns.filter(
				(campaign) => {
					return props.click2Call
						? campaign.profile.clickToCall
						: !campaign.profile.clickToCall;
				},
			);

			if (userContext.telephonyProvider === 'Webex') {
				// Filter the campaigns for the agent's selected team.
				const campaignIdsResponse = await get({
					apiName: 'cdyxoutreach',
					path: '/cci/webex/contact-center/agent-campaigns/' + props.teamId,
					options: {
						headers: {
							Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken}`,
							'x-api-key': userContext.apiKey,
						}
					}
				}).response;
				campaignIdsForTeam = await campaignIdsResponse.body.json() ?? [];

				filteredCampaigns = filteredCampaigns.filter(
					(campaign) => campaignIdsForTeam.includes(campaign.id),
				);
			}

			setAllCampaigns(filteredCampaigns);
			setGettingCampaigns(false);
		} catch (error) {
			console.error('Error getting all campaigns', error);
			enqueueSnackbar('Unable to get campaigns. Please try again later.', {
				autoHideDuration: 1000,
				variant: 'error',
			});
		}
	}

	/**
	 * Reports through the WebSocket that there are no contacts and more are needed. This will trigger contacts to be received on a new WebSocket message.
	 *
	 * @param {Campaign} campaign The campaign for which to report the needed contacts.
	 */
	async function reportEmptyContacts(campaign) {
		if (!canRequestMoreContactsPerCampaignRef.current.get(campaign.id)) {
			console.log(
				`Not reporting empty contacts for campaign ${campaign.id} because we already already reported less than ${DELAY_BETWEEN_CONTACT_NEEDED_REPORTS / 1000} seconds ago.`,
			);
			return;
		}
		// Check for more contacts in a little bit.
		setTimeout(() => {
			// If filtered campaign contacts are still empty, report that more are needed.
			canRequestMoreContactsPerCampaignRef.current.set(campaign.id, true);
			if (
				filteredContactsPerCampaignRef.current.get(campaign.id)?.length === 0
			) {
				reportEmptyContacts(campaign);
			}
		}, DELAY_BETWEEN_CONTACT_NEEDED_REPORTS);
		console.log('Reporting empty contacts for campaign', campaign.id);
		setGettingContacts(true);
		canRequestMoreContactsPerCampaignRef.current.set(campaign.id, false);

		try {
			await pubsubRef.current.publish({
				topics: `approvals${selectedChannelRef.current === 'sms' ? '-sms' : ''}/${process.env.REACT_APP_ENV}/${userContext.tenantId}/${campaign.id}`,
				message: {
					type: 'empty',
					skillId: campaign.skillId,
					campaignId: campaign.id,
					cci: campaign.cci,
					tenant: userContext.tenantId,
					jwtToken: (await fetchAuthSession()).tokens.idToken,
					apiKey: userContext.apiKey,
					agent: userContext.name,
					messageId: nanoid(),
				},
			});
		} catch (error) {
			console.error('Error reporting empty contacts', error);
		}
	}

	/**
	 * Reports through the WebSocket that a contact was clicked.
	 * @param {Contact} contact The contact that was clicked.
	 * @param {string} type The type of action that was performed (approve, recycle, or suppress).
	 */
	async function reportContactClicked(contact, type) {
		// console.log('Reporting contact clicked', type, contact);
		const clickedCampaign = selectedCampaignsRef.current.find(
			(campaign) => campaign.id === contact.campaignId,
		);
		if (!clickedCampaign) {
			throw new Error(
				`Contact clicked for campaign that is not selected: ${contact.campaignId}`,
			);
		}

		try {
			await pubsubRef.current.publish({
				topics: [
					`approvals${selectedChannelRef.current === 'sms' ? '-sms' : ''}/${process.env.REACT_APP_ENV}/${userContext.tenantId}/${clickedCampaign.id}`,
				],
				message: {
					type,
					skillId: clickedCampaign.skillId,
					agent: props.click2Call ? props.agentId : userContext.name,
					cci: clickedCampaign.cci,
					...contact,
					jwtToken: (await fetchAuthSession()).tokens.idToken,
					apiKey: userContext.apiKey,
					c2c: props.click2Call,
				},
			});
		} catch (error) {
			console.error('Error reporting contact clicked', error);
		}
	}

	/**
	 * Unsubscribes from all subscriptions.
	 */
	function unsubscribeFromAll() {
		campaignStatusSubscriptionRef.current?.unsubscribe();
		contactClickSubscriptionRef.current?.unsubscribe();
		campaignContactSubscriptionRef.current?.unsubscribe();
		agentCallStatusesSubscriptionRef.current?.unsubscribe(); // Click2Call only
		agentAllowDialSubscriptionRef.current?.unsubscribe(); // Click2Call only
	}

	/**
	 * Registers listeners for Webex call events to get call progression. This should only be called if the user is using Webex
	 * as the telephony provider.
	 */
	async function addWebexCallEventListeners() {
		try {
			const { Desktop } = await import('@wxcc-desktop/sdk');
			Desktop.agentContact.addEventListener('eAgentContactAssigned', async (event) => {
				await handleCallStatusChangeMessage({ callStatus: 'On Call', contactId: event.data.interaction.callAssociatedData.contactId.value });
			});
			Desktop.agentContact.addEventListener('eAgentContactEnded', async (event) => {
				await handleCallStatusChangeMessage({ callStatus: 'Call Ended - Awaiting Wrap Up', contactId: event.data.interaction.callAssociatedData.contactId.value });
			});
			Desktop.agentContact.addEventListener('eAgentContactWrappedUp', async (event) => {
				removeFromCallApproved(approvedContactsRef.current[0]);
			});
		} catch (error) {
			console.error('Error setting up Webex agent contact listeners', error);
		}
	}

	/**
	 * Removes the Webex call event listeners. This should only be called if the user is using Webex as the telephony provider.
	 */
	async function removeWebexCallEventListeners() {
		try {
			const { Desktop } = await import('@wxcc-desktop/sdk');
			Desktop.agentContact.removeAllEventListeners();
		} catch (error) {
			console.error('Error removing Webex agent contact listeners', error);
		}
	}

	/**
	 * Re-subscribes to information about the selected campaigns.
	 */
	async function resubscribeToSelectedCampaigns() {
		unsubscribeFromAll();
		if (selectedCampaignsRef.current.length === 0) {
			console.log('No need to resubscribe; no campaigns selected');
			return;
		}

		const baseTopic = `approvals${selectedChannelRef.current === 'sms' ? '-sms' : ''}/${process.env.REACT_APP_ENV}/${userContext.tenantId}`;
		const handleThirdErrorReceived = nthInvocation(handleErrorMessage, 3);

		// Subscribe to campaign statuses
		const campaignStatusTopics = selectedCampaignsRef.current.map(
			(campaign) => `${baseTopic}/${campaign.id}/status`,
		);
		campaignStatusSubscriptionRef.current =
			pubsubRef.current
				.subscribe({ topics: campaignStatusTopics })
				.subscribe({
					next: (message) => handleCampaignStatusChangeMessage(message),
					error: (error) => {
						console.error(`Error in campaign status subscription: ${error}`);
						handleThirdErrorReceived();
					},
					complete: () => console.log('Campaign status watcher closed.'),
				});


		// Subscribe to a contact click event
		const campaignContactClickTopics = selectedCampaignsRef.current.map(
			(campaign) => `${baseTopic}/${campaign.id}`,
		);
		contactClickSubscriptionRef.current =
			pubsubRef.current
				.subscribe({ topics: campaignContactClickTopics })
				.subscribe({
					next: (message) => handleContactClickMessage(message),
					error: (error) => {
						console.error(`Error in contact click subscription: ${error}`);
						handleThirdErrorReceived();
					},
					complete: () => console.log('Contact click watcher closed.'),
				});


		// Subscribe to campaign contacts
		const campaignContactTopics = selectedCampaignsRef.current.map(
			(campaign) => `${baseTopic}/${campaign.id}/contacts`,
		);
		campaignContactSubscriptionRef.current =
			pubsubRef.current.subscribe({ topics: campaignContactTopics }).subscribe({
				next: (message) => {
					handleContactsMessage(message);
				},
				error: (error) => {
					console.error(`Error campaign contacts subscription: ${error}`);
					handleThirdErrorReceived();
				},
				complete: () => console.log('Campaign contact watcher closed.'),
			});

		// Subscribe to agent call status changes and allow dial message (Click2Call only).
		if (props.click2Call) {
			agentCallStatusesSubscriptionRef.current =
				pubsubRef.current
					.subscribe({
						topics: `${baseTopic}/agent-call-statuses/${props.agentId}`,
					})
					.subscribe({
						next: (message) => handleCallStatusChangeMessage(message),
						error: (error) => {
							console.error(
								`Error in agent call status subscription: ${error}`,
							);
							handleThirdErrorReceived();
						},
						complete: () => console.log('Call status watcher closed.'),
					});

			agentAllowDialSubscriptionRef.current =
				pubsubRef.current
					.subscribe({
						topics: `${baseTopic}/c2c/${props.agentId}`,
					})
					.subscribe({
						next: (message) => handleAllowDialMessage(message),
						error: (error) => {
							console.error(
								`Error in agent allow dial subscription: ${error}`,
							);
							handleThirdErrorReceived();
						},
						complete: () => console.log('Allow dial watcher closed.'),
					});
		}
	}

	/**
	 * Handles an error message received from the WebSocket.
	 *
	 * @param {*} error The error message.
	 */
	function handleErrorMessage(error) {
		// TODO: Handle something here?
		console.error('IOT error:', error);
		enqueueSnackbar(
			'An error occurred. Please refresh the page if you experience any adverse behavior.',
			{ variant: 'error', autoHideDuration: 4000 },
		);
	}

	/**
	 * Handles a change to the campaign status received from the WebSocket and shows an alert.
	 * @param {Campaign} changedCampaign The campaign that changed.
	 */
	const handleCampaignStatusChangeMessage = (changedCampaign) => {
		console.log('Campaign status change message received', changedCampaign);
		const actionMessage =
			changedCampaign.status === 'Paused' ? 'been paused' : 'ended';
		enqueueSnackbar(
			`The campaign '${changedCampaign.name}' has ${actionMessage}.`,
			{ variant: 'info' },
		);
		setAllCampaigns((campaigns) =>
			campaigns.filter((campaign) => campaign.id !== changedCampaign.id),
		);
		setCampaignToDeselect(changedCampaign);
	};

	/**
	 * Handles a reported contact click message received from the WebSocket.
	 *
	 * @param {Contact} clickedContact The contact that was clicked by an agent.
	 */
	const handleContactClickMessage = (clickedContact) => {
		// console.log('Contact click message received', clickedContact);
		if (clickedContact?.id) {
			const campaignId = clickedContact.campaignId;
			const existingContacts =
				availableContactsPerCampaignRef.current.get(campaignId);
			const filteredContacts = existingContacts.filter(
				(contact) => contact.id !== clickedContact.id,
			);
			setAvailableContactsPerCampaign((contactMap) =>
				new Map(contactMap).set(campaignId, filteredContacts),
			);
		}
	};

	/**
	 * Handles new contacts received from the WebSocket.
	 *
	 * @param {*} message The message received from the WebSocket.
	 */
	async function handleContactsMessage(message) {
		if (!message || !message.chunk) {
			console.error('No contacts message received');
			return;
		}
		const symbolKey = Object.getOwnPropertySymbols(message)[0];
		const campaignId = message[symbolKey].split('/')[3];
		const messageCampaign = selectedCampaignsRef.current.find(
			(campaign) => campaign.id === campaignId,
		);

		// Check for errors
		if (message.StatusCode >= 600) {
			messageCampaign.status = 'Paused';
			handleCampaignStatusChangeMessage(messageCampaign);
			enqueueSnackbar(message.Payload, {
				variant: 'error',
				autoHideDuration: 6000,
			});
			throw new Error(message.Payload);
		} else if (message.StatusCode !== 200) {
			enqueueSnackbar(message.Payload, {
				variant: 'error',
				autoHideDuration: 6000,
			});
		}

		// Get agents available
		// TODO: Shouldn't this be in a campaign status message, not a contacts message?
		const agentsAvailable = message.agentsAvailable ?? 0;
		setAvailableAgentsPerCampaign((agentMap) =>
			new Map(agentMap).set(campaignId, agentsAvailable),
		);

		// Get leaderboard
		// TODO: Shouldn't this be in a campaign status message, not a contacts message?
		const rawLeaderboard = message.leaderBoard ?? [];
		const leaderboard = [];
		// The leaderboard is in a weird format, so we need to map it to JSON
		for (let i = 0; i < rawLeaderboard.length; i += 2) {
			leaderboard.push({
				name: rawLeaderboard[i],
				score: +rawLeaderboard[i + 1],
			});
		}
		setLeaderboardPerCampaign((leaderboardMap) =>
			new Map(leaderboardMap).set(campaignId, leaderboard),
		);

		// Check for no contacts
		if (message.emptyReason) {
			console.log('No contacts reason', message.emptyReason);
		}

		const release = await newContactsMessageMutex.acquire();
		const contacts = message.chunk ?? [];
		console.log('Received contacts', contacts);
		setGettingContacts(false);

		/*
				NOTE: This next part is *very* expensive, but seemingly necessary. Contacts are sent in batches of 50 max. We can't just replace the old contacts with the new ones every time
				because you could end up with a small list of contacts. For example, with 54 contacts you could end up with only 4 in the list, depending on timing.
		*/

		if (contacts.length > 0) {
			let campaignContacts =
				availableContactsPerCampaignRef.current.get(campaignId);

			// If a contact is already in our map, we know this is an updated batch of contacts. Replace the old ones.
			if (
				availableContactsPerCampaignRef.current.has(campaignId) &&
				availableContactsPerCampaignRef.current
					.get(campaignId)
					.some((contact) =>
						contacts.some((newContact) => newContact.id === contact.id),
					)
			) {
				setAvailableContactsPerCampaign((contactMap) =>
					new Map(contactMap).set(campaignId, contacts),
				);
				campaignContacts = contacts;
			} else {
				// Otherwise, append them to the end of the list.
				setAvailableContactsPerCampaign((contactMap) =>
					new Map(contactMap).set(campaignId, [
						...contactMap.get(campaignId),
						...contacts,
					]),
				);
				campaignContacts = [...campaignContacts, ...contacts];
			}

			// Display a message if there are any duplicate contacts. This shouldn't happen, but we'll know if it does.
			const uniqueContacts = new Set(campaignContacts);
			if (campaignContacts.length !== uniqueContacts.size) {
				enqueueSnackbar(
					`There are duplicate contacts in the campaign '${messageCampaign.name}'.`,
					{ variant: 'warning' },
				);
				console.warn(
					`There are duplicate contacts in the campaign '${messageCampaign.name}'.`,
				);
			}
		}
		release();
	}

	/**
	 * Updates the call status on the approved contact when the status changes.
	 *
	 * @param {*} message The message received from the WebSocket.
	 */
	async function handleCallStatusChangeMessage(message) {
		console.log('Call status change message received', message);

		// If a contact is answered, stop tracking all other approved contacts
		if (message.callStatus === 'On Call') {
			const contactOnCall = approvedContactsRef.current.find(
				(contact) => contact.id === message.contactId,
			);
			if (!contactOnCall) {
				console.error('Contact on call not found in approved contacts');
				return;
			}
			contactOnCall.callStatus = message.callStatus;
			setApprovedContacts([contactOnCall]);
			return;
		}

		// Update the call status on the approved contact
		const newContacts = approvedContactsRef.current.map((contact) => {
			if (contact.id === message.contactId) {
				contact.callStatus = message.callStatus;
			}
			return contact;
		});
		setApprovedContacts(newContacts);
	}

	/**
	 * Starts the dial or displays an error message when the agent is not allowed to dial.
	 *
	 * @param {*} message The message received from the WebSocket.
	 */
	async function handleAllowDialMessage(message) {
		console.log('Allow dial message received', message);
		const approvedContact = approvedContactsRef.current[0];
		if (approvedContact === undefined) { // Cannot catch this.
			console.error('Approved contact not found');
			return;
		}

		try {
			const clickedCampaign = selectedCampaignsRef.current.find(
				(campaign) => campaign.id === approvedContact.campaignId,
			);
			if (!clickedCampaign) {
				throw new Error(
					`Contact clicked for campaign that is not selected: ${approvedContact.campaignId}`,
				);
			}

			// If the agent is not allowed to dial, just update the call status to 'Not Allowed'.
			if (!message.allowDial) {
				approvedContact.callStatus = 'Not Allowed To Dial';
				setApprovedContacts([approvedContact]);
				return;
			}

			// Start the dial, if using Webex CC.
			if (userContext.telephonyProvider === 'Webex') {
				const { Desktop } = await import('@wxcc-desktop/sdk');
				const campaignCciMetadata = JSON.parse(clickedCampaign.cciMetaData);
				const outDial = await Desktop.dialer.startOutdial({
					data: {
						entryPointId: clickedCampaign.skillId,
						destination: approvedContact.phone,
						direction: 'OUTBOUND',
						origin: campaignCciMetadata.wbxOutDialAni,
						attributes: {
							FirstName: approvedContact.firstName,
							LastName: approvedContact.lastName,
							contactId: approvedContact.id,
							campaignId: approvedContact.campaignId,
							segmentId: approvedContact.segmentId,
							externalId: approvedContact.externalId,
						},
						mediaType: 'telephony',
						outboundType: 'OUTDIAL',
					},
				});
				console.debug('Outdial started', outDial);

				approvedContact.callStatus = 'Dialing...';
				setApprovedContacts([approvedContact]);
				await Cache.setItem('approvedContacts', [approvedContact]);
			}

		} catch (error) {
			console.error('Error', error);
			approvedContact.callStatus = 'Error';
			setApprovedContacts([approvedContact]);
		}
	}

	/**
	 * Reports that a contact was clicked to the server.
	 *
	 * @param {Contact} contact The contact that was clicked.
	 */
	async function handleContactClicked(contact) {
		try {
			if (clickActionRef.current === 'approve') {
				// Deep copy the contact here so that we don't accidentally modify the contact in the list when making call status changes.
				setApprovedContacts((approvedContacts) => [
					...approvedContacts,
					JSON.parse(JSON.stringify(contact)),
				]);
			}
			await reportContactClicked(contact, clickActionRef.current);
		} catch (error) {
			console.error('Error reporting contact click', error);
		}
		// TODO: Re-enable?
		// setClickAction('approve');
	}

	/**
	 * Calculates and sets the approval groups for each campaign.
	 */
	function calculateApprovalGroups() {
		const newApprovalGroupsPerCampaign = new Map();

		// Create approval groups for each campaign
		for (const campaign of selectedCampaignsRef.current) {
			// Deep copy the leaderboard so that we don't accidentally modify it.
			const campaignLeaderboard = JSON.parse(
				JSON.stringify(leaderboardPerCampaign.get(campaign.id)),
			);
			const originalLeaderboardLength = campaignLeaderboard.length;
			const orderedLeaderboard = campaignLeaderboard.sort(
				(a, b) => b.score - a.score,
			);

			const approvalGroups = [];

			// Create a new group for every 2 approvers on the board.
			for (
				let i = 0;
				i < Math.ceil(originalLeaderboardLength / APPROVERS_PER_APPROVAL_GROUP);
				i++
			) {
				const group = [];
				const highestScoringApprover = orderedLeaderboard.shift();
				const lowestScoringApprover = orderedLeaderboard.pop();
				if (highestScoringApprover) {
					group.push(highestScoringApprover);
				}
				if (lowestScoringApprover) {
					group.push(lowestScoringApprover);
				}

				// If this group isn't "full" and another group exists, distribute these approvers to another group. This is the last group.
				if (
					group.length !== APPROVERS_PER_APPROVAL_GROUP &&
					approvalGroups.length > 0
				) {
					// Distribute approvers to the other groups.
					while (group.length > 0) {
						for (const otherGroup of approvalGroups) {
							if (group.length === 0) {
								break;
							}
							otherGroup.push(group.pop());
						}
					}
					break;
				} else {
					approvalGroups.push(group);
				}
			}
			newApprovalGroupsPerCampaign.set(campaign.id, approvalGroups);
		}
		setApprovalGroupsPerCampaign(newApprovalGroupsPerCampaign);
	}

	/**
	 * Filters the contacts for each campaign based on the approver's approval group.
	 */
	function filterContactsByApproverGroup() {
		const newFilteredContactsPerCampaign = new Map();

		// Filter the contacts for each campaign.
		for (const campaign of selectedCampaignsRef.current) {
			const approvalGroupIndex = getApprovalGroupIndex(campaign.id);

			// If the approver isn't in an approval group, don't filter any contacts for this campaign.
			if (approvalGroupIndex === -1) {
				newFilteredContactsPerCampaign.set(
					campaign.id,
					availableContactsPerCampaign.get(campaign.id),
				);
				continue;
			}

			// Use a subset of the contacts corresponding the approval group.
			const availableContacts = availableContactsPerCampaign.get(campaign.id);
			const approvalGroups = approvalGroupsPerCampaign.get(campaign.id);
			const numberOfContactsPerGroup = Math.ceil(
				availableContacts.length / approvalGroups.length,
			);
			const contactStartIndex = approvalGroupIndex * numberOfContactsPerGroup;
			const filteredContacts = availableContacts.slice(
				contactStartIndex,
				contactStartIndex + numberOfContactsPerGroup,
			);

			// If there are no contacts left to display for this approver group, report that more are needed.
			if (filteredContacts.length === 0) {
				reportEmptyContacts(campaign);
			}

			newFilteredContactsPerCampaign.set(campaign.id, filteredContacts);
		}
		setFilteredContactsPerCampaign(newFilteredContactsPerCampaign);
	}

	/**
	 * Gets the index of the currently logged in approver's approval group for a specific campaign.
	 *
	 * @param {string} campaignId The ID of the campaign to get the approval group index for.
	 * @returns {number} The index of the approval group.
	 */
	function getApprovalGroupIndex(campaignId) {
		const campaignApprovalGroups =
			approvalGroupsPerCampaign.get(campaignId) ?? [];
		return campaignApprovalGroups.findIndex((group) => {
			return group.some((approver) => approver.name === userContext.name);
		});
	}

	/**
	 * Gets a list of all available contacts for all selected campaigns in a single list.
	 *
	 * @returns {Contact[]} The list of all available contacts.
	 */
	function getAllAvailableContacts() {
		const allAvailableContacts = [];
		for (const campaign of selectedCampaignsRef.current) {
			allAvailableContacts.push(
				...availableContactsPerCampaign.get(campaign.id) ?? [],
			);
		}
		return allAvailableContacts;
	}

	/**
	 * Removes the contact from the list of approved contacts.
	 *
	 * @param {Contact} contact The contact to remove.
	 */
	function removeFromCallApproved(contact) {
		console.log('Removing contact from approved list', contact);
		setApprovedContacts((approvedContacts) =>
			approvedContacts.filter(
				(approvedContact) => approvedContact.id !== contact.id,
			),
		);
		if (props.click2Call) {
			Cache.removeItem('approvedContacts');
		}
	}

	return (
		// Container
		<Box
			id="container"
			display="grid"
			gridTemplateColumns="1fr auto"
			height={userContext.telephonyProvider === 'Webex' ? '100%' : '100vh'} // Webex CC Desktop requires 100% height
			minHeight='600px'
			style={{ backgroundColor: theme.palette.mode === 'dark' ? '#1c1c1c' : 'white' }}
			sx={{ ...containerStyles }}
		>
			{/* Main Pane */}
			<Box
				display="grid"
				gridTemplateRows="auto 1fr"
				height="100%"
				width="100%"
				margin="auto"
				overflow="hidden"
			>
				{/* Approver Controls */}
				<ApproverControls
					disabled={!isApprover}
					click2Call={props.click2Call}
					showStatusDrawer={showStatusDrawer}
					onToggleDrawer={() => {
						setShowStatusDrawer(!showStatusDrawer);
						setTimeout(() => {
							window.dispatchEvent(new Event('resize'));
						}, 50);
					}}
					maxSelectableCampaigns={
						props.click2Call
							? MAX_CLICK2CALL_SELECTABLE_CAMPAIGNS
							: MAX_SELECTABLE_CAMPAIGNS
					}
					allCampaigns={allCampaigns}
					gettingCampaigns={gettingCampaigns}
					campaignToDeselect={campaignToDeselect}
					onCampaignSelectionChange={(selectedCampaigns) => {
						// If the selected campaigns haven't changed, we don't need to do anything.
						if (
							JSON.stringify(selectedCampaignsRef.current) ===
							JSON.stringify(selectedCampaigns)
						) {
							return;
						}
						selectedCampaignsRef.current = selectedCampaigns;

						if (selectedCampaigns.length === 0) {
							setGettingContacts(false);
						}

						// Update the subscriptions
						resubscribeToSelectedCampaigns();

						// Add newly selected campaigns to the various maps.
						const addedCampaigns = selectedCampaignsRef.current.filter(
							(campaign) => !availableContactsPerCampaign.has(campaign.id),
						);
						const newAvailableContactsPerCampaign = new Map(
							availableContactsPerCampaign,
						);
						const newAvailableAgentsPerCampaign = new Map(
							availableAgentsPerCampaign,
						);
						const newLeaderboardPerCampaign = new Map(leaderboardPerCampaign);
						addedCampaigns.forEach((campaign) => {
							newAvailableContactsPerCampaign.set(campaign.id, []);
							newAvailableAgentsPerCampaign.set(campaign.id, 0);
							newLeaderboardPerCampaign.set(campaign.id, []);
							canRequestMoreContactsPerCampaignRef.current.set(
								campaign.id,
								true,
							);
						});

						// Remove newly removed campaigns from the various maps.
						const contactMapCampaignIds = Array.from(
							availableContactsPerCampaign.keys(),
						);
						const selectedCampaignIds = selectedCampaignsRef.current.map(
							(campaign) => campaign.id,
						);
						const removedCampaignIds = contactMapCampaignIds.filter(
							(campaignId) => !selectedCampaignIds.includes(campaignId),
						);
						removedCampaignIds.forEach((campaignId) => {
							newAvailableContactsPerCampaign.delete(campaignId);
							newAvailableAgentsPerCampaign.delete(campaignId);
							newLeaderboardPerCampaign.delete(campaignId);
							canRequestMoreContactsPerCampaignRef.current.delete(campaignId);
						});
						setAvailableContactsPerCampaign(newAvailableContactsPerCampaign);
						setAvailableAgentsPerCampaign(newAvailableAgentsPerCampaign);
						setLeaderboardPerCampaign(newLeaderboardPerCampaign);

						// Get contacts for the newly selected campaigns
						addedCampaigns.forEach((campaign) => {
							reportEmptyContacts(campaign);
						});
					}}
					onChannelSelectionChange={(selectedChannel) => {
						const channelBeforeChange = selectedChannelRef.current;
						selectedChannelRef.current = selectedChannel;

						// If the channel hasn't changed, we don't need to do anything.
						if (channelBeforeChange === selectedChannel) {
							return;
						}

						resubscribeToSelectedCampaigns();

						// Reset contacts and available agents for all selected campaigns
						const campaignIds = Array.from(availableContactsPerCampaign.keys());
						const newAvailableContactsPerCampaign = new Map(
							availableContactsPerCampaign,
						);
						const newAvailableAgentsPerCampaign = new Map(
							availableAgentsPerCampaign,
						);
						const newLeaderboardPerCampaign = new Map(leaderboardPerCampaign);
						campaignIds.forEach((campaignId) => {
							newAvailableContactsPerCampaign.set(campaignId, []);
							newAvailableAgentsPerCampaign.set(campaignId, 0);
							newLeaderboardPerCampaign.set(campaignId, []);
							canRequestMoreContactsPerCampaignRef.current.set(
								campaignId,
								true,
							);
						});
						setAvailableContactsPerCampaign(newAvailableContactsPerCampaign);
						setAvailableAgentsPerCampaign(newAvailableAgentsPerCampaign);
						setLeaderboardPerCampaign(newLeaderboardPerCampaign);

						// Get contacts for the newly selected channel
						selectedCampaignsRef.current.forEach((campaign) => {
							reportEmptyContacts(campaign);
						});
					}}
					onNumberOfContactsChange={(numberOfContactsToDisplay) => {
						setNumberOfContactsToDisplay(numberOfContactsToDisplay);
					}}
					onClickActionChange={(clickAction) => {
						clickActionRef.current = clickAction;
					}}
					onCampaignListRefreshRequested={() => {
						getAllCampaigns();
					}}
				/>

				{/* Game Container */}
				<Box
					height="100%"
					display="flex"
					justifyContent="center"
					alignItems="center"
					overflow="hidden"
					ref={gameContainerRef}
				>
					{/* Click2Call */}
					{props.click2Call && !allowClick2CallMultiApprove && (getAllAvailableContacts().length > 0 || approvedContacts.length > 0) && (
						<>
							{approvedContacts.length === 0 && (
								<WhackAMoleGame
									parentElement={gameContainerRef}
									numberOfContactsToDisplay={numberOfContactsToDisplay}
									contactsPerCampaign={availableContactsPerCampaign}
									selectedCampaigns={selectedCampaignsRef.current}
									onContactClicked={handleContactClicked}
								/>
							)}

							{approvedContacts.length > 0 && (
								<CallStatusPane
									contact={approvedContacts[0]}
									onDismiss={removeFromCallApproved}
								></CallStatusPane>
							)}
						</>
					)}

					{/* Regular Approval Games */}
					{!props.click2Call && isApprover && getAllAvailableContacts().length > 0 && (
						<BubbleGame
							parentElement={gameContainerRef}
							contactsPerCampaign={filteredContactsPerCampaign}
							selectedCampaigns={selectedCampaignsRef.current}
							selectedChannel={selectedChannelRef.current}
							onContactClicked={handleContactClicked}
						/>
					)}

					{/* Inactive Game Message */}
					{getAllAvailableContacts().length === 0 && approvedContacts.length === 0 && (
						<InactiveGameMessage
							isApprover={isApprover}
							contactsNeedingApproval={() => getAllAvailableContacts()}
							maxSelectableCampaigns={
								props.click2Call
									? MAX_CLICK2CALL_SELECTABLE_CAMPAIGNS
									: MAX_SELECTABLE_CAMPAIGNS
							}
							numberOfCampaigns={allCampaigns.length}
							numberOfSelectedCampaigns={selectedCampaignsRef.current.length}
							gettingCampaigns={gettingCampaigns}
							gettingContacts={gettingContacts}
							onCampaignListRefreshRequested={() => {
								getAllCampaigns();
							}}
						/>
					)}
				</Box>
			</Box>

			{/* Campaign Info Pane */}
			<Box
				sx={{
					width: { sm: DRAWER_WIDTH },
					flexShrink: { sm: 0 },
					display: usePersistentDrawer && showStatusDrawer ? 'block' : 'none',
				}}
			>
				<Drawer
					variant={usePersistentDrawer ? 'persistent' : 'temporary'}
					open={showStatusDrawer}
					onClose={() => {
						setShowStatusDrawer(false);
					}}
					anchor="right"
					sx={{
						'& .MuiDrawer-paper': {
							boxSizing: 'border-box',
							width: DRAWER_WIDTH,
							padding: '15px',
							backgroundColor: theme.palette.mode === 'dark' ? '#1c1c1c' : 'white',
						},
					}}
				>
					<CampaignInfoDrawer
						click2Call={props.click2Call}
						multipleCampaignsSelected={selectedCampaignsRef.current.length > 1}
						gettingContacts={gettingContacts}
						selectedCampaigns={selectedCampaignsRef.current}
						selectedChannel={selectedChannelRef.current}
						availableAgentsPerCampaign={availableAgentsPerCampaign}
						leaderboardPerCampaign={leaderboardPerCampaign}
					// debugApprovalGroupsPerCampaign={approvalGroupsPerCampaign} // Uncomment to display the approval groups
					/>
				</Drawer>
			</Box>
		</Box>
	);
}
