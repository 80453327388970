import { useEffect, useState } from "react"
import {
	Box,
	CircularProgress,
	Dialog,
	DialogTitle,
	DialogContent,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Typography,
} from '@mui/material';
import { useParams } from "react-router-dom";
import { Buffer } from 'buffer';
import { ClickToCall } from "./clickToCall";

export function Workspace(props) {

	/** The tenant that the agent will work on. Since workspaces are limited multiple tenants can be set in the same workspace. */
	const [tenant, setTenant] = useState('');

	/** The type of dialing the agent will be doing. */
	const [dialMode, setDialMode] = useState('');

	/** The type of dialing the agent will be doing. */
	const [loading, setLoading] = useState(true);

	/** The auth object from the params */
	let { tenantAuth } = useParams();

	/** The list of tenant options */
	let [tenantsList, setTenantsList] = useState([]);

	useEffect(() => {
		loadTenantOptions();
	}, [tenantAuth]);

	function loadTenantOptions() {
		const decodedTenantInfo = Buffer.from(tenantAuth, 'base64')
		const parsedTenantInfo = JSON.parse(decodedTenantInfo);
		setTenantsList(parsedTenantInfo);
		if (parsedTenantInfo.length === 1) {
			setTenant(parsedTenantInfo[0]);
		}
		setLoading(false);
	}


	return (
		<>
			<Dialog open={!dialMode || !tenant} fullWidth>
				<DialogTitle>Agent Dialing Workspace</DialogTitle>
				<DialogContent>
					{loading && <CircularProgress color="primary" />}
					{!loading &&
						<Box sx={{ marginTop: '1em' }} display="flex" flexDirection="column" alignItems="center" gap={2}>
							{tenantsList?.length > 1 && <FormControl fullWidth>
								<InputLabel id="tenant-select-label">Select a Tenant</InputLabel>
								<Select
									labelId="tenant-select-label"
									id="tenant-select"
									value={tenant}
									label="Select a Tenant"
									onChange={(e) => setTenant(e.target.value)}
								>
									{tenantsList.map((tenant) => (
										<MenuItem key={tenant.name} value={tenant}>{tenant.name}</MenuItem>
									))}
								</Select>
							</FormControl>}
							{tenant && <FormControl fullWidth>
								<InputLabel id="dial-mode-select-label">Select a Dialing Mode</InputLabel>
								<Select
									labelId="dial-mode-select-label"
									id="dial-mode-select"
									value={dialMode}
									label="Select a Dialing Mode"
									onChange={(e) => setDialMode(e.target.value)}
								>
									<MenuItem value="preview">Preview</MenuItem>
									<MenuItem value="click2call">Click2Call</MenuItem>
								</Select>
							</FormControl>}
						</Box>
					}
				</DialogContent>
			</Dialog>
			{
				!loading && dialMode && tenant && (
					<>
						{/* {dialMode === 'preview' && <PreviewDialing tenant={tenant} />} */}
						<ClickToCall setDialMode={setDialMode} dialMode={dialMode} velocityAuth={tenant} />
					</>
				)
			}

		</>
	)
}
