import { useEffect, useState, useContext } from 'react';
import { UserContext } from '../../contexts/UserContext';
import {
	Typography,
	TextField,
	Box,
	Card,
	CardHeader,
	CardContent,
	Tooltip,
	IconButton,
	LinearProgress
} from '@mui/material';
import { post } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';
import { BarChart } from '@mui/x-charts';
import { Inventory2Outlined } from '@mui/icons-material';
const moment = require('moment');


/**
 * The ExportDialog component is used Allow users to choose how the export data
 * Expects No params our query string values on the route
 * * @category Components
 *  @component
 *
 */
const CampaignStatusBreakdown = function (props) {
	const userContext = useContext(UserContext);
	const [campaignId, setCampaignId] = useState();
	const [segmentId, setSegmentId] = useState();
	const [segmentName, setSegmentName] = useState();
	const [channel, setChannel] = useState();
	const [date, setDate] = useState(moment().startOf('day').format('YYYY-MM-DD'));
	const [retries, setRetries] = useState();
	const [fresh, setFresh] = useState();
	const [priority, setPriority] = useState();

	const labelValue = [
		'12 am',
		'1 am',
		'2 am',
		'3 am',
		'4 am',
		'5 am',
		'6 am',
		'7 am',
		'8 am',
		'9 am',
		'10 am',
		'11 am',
		'12 pm',
		'1 pm',
		'2 pm',
		'3 pm',
		'4 pm',
		'5 pm',
		'6 pm',
		'7 pm',
		'8 pm',
		'9 pm',
		'10 pm',
		'11 pm'
	]

	//matches safe select colors
	const data = {
		datasets: [
			{
				barPercentage: 0.6,
				barThickness: 30,
				maxBarThickness: 40,
				minBarLength: 2,
				data: retries,
				backgroundColor: '#0057ff',
				label: 'Retries'
			},
			{
				barPercentage: 0.6,
				barThickness: 30,
				maxBarThickness: 40,
				minBarLength: 2,
				data: fresh,
				backgroundColor: '#7db33d',
				label: 'Fresh'
			}
		],
		labels: labelValue
	}

	useEffect(() => {
		if (props.segmentId) {
			setCampaignId(props.campaignId);
			setSegmentId(props.segmentId);
			setChannel(props.channel);
		}
	}, [props.segmentId]);

	useEffect(() => {
		if (segmentId) {
			getStatusBreakdown();
		}
	}, [segmentId]);

	useEffect(() => {
		if (date && segmentId) {
			getStatusBreakdown();
		}

	}, [date]);


	async function getStatusBreakdown() {
		setRetries();
		setFresh();
		try {
			const getData = await post({
				apiName: 'cdyxoutreach',
				path: '/cache/status-breakdown',
				options: {
					headers: {
						Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken}`,
						'x-api-key': userContext.apiKey
					},
					body: {
						campaignId: campaignId,
						segmentId: segmentId,
						utcOffset: await getUTCOffset(),
						channel: channel,
						date: date
					}
				}
			}).response;

			const response = await getData.body.json();

			console.log(response);

			setRetries(response.hourlyBreakdown.retries);
			setFresh(response.hourlyBreakdown.fresh);
			setPriority(response.hourlyBreakdown.priority);
			setSegmentName(response.hourlyBreakdown.Segment);
		} catch (err) {
			console.log(err);
		}
	}

	async function getUTCOffset() {
		return moment().utcOffset()
	}

	async function closeDialog() {
		if (props.onCancel) {
			props.onCancel();
		}
		setRetries();
		setFresh();
		setSegmentId();
	}

	return (
		<Box>
			<Card color="primary" elevation={0} variant="outlined" sx={{ borderRadius: '10px', height: '100%' }}>
				<CardHeader title={
					<Box display="flex" justifyContent="space-between" alignItems="center">
						<Typography variant="h6">{segmentName ? segmentName : 'Segment Inventory'}</Typography>
						<Box display="flex" alignItems="center" gap={2}>
							<TextField
								name="date"
								label="Date"
								type="date"
								margin='dense'
								variant="outlined"
								color="primary"
								value={date}
								onChange={(e) => setDate(e.target.value)}
								InputLabelProps={{
									shrink: true
								}} />

							<Tooltip title="View Campaign Inventory">
								<IconButton
									onClick={() => {
										props.setBreakdownId();
										props.setShowBreakdown(false);
									}}
									color="primary">
									<Inventory2Outlined />
								</IconButton>
							</Tooltip>
						</Box>
					</Box>
				} />
				<CardContent sx={{ height: '100%'}}>
					{retries && <BarChart
						series={[
							{ data: retries, label: 'Retries', stack: '1', color: '#fedcc6' },
							{ data: fresh, label: 'Fresh', stack: '1', color: '#b9cde8' },
							{ data: priority, label: 'Priority', stack: '1', color: '#b9e3e8' }
						]}
						height={200}
						borderRadius={6}
						xAxis={[{ data: [...labelValue], scaleType: 'band' }]}
					/>}
					{!retries && <LinearProgress color="primary" />}
				</CardContent>
			</Card>
		</Box >
	)
}
export default CampaignStatusBreakdown;
