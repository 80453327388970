import { Amplify } from 'aws-amplify';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { AuthenticatedLayout } from './AuthenticatedLayout';
import { Contacts } from './pages/contacts/contacts';
import { CustomFields } from './pages/contacts/customFields';
import { FieldMappings } from './pages/contacts/fieldMappings';
import { ContactFieldMapping } from './pages/contacts/contactFieldMapping';
import { MappingTypes } from './models/mappingTypes';
import { Import } from './pages/contacts/import';
import { BulkEdit } from './pages/contacts/bulkEdit';
import { DialingProfiles } from './pages/engagement/profiles';
import { Profile } from './pages/engagement/profile';

import '@aws-amplify/ui-react/styles.css';
import './App.css';
import config from './aws-exports';
import {
	ThemeProvider,
	createTheme,
	// useMediaQuery
} from '@mui/material';
import { useMemo, useState } from 'react';
import { Campaigns } from './pages/engagement/campaigns';
import { Contact } from './pages/contacts/contact';
import { ConnectionDetails } from './pages/connection/connection-details';
import { SnackbarProvider } from 'notistack';
import { Segments } from './pages/contacts/segments';
import { MessageTemplates } from './pages/omnichannel/messageTemplates';
import { MessageTemplate } from './pages/omnichannel/messageTemplate';
import { DispositionMappings } from './pages/connection/dispositionMappings';
import { DNCLists } from './pages/engagement/dnclists';
import { DNC } from './pages/engagement/dnc';
import Approver from './pages/engagement/approver';
import { ContactFieldSuppressionLists } from './pages/engagement/contactFieldSuppressionList';
import { ContactFieldSuppression } from './pages/engagement/contactFieldSuppression';
import { C2AProfiles } from './pages/omnichannel/c2aprofiles';
import { SuppressionProfiles } from './pages/engagement/suppressionProfiles';
import Suppression from './pages/engagement/suppression';
import { C2AProfile } from './pages/omnichannel/c2aProfile';
import Segment from './pages/contacts/segment';
import ScreenPop from './Screenpop';

// Uncomment to help Intellisense in Layer imports for the backend. Not sure why this needs to be done sometimes.
// import { Logger } from '/opt/nodejs/utils/logger.js';

Amplify.configure(config);

export function App() {
	// const [themeMode, setThemeMode] = useState(
	// 	useMediaQuery('(prefers-color-scheme: dark)') ? 'dark' : 'light',
	// );
	const [themeMode, setThemeMode] = useState('light');

	const theme = useMemo(
		() =>
			createTheme({
				fontFamily: 'Roboto, sans-serif',
				palette: {
					mode: themeMode,
					...(themeMode === 'dark'
						? {
							background: {
								default: '#121212',
							},
						}
						: {
							background: {
								default: '#ffffff',
							},
						}),
					primary: {
						main: '#467bc2',
						light: '#c8d7ed',
						dark: '#2f5eac',
					},
					secondary: {
						main: '#fbb589',
						light: '#fee9dc',
						dark: '#f99d6c',
					},
					warning: {
						main: '#ff9800',
					},
					error: {
						main: '#f44336',
					},
					success: {
						main: '#4caf50',
					},
					white: {
						main: '#ffffff',
					},
				},
				drawer: {
					open: 275,
					closed: 73,
				},
			}),
		[themeMode],
	);

	return (
		<>
			<SnackbarProvider maxSnack={3} anchorOrigin={{
				horizontal: 'center',
				vertical: 'bottom'
			}}
				autoHideDuration={3000} />
			<ThemeProvider theme={theme}>
				<BrowserRouter>
					<Routes>
						<Route
							path="/"
							element={
								<AuthenticatedLayout
									themeMode={themeMode}
									setThemeMode={setThemeMode}
								/>
							}
						>
							<Route path="/" element={<Navigate to="/campaigns" />} />
							<Route path="contacts" element={<Contacts />} />
							<Route path="contacts/:id" element={<Contact />} />
							<Route path="custom-fields" element={<CustomFields />} />
							<Route path="segments" element={<Segments />} />
							<Route path="segments/:id" element={<Segment />} />
							<Route path="field-mappings" element={<FieldMappings />} />
							<Route
								path="field-mappings/contact/:id"
								element={
									<ContactFieldMapping mappingType={MappingTypes.Contact} />
								}
							/>
							<Route
								path="field-mappings/metadata/:id"
								element={
									<ContactFieldMapping mappingType={MappingTypes.Metadata} />
								}
							/>
							<Route
								path="field-mappings/call-windows/:id"
								element={
									<ContactFieldMapping mappingType={MappingTypes.CallWindows} />
								}
							/>
							<Route path="contact-import" element={<Import />} />
							<Route path="bulk-edit" element={<BulkEdit />} />
							<Route path="profiles" element={<DialingProfiles />} />
							<Route path="profiles/:id" element={<Profile />} />
							<Route path="campaigns" element={<Campaigns />} />
							<Route path="message-templates" element={<MessageTemplates />} />
							<Route path="message-templates/:id" element={<MessageTemplate />} />
							<Route path="connection-details" element={<ConnectionDetails />} />
							<Route path="disposition-mappings" element={<DispositionMappings />} />
							<Route path='dnc-lists' element={<DNCLists />} />
							<Route path="dnc-lists/:id" element={<DNC />} />
							<Route path="contact-field-suppression" element={<ContactFieldSuppressionLists />} />
							<Route path="contact-field-suppression/:id" element={<ContactFieldSuppression />} />
							<Route path="approver" element={<Approver />} />
							{/* <Route path="suppression/:id" element={<Suppression />} /> */}
							{/* <Route path="bulk-edit" element={<BulkEdit />} /> */}
							<Route path="digital-profiles" element={<C2AProfiles />} />
							<Route path="digital-profiles/:id" element={<C2AProfile />} />
							<Route path="suppression" element={<SuppressionProfiles />} />
							<Route path="suppression/:suppressionId" element={<Suppression />} />
						</Route>

						<Route path="/screenpop/:id" element={<ScreenPop />} />
						<Route path="/click2call/:auth64" element={<ScreenPop click2call={true} />} />
						<Route path="/workspace/:tenantAuth" element={<ScreenPop workSpace={true} />} />
					</Routes>
				</BrowserRouter>
			</ThemeProvider>
		</>
	);
}
