import { useState, useEffect, useContext } from 'react';
import {
	Button,
	CircularProgress,
	Box,
	Card,
	CardHeader,
	CardContent,
	Typography,
	CardActions,
	TextField
} from '@mui/material';
import { UserContext } from '../../contexts/UserContext';
import { generateClient, get, post } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';
import { getCampaign } from 'src/graphql/queries';
import { I18n } from 'aws-amplify/utils';
import { actionOneButtonStyle } from 'src/theme';
import { enqueueSnackbar } from 'notistack';

const STRINGS = {
	'en-US': { // English (United States)
		noActiveCall: 'There is no active call available for callback.',
		createCardHeader: 'Create Call Back for:',
		callBackCreated: 'Call back created successfully. See Callbacks widget for more details.',
		phoneNumberLabel: 'Phone Number',
		callBackTimeLabel: 'Call Back Time',
		notesLabel: 'Notes',
	},
	'es-419': { // Spanish (Latin America)
		noActiveCall: 'No hay una llamada activa disponible para la devolución de llamada.',
		createCardHeader: 'Crear Devolución de Llamada para:',
		callBackCreated: 'Devolución de llamada creada con éxito. Consulte el widget de Devoluciones de llamada para obtener más detalles.',
		phoneNumberLabel: 'Número de teléfono',
		callBackTimeLabel: 'Hora de devolución de llamada',
		notesLabel: 'Notas',
	},
	'pt-BR': { // Portuguese (Brazil)
		noActiveCall: 'Não há uma chamada ativa disponível para retorno de chamada.',
		createCardHeader: 'Criar Retorno de Chamada para:',
		callBackCreated: 'Retorno de chamada criado com sucesso. Consulte o widget de Retornos de chamada para mais detalhes.',
		phoneNumberLabel: 'Número de telefone',
		callBackTimeLabel: 'Hora de retorno de chamada',
		notesLabel: 'Notas',
	}
};
I18n.putVocabularies(STRINGS);

export function CreateCallbacks(props) {
	/** The amplify client */
	const client = generateClient();

	/** The user context to be used for the approver interface. */
	const userContext = useContext(UserContext);

	/** Whether something is currently being loaded in the page. */
	const [loading, setLoading] = useState(false);

	/** The active Call Data retrieved from the agent's call. */
	const [activeCallDetails, setActiveCallDetails] = useState(null);

	/** The phone number to call back. */
	const [callBackNumber, setCallBackNumber] = useState('');

	/** The time to call back. */
	const [callBackTime, setCallBackTime] = useState(Date.now());

	/** The notes for the callback. */
	const [notes, setNotes] = useState('');

	const [callBackCreated, setCallBackCreated] = useState(false);

	/**
	 * Get the active call data for the agent when the component loads.
	 */
	useEffect(() => {
		getActiveCallData();
	}, []);

	/**
	 * Get the needed campaign data for the callback.
	 * @param {string} campaignId The ID of the campaign.
	 * @returns The campaign data.
	 * @throws An error if the campaign data cannot be retrieved.
	 */
	async function getCampaignData(campaignId) {
		const campaign = await client.graphql({
			query: getCampaign,
			variables: {
				id: campaignId
			}
		});
		return campaign.data.getCampaign;
	}

	/**
	 * Create a callback for the agent.
	 */
	async function createCallback() {
		try {
			if (!callBackNumber) {
				throw new Error('No phone number provided');
			}
			if (!callBackTime) {
				throw new Error('No call back time provided');
			}

			const campaign = await getCampaignData(activeCallDetails.campaignId);
			const createCallback = await post({
				apiName: 'cdyxoutreach',
				path: '/cache/createCallback',
				options: {
					headers: {
						Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken}`,
						'x-api-key': userContext.apiKey
					},
					body: {
						campaignId: activeCallDetails.campaignId,
						segmentId: activeCallDetails.segmentId,
						contactId: activeCallDetails.contactId,
						entryPointId: campaign.skillId,
						createdById: props.agentId,
						createdByAgentName: props.agentName,
						callBackPOC: callBackNumber,
						callDateTime: callBackTime,
						agentId: props.agentId,
						note: notes,
						outDialNumber: JSON.parse(campaign.cciMetaData).wbxOutDialAni
					}
				}
			}).response;

			console.log('createCallback', createCallback);

			setCallBackCreated(true);
		} catch (err) {
			console.error('Error creating callback', err);
			enqueueSnackbar({
				message: err,
				options: {
					variant: 'error'
				}
			});
		}

	}


	/**
	 * Get the active call data for the agent.
	 * @returns The active call data.
	 */
	async function getActiveCallData() {
		try {
			setLoading(true);
			if (userContext.telephonyProvider === 'Webex') {
				const { Desktop } = await import('@wxcc-desktop/sdk');
				const taskMap = await Desktop.actions.getTaskMap();
				const mapKeys = Array.from(taskMap.keys());

				// Not on a call
				if (mapKeys.length === 0) {
					console.debug('The agent is not on a call.');
				} else {
					const taskId = mapKeys[0];
					const task = taskMap.get(taskId);
					const callDataValues = task?.interaction?.callAssociatedData;
					const callData = {
						contactId: callDataValues.contactId.value,
						campaignId: callDataValues.campaignId.value,
						segmentId: callDataValues.segmentId.value,
						FirstName: callDataValues.FirstName.value,
						LastName: callDataValues.LastName.value,
						dn: callDataValues.dn.value,
					};

					console.log('callData', callData);
					if (callData && callData.campaignId && callData.segmentId && callData.contactId) {
						setCallBackNumber(callData.dn);
						setActiveCallDetails(callData);
					} else {
						console.log('Not on a call available for callback');
					}
				}
			}
		} catch (error) {
			console.error('Error getting active call data', error);
		}
		setLoading(false);
	}


	return (
		<>
			{/* Callback form */}
			{activeCallDetails && !loading && !callBackCreated && (
				<Box sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}>
					<Card sx={{ width: 600 }}>
						<CardHeader title={`${I18n.get('createCardHeader', STRINGS['en-US'].createCardHeader)} ${activeCallDetails.FirstName} ${activeCallDetails.LastName}`} />
						<CardContent>
							<Box display="flex" flexDirection="column" gap={2}>
								<Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
									<TextField
										label={I18n.get('phoneNumberLabel', STRINGS['en-US'].phoneNumberLabel)}
										variant="outlined"
										value={callBackNumber}
										onChange={(e) => setCallBackNumber(e.target.value)}
									/>
									<TextField
										label={I18n.get('callBackTimeLabel', STRINGS['en-US'].callBackTimeLabel)}
										variant="outlined"
										color="primary"
										type="datetime-local"
										value={callBackTime}
										onChange={(e) => setCallBackTime(e.target.value)}
									/>
								</Box>
								<TextField
									multiline
									label={I18n.get('notesLabel', STRINGS['en-US'].notesLabel)}
									variant="outlined"
									value={notes}
									onChange={(e) => setNotes(e.target.value)}
								/>
							</Box>
						</CardContent>
						<CardActions>
							<Button sx={actionOneButtonStyle} onClick={createCallback}>Create Callback</Button>
						</CardActions>
					</Card>
				</Box>
			)}

			{/* Callback not available */}
			{!activeCallDetails && !loading && (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Typography color='primary' variant="h6">
						{I18n.get('noActiveCall', STRINGS['en-US'].noActiveCall)}
					</Typography>
				</Box>
			)}

			{/* Loading */}
			{loading && (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<CircularProgress
						variant="indeterminate"
						color="primary"
					></CircularProgress>
				</Box>
			)}

			{/* Callback created */}
			{callBackCreated && (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Typography variant="h6" color='primary'>
						{I18n.get('callBackCreated', STRINGS['en-US'].callBackCreated)}
					</Typography>
				</Box>
			)}
		</>
	);
}
