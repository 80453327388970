import { useState, useEffect, useContext, useRef } from 'react';
import queryString from 'query-string';
import {
	TextField,
	Grid,
	InputLabel,
	Select,
	FormControl,
	MenuItem,
	CircularProgress,
	Box,
	Card,
	CardHeader,
	CardContent
} from '@mui/material';
import _ from 'lodash';
import { Formik } from 'formik';
import MaterialTable from '@material-table/core';
import QueryBuilder from '../../components/queryBuilder';
import { UserContext, isDateField } from '../../contexts/UserContext';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { segmentValidation } from '../../components/yupValidations';
import { generateClient } from 'aws-amplify/api';
import { expandedContactSearch, getSegment, listSuppressions } from 'src/graphql/queries';
import { createSegment, updateSegment } from 'src/graphql/mutations';
import { PageAppBar } from 'src/components/pageAppBar';
import { cardStyle } from 'src/theme';

/**
 * The segment class implements creation, update and cloning of campaigns
 * Called Via a route
 * Can have URL params of Segment ID and Action (Edit, or Clone) No params for create
 * * @category Pages
 * @component
 * @param {string} segmentId The component that expects a param of 'data'.
 * @param {string} action The graphql query string to retreive data with.
 */
export default function Segment() {
	const client = generateClient();
	const userContext = useContext(UserContext);
	const navigate = useNavigate();
	const location = useLocation();
	const { id } = useParams();
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [total, setTotal] = useState(0);
	const [customFieldColumns, setCustomFieldColumns] = useState([]);
	const [segmentState, setSegment] = useState({
		name: '',
		description: '',
		query: {
			qbQuery: {
				combinator: 'and',
				rules: []
			},
			searchFilter: {},
			sort: {
				field: '',
				direction: ''
			}
		},
		filter: {
			qbQuery: {
				combinator: 'and',
				rules: []
			},
			searchFilter: {}
		},
		suppressionID: ''
	});

	const [action, setAction] = useState('');

	const throttledContactPreview = useRef(_.throttle(previewContacts, 1000, { 'leading': false, 'trailing': true }));

	const [qbPOCFields, setQbPOCFields] = useState([
		{
			name: 'field',
			label: 'Field',
			type: 'select',
			values: [
				{
					label: 'Phone',
					name: 'phone'
				},
				{
					label: 'Cell',
					name: 'cell',
				},
				{
					label: 'Email',
					name: 'email'
				}
			]
		},
		{
			name: 'poc.cell',
			label: 'Cell',
			type: 'select',
			values: [
				{
					label: 'True',
					name: true
				},
				{
					label: 'False',
					name: false
				}
			],
			operators: [
				{ name: 'eq', label: '=' },
				{ name: 'ne', label: '!=' }
			]
		},
		{
			name: 'poc.consent',
			label: 'Consent',
			type: 'select',
			values: [
				{
					label: 'True',
					name: true
				},
				{
					label: 'False',
					name: false
				}
			],
			operators: [
				{ name: 'eq', label: '=' },
				{ name: 'ne', label: '!=' }
			]
		},
		{
			name: 'poc.optOut',
			label: 'Opt Out',
			type: 'select',
			values: [
				{
					label: 'True',
					name: true
				},
				{
					label: 'False',
					name: false
				}
			],
			operators: [
				{ name: 'eq', label: '=' },
				{ name: 'ne', label: '!=' }
			]
		},
		{
			name: 'poc.thirdParty',
			label: 'Third Party',
			type: 'select',
			values: [
				{
					label: 'True',
					name: true
				},
				{
					label: 'False',
					name: false
				}
			],
			operators: [
				{ name: 'eq', label: '=' },
				{ name: 'ne', label: '!=' }
			]
		},
		{
			name: 'poc.preferred',
			label: 'Preferred',
			type: 'select',
			values: [
				{
					label: 'True',
					name: true
				},
				{
					label: 'False',
					name: false
				}
			],
			operators: [
				{ name: 'eq', label: '=' },
				{ name: 'ne', label: '!=' }
			]
		},
		{
			name: 'poc.dontCallOnWeekends',
			label: 'Don\'t Call on Weekends',
			type: 'select',
			values: [
				{
					label: 'True',
					name: true
				},
				{
					label: 'False',
					name: false
				}
			],
			operators: [
				{ name: 'eq', label: '=' },
				{ name: 'ne', label: '!=' }
			]
		}
	]);

	const sortFields = [
		{ name: 'firstName', label: 'First Name' },
		{ name: 'lastName', label: 'Last Name' },
		{ name: 'source', label: 'Source' },
		{ name: 'phone', label: 'Phone' },
		{ name: 'cell', label: 'Cell' },
		{ name: 'email', label: 'eMail' },
		{ name: 'dateCreated', label: 'Date Created' },
		{ name: 'status', label: 'Status' },
		{ name: 'city', label: 'City' },
		{ name: 'state', label: 'State' },
		{ name: 'zip', label: 'Zip' },
	];
	const sortDirections = [
		{ name: 'asc', label: 'Ascending' },
		{ name: 'desc', label: 'Descending' }
	];
	const [previewColumns, setPreviewColumns] = useState([
		{ title: 'First Name', field: 'firstName' },
		{ title: 'Last Name', field: 'lastName' },
		{ title: 'Source', field: 'source' },
		{ title: 'Phone', field: 'phone' },
		{ title: 'Cell', field: 'cell' },
		{ title: 'Email', field: 'email' },
		{ title: 'State', field: 'state' },
	]);
	const [suppressionProfiles, setSuppressionProfiles] = useState([]);

	useEffect(() => {
		if (customFieldColumns.length > 0) {
			const columns = [
				{ title: 'First Name', field: 'firstName' },
				{ title: 'Last Name', field: 'lastName' },
				{ title: 'Source', field: 'source' },
				{ title: 'Phone', field: 'phone' },
				{ title: 'Cell', field: 'cell' },
				{ title: 'Email', field: 'email' },
				{ title: 'State', field: 'state' },
			];
			for (const customField of customFieldColumns) {
				const customFieldData = userContext.customFields.find(field => field.name === customField);
				columns.push({
					title: customFieldData.displayName,
					field: customFieldData.name,
					render: rowData => (rowData.customFields && renderCustomField(customFieldData.name, rowData))
				});
			}
			setPreviewColumns(columns);
		}
	}, [customFieldColumns]);

	const renderCustomField = (fieldName, rowData) => {
		const customFields = JSON.parse(rowData.customFields);
		if (customFields[fieldName] != null) {
			if (isDateField(fieldName)) {
				return new Date(customFields[fieldName]).toLocaleString();
			} else {
				return `${customFields[fieldName]}`;
			}
		} else {
			return '';
		}
	}

	useEffect(() => {
		async function getData() {
			setLoading(true);
			//Are we creating? Editing? Or Cloning
			try {
				// Get the current Segment information
				const results = await Promise.all([
					client.graphql({
						query: getSegment,
						variables: { id: id },
					}),
					client.graphql({
						query: listSuppressions
					})
				])
				setSuppressionProfiles(results[1].data.listSuppressions.items);
				const dbSegment = { ...results[0].data.getSegment }
				const values = queryString.parse(location.search);
				//if we are editing, set the values for the object includeing the id, set the mutation
				if (values.op === 'edit') {
					setAction('edit');
					if (!dbSegment.query.sort) {
						dbSegment.query.sort = {
							field: '',
							direction: ''
						};
					}
				} else if (values.op === 'clone') {
					setAction('clone');
					dbSegment.name = `${dbSegment.name} - Clone`
				} else {
					setAction('create');
				}
				if (dbSegment && dbSegment.query) {
					dbSegment.query.qbQuery = JSON.parse(dbSegment.query.qbQuery);
					dbSegment.query.searchFilter = JSON.parse(dbSegment.query.searchFilter);
					dbSegment.filter.qbQuery = JSON.parse(dbSegment.filter.qbQuery);
					dbSegment.filter.searchFilter = JSON.parse(dbSegment.filter.searchFilter);
					setSegment(dbSegment);
				}
			} catch (err) {
				console.error(err);
			}

			setLoading(false);
		}
		getData();
	}, [id, location.search]);

	useEffect(() => {
		const cancel = throttledContactPreview.current.cancel;
		return () => cancel();
	}, []);

	useEffect(() => {
		if (userContext.customFields) {
			previewContacts(segmentState.query, segmentState.filter);
		}
	}, [userContext.customFields, userContext.tenantId]);

	const processFilterArray = (items, combinator) => {
		const ruleArray = [];
		if (userContext.tenantId) {
			const ruleGroup = { [combinator]: ruleArray };
			//the rule array always has to have the tenant included for security
			_.forEach(items, (item) => { ruleArray.push(processFilterItem(item, items.combinator)) });
			const tenantRuleGroup = { and: [{ tenant: { eq: userContext.tenantId } }, ruleGroup] };
			return tenantRuleGroup;
		} else {
			return {}
		}
	}

	const processFilterItem = (item) => {
		const cpyItem = { ...item };
		if (item.field) {
			//item.value = item.value.toLowerCase();
			if (!item.field.startsWith('custom:')) {
				if (cpyItem.operator === 'wildcard') {
					cpyItem.value = cpyItem.value.toLowerCase();
					cpyItem.value = `*${cpyItem.value}*`;
				}
				if (cpyItem.operator === 'wildcard*') {
					cpyItem.value = cpyItem.value.toLowerCase();
					cpyItem.value = `${cpyItem.value}*`;
					cpyItem.operator = 'wildcard';
				}
				if (cpyItem.operator === '*wildcard') {
					cpyItem.value = cpyItem.value.toLowerCase();
					cpyItem.value = `*${cpyItem.value}`;
					cpyItem.operator = 'wildcard';
				}
				const ruleItem = { [cpyItem.field]: { [cpyItem.operator]: cpyItem.value } };
				return ruleItem;
			} else {
				const fieldName = cpyItem.field.split(':')[1];
				if (cpyItem.operator === 'wildcard') {
					cpyItem.value = `*${cpyItem.value.toLowerCase()}*`;
				} else if (cpyItem.operator === 'wildcard*') {
					cpyItem.value = `${cpyItem.value.toLowerCase()}*`;
					cpyItem.operator = 'wildcard';
				} else if (cpyItem.operator === '*wildcard') {
					cpyItem.value = `*${cpyItem.value.toLowerCase()}`;
					cpyItem.operator = 'wildcard';
				} else {
					if (cpyItem.value === true || cpyItem.value === false || cpyItem.value === 'true' || cpyItem === 'false') {
						if (_.isString(cpyItem.value)) {
							cpyItem.value = cpyItem.value === 'true';
						} else {
							cpyItem.value = cpyItem.value === true;
						}
					} else if (!isNaN(cpyItem.value)) {
						cpyItem.value = +cpyItem.value;
					} else if (!!Date.parse(cpyItem.value)) {
						cpyItem.value = Date.parse(cpyItem.value);
					}
				}
				const ruleItem = { [`customFields.${fieldName}`]: { [cpyItem.operator]: cpyItem.value } };
				return ruleItem;
			}
		}
		return processFilterArray(cpyItem.rules, cpyItem.combinator);
	}

	async function previewContacts(contactQuery) {
		try {
			const fieldReplacements = ['#DOY']

			let options = {
				limit: 250
			};
			options.filter = JSON.stringify(contactQuery.searchFilter);
			// Handle Field Replacements
			for (const field of fieldReplacements) {
				switch (field) {
					case '#DOY':
						options.filter = options.filter.replaceAll(field, dayOfTheYear(new Date()));
						break;
					default:
						break;
				}
			}



			if (contactQuery.sort.field !== '') {
				options.sort = contactQuery.sort;
			}
			const listData = await client.graphql({
				query: expandedContactSearch,
				variables: options
			});

			setData([...listData.data.expandedContactSearch.items]);
			setTotal(listData.data.expandedContactSearch.total === null ? 0 : listData.data.expandedContactSearch.total);
		}
		catch (error) {
			console.error(error);
		}
	}

	function dayOfTheYear(date) {
		return Math.floor((date - new Date(date.getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24);
	}

	const handleButtonClose = (path) => {
		navigate('/segments');
	}

	const getValues = (field) => {
		const selectedField = _.find(qbPOCFields, ['name', field]);
		if (selectedField) {
			return selectedField.values;
		} else {
			return null;
		}
	}

	const getType = (field) => {
		const selectedField = _.find(qbPOCFields, ['name', field]);
		if (selectedField) {
			return selectedField.type;
		} else {
			return null;
		}
	}

	const getOperators = (field) => {
		const selectedField = _.find(qbPOCFields, ['name', field]);
		if (selectedField) {
			return selectedField.operators
		} else {
			return null;
		}
	}

	function handleQueryChange(query, formikProps) {
		if (query?.rules?.length === 0) {
			setData([]);
			setTotal(0);
		}

		formikProps.setFieldValue('query.qbQuery', query);
		const newFilter = processFilterArray(query.rules, query.combinator);
		formikProps.setFieldValue('query.searchFilter', newFilter);

		throttledContactPreview.current(_.cloneDeep({
			qbQuery: query,
			searchFilter: newFilter,
			sort: formikProps.values.query.sort
		}));

		let customFields = [];

		try {
			// Get the custom fields in the ruleSet
			customFields = getCustomFieldsFromRules(query);
		} catch (err) {
			console.error('Unable to display custom fields: ', err);
		}
		// Dedup the custom fields
		customFields = _.uniq(customFields);
		setCustomFieldColumns(customFields);
	}

	function getCustomFieldsFromRules(rule) {
		const customFields = [];
		if (rule.field) {
			if (rule.field.startsWith('custom:')) {
				const fieldName = rule.field.split(':')[1];
				customFields.push(fieldName);

			}
		} else {
			_.forEach(rule.rules, (subRule) => {
				customFields.push(...getCustomFieldsFromRules(subRule));
			});
		}
		return customFields;

	}

	function handlePocQueryChange(query, formikProps) {
		formikProps.setFieldValue('filter.qbQuery', query);
		const newFilter = processFilterArray(query.rules, query.combinator)
		formikProps.setFieldValue('filter.searchFilter', newFilter);
	}

	return (
		<>
			{loading &&
				<CircularProgress variant='indeterminate' color='primary' />}
			{!loading &&
				<Grid container direction="column" spacing={2}>
					<Grid item>
						<Formik
							initialValues={segmentState}
							enableReinitialize={true}
							validationSchema={segmentValidation}
							onSubmit={async originalValues => {
								const values = _.cloneDeep(originalValues);
								//dynamodb doesn't like empty strings. This will turn empty strings into null
								for (const key in values) {
									if (key === 'startDate' || key === 'expireDate') {
										const date = new Date(values[key]).toISOString();
										values[key] = date;
									}
									values[key] = values[key] || null;
								}

								//DynamoDB flips out if the externalId is null.
								//If the externalId is present and is null, remove it.
								if ('externalId' in values) {
									if (values['externalId'] === null)
										delete values['externalId'];
								}
								values.query.qbQuery = JSON.stringify(values.query.qbQuery);
								values.query.searchFilter = JSON.stringify(values.query.searchFilter);
								values.filter.qbQuery = JSON.stringify(values.filter.qbQuery);
								values.filter.searchFilter = JSON.stringify(values.filter.searchFilter);

								values.tenant = userContext.tenantId;
								delete values.createdAt;
								delete values.updatedAt;
								delete values.suppression;

								try {
									if (action === 'edit') {
										await client.graphql({
											query: updateSegment,
											variables: { input: values }
										});
									}
									else {
										if (action === 'clone') {
											delete values.id;
										}
										await client.graphql({
											query: createSegment,
											variables: { input: values }
										});
									}
									handleButtonClose('/segments');
								} catch (err) {
									console.error(err);
								}

								values.query.qbQuery = JSON.parse(values.query.qbQuery);
								values.query.searchFilter = JSON.parse(values.query.searchFilter);
								values.filter.qbQuery = JSON.parse(values.filter.qbQuery);
								values.filter.searchFilter = JSON.parse(values.filter.searchFilter);
								setSegment({ ...values });
							}}
						>
							{formikProps => (
								<form onSubmit={formikProps.handleSubmit}>
									<Box display="flex" flexDirection="column" gap={2}>
										<PageAppBar
											title="Segment"
											description="Create, edit, or clone a segment."
											actionOneText="Save"
											actionOneHandler={formikProps.handleSubmit}
											actionTwoText="Close"
											actionTwoHandler={() => handleButtonClose('/segments')}
										/>
										<TextField
											autoFocus
											color="primary"
											name="name"
											label="Name"
											type="text"
											required={true}
											onChange={formikProps.handleChange}
											onBlur={formikProps.handleBlur}
											value={formikProps.values.name}
											error={formikProps.errors.name && formikProps.touched.name} />

										<TextField
											color="primary"
											name="description"
											label="Description"
											type="text"
											rows={2}
											onChange={formikProps.handleChange}
											onBlur={formikProps.handleBlur}
											value={formikProps.values.description}
											error={formikProps.errors.description && formikProps.touched.description} />

										<FormControl>
											<InputLabel>Suppression Profile</InputLabel>
											<Select
												label="Suppression Profile"
												color="primary"
												margin="dense"
												name="suppressionID"
												onChange={formikProps.handleChange}
												onBlur={formikProps.handleBlur}
												value={formikProps.values.suppressionID}
												error={formikProps.errors.suppressionID && formikProps.touched.suppressionID}>
												<MenuItem value=''>None</MenuItem>
												{suppressionProfiles.map((suppression, index) =>
													<MenuItem key={index} value={suppression.id}>{suppression.name}</MenuItem>
												)}
											</Select>
										</FormControl>

										<Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>

											<Card
												style={cardStyle}
												elevation={0}
											>
												<CardHeader title="Contact Query"
													titleTypographyProps={
														{
															variant: 'h6',
														}}
												/>
												<CardContent>
													<QueryBuilder
														fields={userContext.queryFields.list}
														getValueEditorType={field => userContext.queryFields.typeLookup(field)}
														query={formikProps.values.query.qbQuery}
														onQueryChange={query => handleQueryChange(query, formikProps)}
														showCombinatorsBetweenRules={true}
													/>
												</CardContent>
											</Card>

											<Card
												style={cardStyle}
												elevation={0}
											>
												<CardHeader title="POC Query"
													titleTypographyProps={
														{
															variant: 'h6',
														}}
												/>
												<CardContent>
													<QueryBuilder
														getValueEditorType={getType}
														getInputType={getType}
														getValues={getValues}
														getOperators={getOperators}
														fields={qbPOCFields}
														query={formikProps.values.filter.qbQuery}
														onQueryChange={query => handlePocQueryChange(query, formikProps)}
														showCombinatorsBetweenRules={true} />
												</CardContent>
											</Card>

										</Box>

										<MaterialTable title={`Preview Segment Inventory (${total})`}
											columns={previewColumns}
											data={data}
											options={{
												actionsColumnIndex: -1,
												pageSize: 10
											}}
											// actions={[{
											// 	isFreeAction: true,
											// 	toolip: 'Bulk Edit/Delete',
											// 	onClick: () => navigate('/bulk-edit'),
											// 	icon: () => <Button sx={actionOneButtonStyle} >Bulk Edit/Delete</Button>
											// }]}
											onRowClick={(event, rowData) => {
												navigate(`/contacts/${rowData.id}`);
											}}
										/>
									</Box>
								</form>
							)}
						</Formik>
					</Grid>
					<Grid item>

					</Grid>
				</Grid>}
		</>
	)
}
