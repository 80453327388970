import React, { Fragment } from 'react';
import Rule from './Rule';
import { Box, Grid, Paper } from '@mui/material';

const RuleGroup = ({ id, parentId, combinator, rules, translations, schema, not }) => {
  const {
    combinators,
    controls,
    createRule,
    createRuleGroup,
    getLevel,
    isRuleGroup,
    onGroupAdd,
    onGroupRemove,
    onPropChange,
    onRuleAdd,
    showCombinatorsBetweenRules,
    showNotToggle
  } = schema;

  const hasParentGroup = () => !!parentId;

  const onCombinatorChange = (value) => {
    onPropChange('combinator', value, id);
  };

  const onNotToggleChange = (checked) => {
    onPropChange('not', checked, id);
  };

  const addRule = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const newRule = createRule();
    onRuleAdd(newRule, id);
  };

  const addGroup = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const newGroup = createRuleGroup();
    onGroupAdd(newGroup, id);
  };

  const removeGroup = (event) => {
    event.preventDefault();
    event.stopPropagation();

    onGroupRemove(id, parentId);
  };

  const level = getLevel(id);

  return (
		<Paper elevation={0} sx={{ border: 1, borderRadius: '8px', borderColor: '#b9cde8', margin: '10px', padding: '10px' }}>
      <Box gap={1} display="flex" flexDirection="column" justifyContent="flex-start" fullWidth data-rule-group-id={id} data-level={level}>

        {rules.map((r, idx) => (
          <Fragment key={r.id}>
            {idx && showCombinatorsBetweenRules ? (
              <controls.combinatorSelector
                options={combinators}
                value={combinator}
                title={translations.combinators.title}
                handleOnChange={onCombinatorChange}
                rules={rules}
                level={level}
              />
            ) : null}
            {isRuleGroup(r) ? (
              <RuleGroup
                id={r.id}
                schema={schema}
                parentId={id}
                combinator={r.combinator}
                translations={translations}
                rules={r.rules}
                not={r.not}
              />
            ) : (
                <Rule
                  id={r.id}
                  field={r.field}
                  value={r.value}
                  operator={r.operator}
                  schema={schema}
                  parentId={id}
                  translations={translations}
                />
              )}
          </Fragment>
        ))}
				        <Grid container item direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
          {showCombinatorsBetweenRules ? null : (
            <controls.combinatorSelector
              options={combinators}
              value={combinator}
              title={translations.combinators.title}
              handleOnChange={onCombinatorChange}
              rules={rules}
              level={level}
            />
          )}
          {!showNotToggle ? null : (
            <controls.notToggle
              title={translations.notToggle.title}
              checked={not}
              handleOnChange={onNotToggleChange}
            />
          )}
          <controls.addRuleAction
            label={translations.addRule.label}
            title={translations.addRule.title}
            handleOnClick={addRule}
            rules={rules}
            level={level}
          />
          <controls.addGroupAction
            label={translations.addGroup.label}
            title={translations.addGroup.title}
            handleOnClick={addGroup}
            rules={rules}
            level={level}
          />
          {hasParentGroup() ? (
            <controls.removeGroupAction
              label={translations.removeGroup.label}
              title={translations.removeGroup.title}
              handleOnClick={removeGroup}
              rules={rules}
              level={level}
              className={`iconBtn`}
            />
          ) : null}
        </Grid>
      </Box>
			</Paper>
  );
};

RuleGroup.defaultProps = {
  id: null,
  parentId: null,
  rules: [],
  combinator: 'and',
  schema: {}
};

RuleGroup.displayName = 'RuleGroup';

export default RuleGroup;
