
import React, { act, useContext, useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, CircularProgress, Typography, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { UserContext } from 'src/contexts/UserContext';
import { generateClient, get } from 'aws-amplify/api';
import { esQuery, searchCampaignLogs } from 'src/graphql/queries';
import { BarChart } from "@mui/x-charts/BarChart";
import { PieChart } from '@mui/x-charts';
import { actionTwoButtonStyle } from 'src/theme';
import { ListOutlined, PieChartOutline } from '@mui/icons-material';


const CampaignCallDetailsDialog = ({ open, data, type, onClose }) => {
	const userContext = useContext(UserContext);
	const client = generateClient();
	const [displayData, setDisplayData] = useState([]);
	const [displayMode, setDisplayMode] = useState('chart');

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (open) {
			setLoading(true);
			if (type === 'disposition') {
				const dispositionData = [];
				if (data?.aggregations?.disposition?.buckets) {
					for (const disposition of data?.aggregations?.disposition?.buckets) {
						dispositionData.push({ label: disposition.key, value: disposition.doc_count });
					}
				}
				dispositionData.sort((a, b) => b.count - a.count);
				setDisplayData(dispositionData);
			} else if (type === 'agent') {
				const agentData = [];
				if (data?.aggregations?.disposition?.buckets) {
					for (const disposition of data?.aggregations?.disposition?.buckets) {
						for (const agent of disposition.agent.buckets) {
							const index = agentData.findIndex((a) => a.label === agent.key);
							if (index === -1) {
								agentData.push({ label: agent.key, [disposition.key]: agent.doc_count });
							} else {
								agentData[index][disposition.key] = agent.doc_count;
							}

						}
					}
					agentData.sort((a, b) => b.count - a.count);
					setDisplayData(agentData);
				}
			}
		}
	}, [open, type]);

	// Turn of loading when the state is set
	useEffect(() => {
		setLoading(false);
	}, [displayData]);

	function getColors() {
		const colors = [];
		for (let i = 0; i < displayData.length; i++) {
			// Generate pastel colors
			const r = Math.floor(Math.random() * 256);
			const g = Math.floor(Math.random() * 256);
			const b = Math.floor(Math.random() * 256);
			const color = `rgb(${r},${g},${b})`;
			colors.push(color);
		}
		return colors;
	}

	function generateSeries(agentObject) {
		const series = [];
		for (const key in agentObject) {
			if (key !== 'label') {
				series.push({ dataKey: key, label: key, stack: '1' });
			}
		}
		return series;
	}

	function getAgentCallCount(agent) {
		let count = 0;
		for (const key in agent) {
			if (key !== 'label') {
				count += agent[key];
			}
		}
		return count;
	}

	return (
		<Dialog open={open} onClose={onClose} maxWidth="xl" >
			<DialogTitle>{type === 'disposition' ? 'Disposition' : 'Agent'} Details</DialogTitle>
			<DialogContent sx={{ padding: 3 }}>
				{type === 'disposition' && <ToggleButtonGroup
					size="small"
					value={displayMode}
					exclusive
					onChange={(e) => setDisplayMode(e.target.value)}
				>
					<ToggleButton value="chart"><PieChartOutline /></ToggleButton>
					<ToggleButton value="list"><ListOutlined /></ToggleButton>
				</ToggleButtonGroup>}
				<Box sx={{ display: 'flex', justifyContent: 'center' }}>
					{type === 'disposition' &&
						<>

							{displayMode === 'chart' && <PieChart
								// colors={getColors}
								series={[{ data: displayData }]}
								height={300}
								width={700}
							/>}
							{displayMode === 'list' &&
								<Box display="flex" flexDirection="column">
									{displayData.map((disposition, index) => (
										<Box display="flex" flexDirection="column" gap={2} key={index}>
											<Box display="flex" justifyContent="space-between">
												<Box display="flex" flexDirection="column">
													<Typography variant="h6">Disposition</Typography>
													<Typography variant="h6">{disposition.label}</Typography>
												</Box>
												<Box display="flex" flexDirection="column">
													<Typography variant="h6">Count</Typography>
													<Typography variant="h6">{disposition.value}</Typography>
												</Box>
											</Box>
										</Box>
									))}
								</Box>
							}
						</>
					}
					{type === 'agent' &&
						<Box display="grid" gridTemplateColumns="1fr" gap={2}>
							{displayData.map((agent, index) => (
								<Box display="flex" flexDirection="column" gap={2} key={index}>
									<Box display="flex" justifyContent="space-between">
										<Box display="flex" flexDirection="column">
											<Typography variant="h6">Agent Name</Typography>
											<Typography variant="h6">{agent.label}</Typography>
										</Box>
										<Box display="flex" flexDirection="column">
											<Typography variant="h6">Total Calls</Typography>
											<Typography variant="h6">{getAgentCallCount(agent)}</Typography>
										</Box>
									</Box>
									<BarChart
										leftAxis={null}
										bottomAxis={null}
										layout="horizontal"
										dataset={[agent]}
										series={generateSeries(agent)}
										yAxis={[{ scaleType: "band", dataKey: "label", categoryGapRatio: 0.7 }]}
										borderRadius={50}
										height={125}
										width={700}
										slotProps={{ legend: { hidden: true } }}
									/>
								</Box>
							))}

						</Box>}
					{loading ? <CircularProgress /> : null}
				</Box>
			</DialogContent>
			<DialogActions>
				<Button sx={actionTwoButtonStyle} onClick={onClose} color="primary">
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default CampaignCallDetailsDialog;
