import { StorageManager } from '@aws-amplify/ui-react-storage';
import { generateClient, post, del } from 'aws-amplify/api';
import { Typography, TextField, Button, FormControlLabel, InputAdornment, Tooltip, Tabs, Tab, Box, Switch } from '@mui/material';
import {  InfoOutlined, DeleteOutline } from '@mui/icons-material';
import MaterialTable, { MTableAction } from '@material-table/core';
import React, { useEffect, useContext, useState, useRef, forwardRef } from 'react';
import { UserContext } from '../../contexts/UserContext';
import { useSnackbar } from 'notistack';
import phone from 'phone';

import _ from 'lodash';
import { IS_PROD } from '../../models/globals';
import { dncByList, getDncList } from 'src/graphql/queries';
import { fetchAuthSession } from 'aws-amplify/auth';
import { createDnc, deleteDnc } from 'src/graphql/mutations';
import { ConfirmDialog } from 'src/components/confirmDialog/confirmDialog';
import { useParams } from 'react-router-dom';
import { PageAppBar } from 'src/components/pageAppBar';
import { actionOneButtonStyle, actionTwoButtonStyle, tabStyle } from 'src/theme';
import { TextAreaField } from '@aws-amplify/ui-react';

export function DNC() {
	const client = generateClient();
	const userContext = useContext(UserContext);
	const { id } = useParams();
	const displayMax = 1000;
	const { enqueueSnackbar } = useSnackbar();
	const [selDncList, setSelDncList] = useState({});
	const [dncs, setDncs] = useState([]);
	const [loading, setLoading] = useState(false);
	const [delimiter, setDelimiter] = useState(';');
	const [skipInvalidNumbers, setSkipInvalidNumbers] = useState(false);
	const [currentTab, setCurrentTab] = useState(0);
	const [deleting, setDeleting] = useState(false);
	const [adding, setAdding] = useState(false);
	const [confirmDeleteSelection, setConfirmDeleteSelection] = useState(false);
	const [selectionToDelete, setSelectionToDelete] = useState([]);
	const [filterActive, setFilterActive] = useState(false);
	const nextToken = useRef('');
	const currentToken = useRef('');
	const [filterKeyword, setFilterKeyword] = useState('');
	const [filterOperation, setFilterOperation] = useState('beginsWith');
	const [uploading, setUploading] = useState(false);
	const multiBoxRef = useRef(null);
	const listTableRef = useRef();
	const numberTableRef = useRef();
	const [newNumberTrigger, setNewNumberTrigger] = useState(false);

	useEffect(() => {
		async function getData() {
			// Get the DNC list
			const result = await client.graphql({
				query: getDncList,
				variables: { id, tenant: userContext.tenantId }
			});
			console.log(result.data.getDNCList);
			setSelDncList(result.data.getDNCList);
		}
		if (id) {
			getData();
		}

	}, [id, userContext.tenantId]);

	useEffect(() => {
		if (selDncList?.id) {
			getNumbers();
		}

	}, [selDncList, filterActive]);

	async function getNumbers() {
		setLoading(true);

		const variables = { limit: displayMax, dncList: selDncList.id };
		if (currentToken.current) {
			variables.nextToken = currentToken.current;
		}

		if (filterKeyword && filterOperation && filterActive) {
			variables.phoneNumber = { [filterOperation]: filterKeyword };
		}

		let result = await client.graphql({
			query: dncByList,
			variables: variables
		});

		setDncs(result.data.dncByList.items);
		nextToken.current = result?.data?.dncByList?.nextToken;
		setLoading(false);
	}

	function isNumberValid(number) {
		return phone(number, { validateMobilePrefix: IS_PROD }).isValid;
	}

	function getMultiNumbers() {
		let separator = delimiter.trim();
		if (separator === '\\r\\n') {
			separator = '\n';
		}

		const numberStrings = multiBoxRef.current.split(separator);

		let hasInvalid = false;

		const numbers = [];
		const strings = [];

		for (let numberString of numberStrings) {
			numberString = numberString.trim();
			if (numberString) {
				const phoneNumber = phone(numberString, { validateMobilePrefix: IS_PROD });
				if (!phoneNumber.isValid) {
					hasInvalid = true;
					numberString = `<span style="background-color: #E8A4BA; border-radius: 3px;">${numberString}</span>`;
				} else {
					numbers.push(phoneNumber.phoneNumber);
				}

				strings.push(numberString);
			}
		}

		multiBoxRef.current = strings.join(separator);

		if (hasInvalid && !skipInvalidNumbers) {
			return [false, []];
		}

		return [true, numbers];
	}

	function formatData() {
		let numbers = [];

		if (delimiter.trim() === '') {
			enqueueSnackbar("A delimiter is required", { variant: 'error' });
			return [false, []];
		}

		if (multiBoxRef.current.trim() === '') {
			enqueueSnackbar("Please provide at least one number", { variant: 'info' });
			return [false, []];
		}

		const result = getMultiNumbers();
		numbers = result[1];

		if (!result[0]) {
			enqueueSnackbar("Invalid number found.", { variant: 'error' });
			return [false, []];
		}


		if (numbers.length === 0) {
			enqueueSnackbar("No numbers to process", { variant: 'info' });
			return [false, []];
		}

		return [true, numbers];
	}

	async function handleAdd() {
		setAdding(true);
		const result = formatData();
		if (result[0]) {
			await post({
				apiName: 'cdyxoutreach',
				path: '/dnc',
				options: {
					headers: {
						Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken}`,
						'x-api-key': userContext.apiKey
					},
					body: {
						tenant: userContext.tenantId,
						numbers: result[1],
						listId: selDncList.id
					}
				}
			}).response;
			await getNumbers();
		}
		setAdding(false);
		setCurrentTab(0);
	}

	async function handleRemove() {
		setDeleting(true);
		const result = formatData();
		if (result[0]) {
			const response = await unsafeDeleteListOfNumbers(result[1]);
			if (typeof (response) === 'object' && "numbers" in (response ?? {})) {
				enqueueSnackbar("Some numbers were unable to be deleted", { variant: 'warning' });
			}

			await getNumbers();
		}
		setDeleting(false);
	}

	async function unsafeDeleteListOfNumbers(numbers) {
		return await del({
			apiName: 'cdyxoutreach',
			path: '/dnc',
			options: {
				headers: {
					Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken}`,
					'x-api-key': userContext.apiKey
				}
			},
			body: {
				tenant: userContext.tenantId,
				numbers: numbers,
				listId: selDncList.id
			}
		}).response;
	}

	async function handleUpload(path) {
		try {
			const urlObject = await getUrl({
				path,
				options: {
					level: 'private',
				},
			});

			const url = urlObject.url.toString();
			const session = await fetchAuthSession();

			await post({
				apiName: 'cdyxoutreach',
				path: '/dnc/import',
				options: {
					headers: {
						Authorization: `Bearer ${session.tokens.idToken}`,
						'x-api-key': userContext.apiKey,
					},
					body: {
						tenant: userContext.tenantId,
						url,
						email: (await fetchAuthSession()).attributes.email,
						listId: selDncList.id
					}
				}
			}).response;

			enqueueSnackbar("File uploaded successfully. An email will be sent upon import completion.", { variant: 'success' });
		} catch (err) {
			console.log(err);
			enqueueSnackbar("There was an error uploading and importing the file.", { variant: 'error' });
		}
		setUploading(false);
		setCurrentTab(0);
	}

	async function handleConfirmDeleteSelection(data) {
		setLoading(true);
		setConfirmDeleteSelection(false);
		setDeleting(true);
		await unsafeDeleteListOfNumbers(data.map(x => x.phoneNumber));
		const deletedHash = new Set(data.map(x => x.id));
		setDncs(dncs.filter(x => !deletedHash.has(x.id)));
		setDeleting(false);
		setLoading(false);
	}

	function handleAddNewNumber() {
		numberTableRef.current.state.showAddRow = true;
		setNewNumberTrigger(!newNumberTrigger);
	}

	function handleSearchChange(value) {
		window.sessionStorage.setItem("DNCListsSearch", value);
	}

	return (
		<Box>
			<PageAppBar
				title={selDncList?.name ?? 'DNC List'}
				description='Manage numbers in this DNC list'
				actionOneText={currentTab === 0 ? '+ Number' : ''}
				actionOneHandler={currentTab === 0 ? handleAddNewNumber : undefined}
				actionTwoText='Close'
				actionTwoHandler={() => window.history.back()}
			/>
			<Box>
				<Box>
					<Tabs value={currentTab} onChange={(_, newTab) => setCurrentTab(newTab)} style={{ marginBottom: '10px' }}>
						<Tab sx={tabStyle} label="DNC List Numbers" id={0} />
						<Tab sx={tabStyle} label='Upload DNC Numbers' id={1} />
						<Tab sx={tabStyle} label='Quick Add/Remove' id={2} />
					</Tabs>
				</Box>
				<Box>
					{currentTab === 2 &&
						<Box>
							<Box>
								<TextAreaField
									sx={{ width: '100%', height: '150px' }}
									label='Numbers'
									placeholder='Enter numbers here'
									onChange={(e) => multiBoxRef.current = e.target.value}
								/>
							</Box>
							<Box>
								<Box>
									<TextField
										label='Delimiter'
										variant='standard'
										color='primary'
										value={delimiter}
										onChange={e => setDelimiter(e.target.value)}
										error={delimiter.trim() === ''}
										helperText={delimiter.trim() === '' && <>A delimiter is required</>}
										InputProps={{
											endAdornment: (
												<InputAdornment position='end'>
													<Tooltip title='Type \r\n to designate a newline as the delimiter'>
														<InfoOutlined color='primary' />
													</Tooltip>
												</InputAdornment>
											)
										}} />
								</Box>
								<Box item>
									<FormControlLabel
										control={<Switch color='primary' checked={skipInvalidNumbers} onChange={() => setSkipInvalidNumbers(!skipInvalidNumbers)} />}
										label='Skip Invalid Numbers' />
								</Box>
							</Box>
							<Box display="flex">
								<Box >
									<Button sx={actionOneButtonStyle} onClick={handleAdd} disabled={adding || deleting || loading || !selDncList}>Add</Button>
								</Box>
								<Box item>
									<Button sx={actionTwoButtonStyle} onClick={handleRemove} disabled={adding || deleting || loading || !selDncList}>Remove</Button>
								</Box>
							</Box>
						</Box>}
					{currentTab === 1 &&
						<Box>
							<Box display='flex' gap={1} alignItems='center'>
								<InfoOutlined color='primary' />
								<Typography variant='body1'>Any column headers containing the word <i>phone</i> will be added</Typography>
							</Box>
							<Box>
								<StorageManager
									displayText={{
										dropFilesText: 'Drop file here or'
									}}
									acceptedFileTypes={['.csv']}
									path={({ identityId }) => `private/${identityId}/`}
									maxFileCount={1}
									autoUpload={false}
									onUploadSuccess={(file) => {
										handleUpload(file.key);
									}

									}
								/>
							</Box>
						</Box>}
					{currentTab === 0 &&
						<Box>
							<Box item container direction="row" spacing={2}>
								<Box item style={{ flexGrow: 1, height: '100%' }}>
									<MaterialTable
										tableRef={numberTableRef}
										title=""
										isLoading={loading}
										columns={[
											{ title: 'Number', field: 'phoneNumber', validate: rowData => isNumberValid(rowData.phoneNumber) && (selDncList !== null) },
											{ title: 'Created', field: 'createdAt', render: rowData => rowData?.createdAt ? (new Date(rowData.createdAt)).toLocaleString() : (new Date()).toLocaleString(), editable: 'never' }
										]}
										data={dncs}
										options={{
											selection: true,
											showSelectAllCheckbox: true,
											actionsColumnIndex: -1,
											pageSize: 10,
											padding: 'dense',
											addRowPosition: 'first',
											searchFieldStyle: {
												marginBottom: '16px',
												marginleft: '-28px',
											},
											headerStyle: {
												borderTop: '1px solid #e0e0e0',
												padding: '16px',
											},
											searchFieldVariant: 'outlined',
											paginationType: 'stepped',
										}}
										localization={{
											toolbar: {
												searchPlaceholder: 'Search first 1000 items...',
												searchTooltip: 'Search first 1000 items...'
											}
										}}
										components={{
											Action: props => {
												if (props.action.tooltip !== "Add") return <MTableAction {...props} />;
												else return null;
											},
											Container: (props) => <Box {...props} elevation={0} />,
										}}
										icons={{
											Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} color='primary' />),
										}}
										actions={[
											{
												disabled: !selDncList,
												tooltip: 'Remove All Selected Numbers',
												icon: 'delete',
												onClick: async (_, data) => {
													setSelectionToDelete(data);
													setConfirmDeleteSelection(true);
												}
											}
										]}
										editable={{
											isEditable: selDncList === null,
											onRowAdd: newData => new Promise(async (resolve, reject) => {
												try {
													const result = await client.graphql({
														query: createDnc,
														variables: {
															input: {
																phoneNumber: phone(newData.phoneNumber, { validateMobilePrefix: IS_PROD }).phoneNumber,
																dncList: selDncList.id,
																tenant: userContext.tenantId
															}
														}
													})

													setDncs([...dncs, result.data.createDNC]);
													resolve();
												} catch (err) {
													console.log(err);
													reject();
												}
											}),
											onRowDelete: toDelete => new Promise(async (resolve, reject) => {
												try {
													await client.graphql({
														query: deleteDnc,
														variables: {
															input: {
																id: toDelete.id
															}
														}
													});

													enqueueSnackbar("Number deleted", { variant: 'success' });
													dncs.splice(toDelete.tableData.id, 1);
													setDncs([...dncs]);
													resolve();
												} catch (err) {
													console.log(err);
													enqueueSnackbar("Unable to delete number", { variant: 'error' });
													reject();
												}
											})
										}}
									/>
								</Box>
							</Box>
						</Box>}
				</Box>
			</Box>
			<ConfirmDialog
				open={confirmDeleteSelection}
				title="Delete Selection"
				description="Are you sure you want to delete this selection of numbers?"
				actionOneText="Confirm"
				actionOneHandler={() => handleConfirmDeleteSelection(selectionToDelete)}
				actionTwoText="Cancel"
				actionTwoHandler={() => setConfirmDeleteSelection(false)} />
		</Box >
	);
}
