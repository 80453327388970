import { useEffect, useState, useContext, useRef } from "react";
import { UserContext } from "../../contexts/UserContext";
import CampaignStatusBreakdown from "./campaignStatusBreakdown";
import {
	EqualizerOutlined,
	PhoneOutlined,
	TextsmsOutlined,
	MailOutlined,
	NorthEastOutlined,
	SouthEastOutlined,
	RefreshOutlined,
	InfoOutlined,
} from "@mui/icons-material";
import {
	Table,
	TableBody,
	Paper,
	TableCell,
	Grid,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Box,
	ToggleButtonGroup,
	ToggleButton,
	Card,
	CardContent,
	Typography,
	CardHeader,
	LinearProgress,
	Button,
	DialogTitle,
	DialogContent,
	TextField,
	Dialog,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	DialogActions,
	IconButton,
	CardActions
} from '@mui/material';
import { generateClient, post } from "aws-amplify/api";
import { fetchAuthSession } from "aws-amplify/auth";
import { esQuery } from "src/graphql/queries";
import moment from "moment";
import { Divider } from "@aws-amplify/ui-react";
import ActiveCalls from "./activeCalls";
import CallBacks from "./callBacks";
import { Gauge } from "@mui/x-charts";
import { actionOneButtonStyle, actionTwoButtonStyle } from "src/theme";
import PenetrationDialog from "./penetrationDialog";
import CampaignCallDetailsDialog from "./campaignCallDetails";

const CampaignStatus = function (props) {
	const client = generateClient();
	const userContext = useContext(UserContext);
	const [campaignStatus, setCampaignStatus] = useState();
	const [channel, setChannel] = useState("voice");

	const [totalContacts, setTotalContacts] = useState();
	const [completedContacts, setCompletedContacts] = useState();
	const [percentageComplete, setPercentageComplete] = useState();
	const [activeCalls, setActiveCalls] = useState();
	const [callsInProgress, setCallsInProgress] = useState(0);

	const [needsApproval, setNeedsApproval] = useState(0);
	const [dispositionCount, setDispositionCount] = useState(0);
	const [callBackCount, setCallBackCount] = useState(0);
	const [smsSent, setSMSSent] = useState();

	const [todayCalls, setTodayCalls] = useState();
	const [callDiff, setCallDiff] = useState();

	const [showBreakdown, setShowBreakdown] = useState(false);
	const [breakdownId, setBreakdownId] = useState();

	const [showPriority, setShowPriority] = useState(false);
	const [showCallDetails, setShowCallDetails] = useState(false);
	const [showDetailType, setShowDetailType] = useState('disposition');

	const [showActiveCalls, setShowActiveCalls] = useState(false);
	const [showCallBacks, setShowCallBacks] = useState(false);

	const [dispositions, setDispositions] = useState();
	const [agents, setAgents] = useState();
	const [rawDispoData, setRawDispoData] = useState();

	const [newReport, setNewReport] = useState({
		report: '',
		startDateTime: '',
		endDateTime: '',
		aggType: 'daily',
		email: '',
	});
	const [showReportDialog, setShowReportDialog] = useState(false);
	const [refreshTime, setRefreshTime] = useState();

	const systemAgents = ['0000000', 'System', 'SS Service']

	const [connectionRate, setConnectionRate] = useState(0);
	const [systemDispositions, setSystemDispositions] = useState(0);
	const [agentDispositions, setAgentDispositions] = useState(0);

	const [freshCount, setFreshCount] = useState(0);


	useEffect(() => {
		//If there is configuration for the export SFTP then allow it as an action.
		if (props.campaign) {
			// console.log(props.campaign);
			getCampaignStatus();
			const interval = setInterval(() => {
				getCampaignStatus();
			}, 30000);
			return () => clearInterval(interval);
		}
	}, [props.campaign]);

	function getCallHistoryQuery(campaignId, startTime, endTime) {
		return {
			"aggs": {
				"disposition": {
					"terms": {
						"field": "disposition.keyword",
						"size": 2147483647
					},
					"aggs": {
						"agent": {
							"terms": {
								"field": "agent.keyword",
								"size": 2147483647
							}
						}
					}
				}
			},
			"query": {
				"bool": {
					"must": [
						{
							"term": {
								"tenant.keyword": userContext.tenantId
							}
						},
						{
							"match_phrase": {
								"type": "Dial"
							}
						},
						{
							"match_phrase": {
								"activityLogCampaignId": campaignId
							}
						},
						{
							"range": {
								"createdAt": {
									"gte": startTime,
									"lte": endTime
								}
							}
						}
					],
					"must_not": [
							{
									"match_phrase": {
											"disposition": "Cadence Exhaustion - Retry Increment"
									}
							}
					]
				}
			},
			"size": 0
		}
	}

	async function getCampaignCallHistory(campaignId) {
		try {
			let promises = [];
			promises.push(client.graphql({
				query: esQuery,
				variables: {
					model: 'activitylog',
					action: '_search',
					query: JSON.stringify(getCallHistoryQuery(campaignId, moment().startOf('day').toISOString(), moment().toISOString())),
				},
			}));
			promises.push(client.graphql({
				query: esQuery,
				variables: {
					model: 'activitylog',
					action: '_search',
					query: JSON.stringify(getCallHistoryQuery(campaignId, moment().startOf('day').subtract(1, 'days').toISOString(), moment().subtract(1, 'days').toISOString())),
				},
			}));
			const data = await Promise.all(promises);

			const today = JSON.parse(data[0].data.esQuery);
			const yesterday = JSON.parse(data[1].data.esQuery);

			const diff = today.hits.total - yesterday.hits.total;

			// console.log(today);

			setTodayCalls(today.hits.total);
			setCallDiff(diff);

			let dispositionData = [];
			let agentData = [];

			if (today?.aggregations?.disposition?.buckets) {
				for (const disposition of today?.aggregations?.disposition?.buckets) {
					dispositionData.push({ disposition: disposition.key, count: disposition.doc_count });
					for (const agent of disposition?.agent?.buckets) {
						// Only push unique agents always update count
						const index = agentData.findIndex(a => a.agent === agent.key);
						if (index === -1) {
							agentData.push({ agent: agent.key, count: agent.doc_count });
						} else {
							agentData[index].count += agent.doc_count;
						}
					}
				}
			}

			dispositionData.sort((a, b) => b.count - a.count);
			agentData.sort((a, b) => b.count - a.count);

			// Connection Rate is the number of calls that have been dispositioned by a non system agent divided by the total number of calls
			if (today?.hits?.total !== 0) {
				let nonSystemDialCount = 0;
				for (const agent of agentData) {
					if (!systemAgents.includes(agent.agent)) {
						nonSystemDialCount += agent.count;
					}
				}

				setAgentDispositions(nonSystemDialCount);
				setSystemDispositions(today?.hits?.total - nonSystemDialCount);

				const estimatedConnectionRate = (nonSystemDialCount / today?.hits?.total) * 100;
				setConnectionRate(estimatedConnectionRate);
			}

			setDispositions(dispositionData);
			setAgents(agentData);
			setRawDispoData(today);

		} catch (error) {
			console.error(error);
		}
	}

	async function getCampaignStatus() {
		try {
			getCampaignCallHistory(props.campaign.id);
			setRefreshTime(new Date().toLocaleTimeString("en-US", { hour: '2-digit', minute: '2-digit', second: '2-digit' }));
			const data = await post({
				apiName: "cdyxoutreach",
				path: "/cache/status",
				options: {
					headers: {
						Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken}`,
						"x-api-key": userContext.apiKey,
					},
					body: {
						campaignId: props.campaign.id,
						channel: channel,
					},
				},
			}).response;

			const getData = await data.body.json();

			//Setting Retrieved Data
			setTotalContacts(getData.totalContacts);

			//CompletedContacts && totalFresh

			let totalFresh = 0;
			let completed = 0;
			for (const segment of getData.campaignStats) {
				completed = completed + segment.completed;
				totalFresh = totalFresh + segment.fresh;
			}
			setCompletedContacts(completed);
			setFreshCount(totalFresh);

			// CompletedPercentage
			let percentage = '-';
			if (getData.totalContacts > 0) {
				percentage = ((completed / getData.totalContacts) * 100).toFixed(0);
			}
			setPercentageComplete(percentage);
			setActiveCalls(getData.active ? getData.active : 0);
			setCallsInProgress(getData.inProgress ? getData.inProgress : 0);


			if (props?.campaign?.profile?.clickToCall) {
				setNeedsApproval(getData.needsApproval ? getData.needsApproval : 0);
			}


			setCallBackCount(getData.callBacks ? getData.callBacks : 0);
			setDispositionCount(getData.dispositioning ? getData.dispositioning : 0);
			setSMSSent(getData.smsSent ? getData.smsSent : 0);
			setCampaignStatus([...getData.campaignStats]);
		} catch (err) {
			console.error(err);
		}
	}

	const toggleChannel = (event, value) => {
		if (value) {
			setChannel(value);
		}
	};

	function getTopAgents() {
		// Get top 5 agents by call count excluding system agents
		const topAgents = [];
		const temptAgents = agents.filter(agent => !systemAgents.includes(agent.agent));
		temptAgents.sort((a, b) => b.count - a.count);
		for (let i = 0; i < 5; i++) {
			topAgents.push(temptAgents[i]);
		}
		return topAgents;
	}

	function getTopDispositions() {
		// List top 5 dispositions by count
		const topDispositions = [];
		dispositions.sort((a, b) => b.count - a.count);
		for (let i = 0; i < 5; i++) {
			topDispositions.push(dispositions[i]);
		}
		return topDispositions;
	}

	return (
		<>
			{campaignStatus && <Box display="flex" flexDirection="column" gap={4}>
				<Box display="grid" gridTemplateColumns="1fr 1fr" gap={4} alignItems="stretch">
					<Card elevation={0}
						sx={{
							height: '100%',
							backgroundColor: 'primary.light',
							borderRadius: '10px',
						}}
					>
						<CardContent>
							<Box display="flex" flexDirection="column" gap={2} sx={{ padding: 2 }}>
								<Box display="flex" justifyContent="space-between">
									<Typography variant="h5">{props.campaign.name}</Typography>
									<Box display="flex" alignItems="center" gap={1}>
										<Typography variant="subtitle2">{refreshTime}</Typography>
										<Tooltip title="Refresh"><IconButton onClick={() => getCampaignStatus()}><RefreshOutlined color="primary" /></IconButton></Tooltip>
									</Box>
								</Box>
								<Box display="flex" justifyContent="space-between" alignItems="center">
									<Box display="flex" flexDirection="column" justifyContent="space-between" gap={2}>
										<Box display="flex" gap={2}>
											<Box display="flex" flexDirection="column" alignItems="center" >
												<Typography variant="h2">{todayCalls}</Typography>
												<Typography variant="h6">Calls Today</Typography>
											</Box>
											<Divider orientation="vertical" flexItem />
											<Box display="flex" flexDirection="column" alignItems="center">
												<Box display="flex" alignItems="center" gap={1}>
													{/* {!isNaN(callDiff) && isFinite(callDiff) && <Typography variant="h4">{`${callDiff.toFixed(0)}%`}</Typography>} */}
													<Typography variant="h2">{callDiff}</Typography>
													{callDiff > 0 && <NorthEastOutlined color="primary" />}
													{callDiff < 0 && <SouthEastOutlined color="primary" />}
												</Box>
												<Box display="flex" flexDirection="column" alignItems="center">
													<Typography variant="h6">From Yesterday</Typography>
													<Typography variant="subtitle2">At this time</Typography>
												</Box>

											</Box>
										</Box>
										<Button sx={actionOneButtonStyle} onClick={() => setShowPriority(true)}>View Penetration</Button>
									</Box>
									<Box display="flex" flexDirection="column" alignItems="center" >
										<Gauge
											width={150}
											height={150}
											value={percentageComplete}
											startAngle={0}
											endAngle={360}
											innerRadius="70%"
											outerRadius="100%"
											text={`${completedContacts} / ${totalContacts}`}
										/>
										<Typography variant="subtitle2">Contacts Completed</Typography>
									</Box>
								</Box>
							</Box>
						</CardContent>
					</Card>

					<Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap={4}>
						{userContext.telephonyProvider !== 'Webex' && <Card elevation={0}
							variant="outlined"
							sx={{
								borderRadius: '10px',
							}}
						>
							{/* <CardContent sx={{ alignContent: 'center' }}> */}
								<Box display="flex" sx={{height: '100%'}} flexDirection="column" alignItems="center" justifyContent="center" >
									<Typography variant="h2">{activeCalls}</Typography>
									<Typography variant="h6">Active Calls</Typography>
									<Button disabled={activeCalls < 1} onClick={() => setShowActiveCalls(true)}>View Calls</Button>
								</Box>
							{/* </CardContent>
							<CardActions /> */}
						</Card>}

						<Card elevation={0}
							variant="outlined"
							sx={{
								borderRadius: '10px',
							}}
						>
							{/* <CardContent> */}
								<Box display="flex" sx={{height: '100%'}} justifyContent="center" flexDirection="column" alignItems="center" >
									<Typography variant="h2">{callsInProgress - activeCalls - dispositionCount}</Typography>
									<Typography variant="h6">{userContext.telephonyProvider === 'NiC' ? 'Calls in Progress' : 'In Dialer'}</Typography>
								</Box>
							{/* </CardContent> */}
						</Card>

						{/* <Card elevation={0}
							variant="outlined"
							sx={{
								borderRadius: '10px',
							}}
						>
							<CardHeader sx={{ textAlign: 'center' }} title="Connection Rate" action={
								<Tooltip title="Connection Rate is the number of calls that have been dispositioned by a non system agent divided by the total number of calls">
									<InfoOutlined color="primary" />
								</Tooltip>
							} />
							<CardContent>
								<Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" >
									<Typography variant="h2">{connectionRate.toFixed(2)}%</Typography>
									<Typography variant="subtitle2">Today</Typography>
								</Box>
							</CardContent>
						</Card> */}

						{userContext.telephonyProvider === 'NiC' && <Card elevation={0}
							variant="outlined"
							sx={{
								borderRadius: '10px',
							}}
						>
							{/* <CardContent> */}
								<Box display="flex" sx={{height: '100%'}} justifyContent="center" flexDirection="column" alignItems="center" >
									<Typography variant="h2">{dispositionCount}</Typography>
									<Typography variant="h6">Dispositioning</Typography>
								</Box>
							{/* </CardContent> */}
						</Card>}

						{props?.campaign?.profile?.clickToCall && <Card elevation={0}
							variant="outlined"
							sx={{
								borderRadius: '10px',
							}}
						>
							{/* <CardContent> */}
								<Box display="flex" sx={{height: '100%'}} justifyContent="center" flexDirection="column" alignItems="center" >
									<Typography variant="h2">{needsApproval}</Typography>
									<Typography variant="h6">Need Approval</Typography>
								</Box>
							{/* </CardContent> */}
						</Card>}

						{userContext.telephonyProvider === 'Webex' && <Card elevation={0}
							variant="outlined"
							sx={{
								borderRadius: '10px',
							}}
						>
							{/* <CardContent> */}
								<Box display="flex" sx={{height: '100%'}} justifyContent="center" flexDirection="column" alignItems="center" >
									<Typography variant="h2">{callBackCount}</Typography>
									<Typography variant="h6">Call Backs</Typography>
									<Button disabled={callBackCount < 1} onClick={() => setShowCallBacks(true)}>View Call Backs</Button>
								</Box>
							{/* </CardContent> */}
						</Card>}
					</Box>
				</Box>

				{/* Second row */}
				<Box display="grid" gridTemplateColumns="3fr 2fr 2fr" gap={2}>
					{!showBreakdown && <Box >
						<Card color="primary" elevation={0} variant="outlined" sx={{ borderRadius: '10px', height: '100%' }}>
							<CardHeader title={
								<Box display="flex" justifyContent="space-between">
									<Typography variant="h5">Inventory</Typography>
									<ToggleButtonGroup
										size="small"
										value={channel}
										exclusive
										onChange={toggleChannel}
									>
										<ToggleButton value="voice">
											<Tooltip title="View Voice Inventory">
												<PhoneOutlined color="primary" fontSize="small" />
											</Tooltip>
										</ToggleButton>
										<ToggleButton value="sms">
											<Tooltip title="View SMS Inventory">
												<TextsmsOutlined color="primary" fontSize="small" />
											</Tooltip>
										</ToggleButton>
										<ToggleButton value="email">
											<Tooltip title="View Email Inventory">
												<MailOutlined color="primary" fontSize="small" />
											</Tooltip>
										</ToggleButton>
									</ToggleButtonGroup>
								</Box>
							} />
							<CardContent>
								{campaignStatus && channel === "voice" && (
									<Box>
										<Table>
											<TableHead>
												<TableRow>
													<TableCell><Typography variant="h6">Segment</Typography></TableCell>
													<TableCell align="center">
														<Box display="flex" flexDirection="column" alignItems="center">
															<Typography variant="h6">Priority</Typography>
															<Typography variant="subtitle2">Available / Total</Typography>
														</Box>
													</TableCell>
													<TableCell align="center">
														<Box display="flex" flexDirection="column" alignItems="center">
															<Typography variant="h6">Fresh</Typography>
															<Typography variant="subtitle2">Available / Total</Typography>
														</Box>
													</TableCell>
													<TableCell align="center">
														<Box display="flex" flexDirection="column" alignItems="center">
															<Typography variant="h6">Retry</Typography>
															<Typography variant="subtitle2">Available / Total</Typography>
														</Box>
													</TableCell>
													<TableCell align="center">
														<Typography variant="h6">Details</Typography>
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{campaignStatus.map((row) => (
													<TableRow key={row.segment}>
														<TableCell component="th" scope="row">
															<Typography variant="h6">{row.segment}</Typography>
														</TableCell>
														<TableCell align="center"><Typography variant="h6">{`${row.priorityAvailable} / ${row.priority}`}</Typography></TableCell>
														<TableCell align="center"><Typography variant="h6">{`${row.availableFresh} / ${row.fresh}`}</Typography></TableCell>
														<TableCell align="center"><Typography variant="h6">{`${row.readyRetry} / ${row.retry}`}</Typography></TableCell>
														<TableCell
															onClick={() => {
																setShowBreakdown(true)
																setBreakdownId(row.segmentId)
															}}
															align="center"
														>
															<EqualizerOutlined color="primary" />
														</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
									</Box>
								)}

								{campaignStatus && channel === "sms" && (
									<Box>
										<Table>
											<TableHead>
												<TableRow>
													<TableCell><Typography variant="h6">Segment</Typography></TableCell>
													<TableCell align="center">
														<Box display="flex" flexDirection="column" alignItems="center">
															<Typography variant="h6">Fresh</Typography>
															<Typography variant="subtitle2">Available / Total</Typography>
														</Box>
													</TableCell>
													<TableCell align="center">
														<Box display="flex" flexDirection="column" alignItems="center">
															<Typography variant="h6">Retry</Typography>
															<Typography variant="subtitle2">Available / Total</Typography>
														</Box>
													</TableCell>
													<TableCell align="center">
														<Typography variant="h6">Details</Typography>
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{campaignStatus.map((row) => (
													<TableRow key={row.segment}>
														<TableCell component="th" scope="row">
															<Typography variant="h6">{row.segment}</Typography>
														</TableCell>
														<TableCell align="center"><Typography variant="h6">{`${row.smsAvailableFresh} / ${row.smsFresh}`}</Typography></TableCell>
														<TableCell align="center"><Typography variant="h6">{`${row.smsReadyRetry} / ${row.smsRetry}`}</Typography></TableCell>
														<TableCell
															onClick={() => {
																setShowBreakdown(true)
																setBreakdownId(row.segmentId)
															}}
															align="center"
														>
															<EqualizerOutlined color="primary" />
														</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
									</Box>
								)}

								{campaignStatus && channel === "email" && (
									<Box>
										<Table>
											<TableHead>
												<TableRow>
													<TableCell><Typography variant="h6">Segment</Typography></TableCell>
													<TableCell align="center">
														<Box display="flex" flexDirection="column" alignItems="center">
															<Typography variant="h6">Fresh</Typography>
															<Typography variant="subtitle2">Available / Total</Typography>
														</Box>
													</TableCell>
													<TableCell align="center">
														<Box display="flex" flexDirection="column" alignItems="center">
															<Typography variant="h6">Retry</Typography>
															<Typography variant="subtitle2">Available / Total</Typography>
														</Box>
													</TableCell>
													<TableCell align="center">
														<Typography variant="h6">Details</Typography>
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{campaignStatus.map((row) => (
													<TableRow key={row.segment}>
														<TableCell component="th" scope="row">
															<Typography variant="h6">{row.segment}</Typography>
														</TableCell>
														<TableCell align="center"><Typography variant="h6">{`${row.emailAvailableFresh} / ${row.emailFresh}`}</Typography></TableCell>
														<TableCell align="center"><Typography variant="h6">{`${row.emailReadyRetry} / ${row.emailRetry}`}</Typography></TableCell>
														<TableCell
															onClick={() => {
																setShowBreakdown(true)
																setBreakdownId(row.segmentId)
															}}
															align="center"
														>
															<EqualizerOutlined color="primary" />
														</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
									</Box>
								)}

							</CardContent>
						</Card>
					</Box>}
					{showBreakdown && <Box>
						<CampaignStatusBreakdown
							campaignId={props.campaign.id}
							segmentId={breakdownId}
							channel={channel}
							setBreakdownId={setBreakdownId}
							setShowBreakdown={setShowBreakdown}
						/>
					</Box>}

					<Card elevation={0}
						sx={{
							borderRadius: '10px',
						}}
						variant="outlined"
					>
						<CardHeader title="Call Results" subheader="Today" />
						<CardContent>
							<Box display="flex" flexDirection="column" gap={2} alignContent="space-between">
								{dispositions && getTopDispositions().map((dispo, index) => (
									<Box key={index} display="flex" justifyContent="space-between">
										<Typography variant="h6">{dispo?.disposition}</Typography>
										<Typography variant="h6">{dispo?.count}</Typography>
									</Box>
								))}
								<Button onClick={() => {
									setShowCallDetails(true);
									setShowDetailType('disposition');
								}} sx={actionTwoButtonStyle}>View More</Button>
							</Box>
						</CardContent>
					</Card>

					<Card elevation={0}
						sx={{
							borderRadius: '10px',
						}}
						variant="outlined"
					>
						<CardHeader title="Agent Call Counts" subheader="Today" />
						<CardContent>
							<Box display="flex" flexDirection="column" gap={2} alignContent="space-between">
								{/* List top 5 agents by call count excluding system agents */}
								{agents && getTopAgents().map((agent, index) => (
									<Box key={index} display="flex" justifyContent="space-between">
										<Typography variant="h6">{agent?.agent}</Typography>
										<Typography variant="h6">{agent?.count}</Typography>
									</Box>
								))}
								<Button onClick={() => {
									setShowCallDetails(true);
									setShowDetailType('agent');
								}} sx={actionTwoButtonStyle}>View More</Button>
							</Box>
						</CardContent>
						<CardActions>

						</CardActions>
					</Card>

				</Box>

			</Box>}

			{/* Dialogs */}
			<PenetrationDialog
				open={showPriority}
				campaignId={props.campaign?.id}
				totalContacts={totalContacts}
				freshCount={freshCount}
				onClose={() => setShowPriority(false)}
			/>

			<CampaignCallDetailsDialog
				open={showCallDetails}
				data={rawDispoData}
				type={showDetailType}
				onClose={() => setShowCallDetails(false)}
			/>

			{!campaignStatus && <Box display="flex" justifyContent="center" alignItems="center">
				<LinearProgress color="primary" />
			</Box>}
			<ActiveCalls
				open={showActiveCalls}
				onClose={() => setShowActiveCalls(false)}
				campaignId={props.campaign?.id}
				campaignName={props.campaign?.name}
			/>
			<CallBacks
				open={showCallBacks}
				onClose={() => setShowCallBacks(false)}
				campaignId={props.campaign?.id}
				campaignName={props.campaign?.name}
			/>
			<Dialog
				open={showReportDialog}
				onClose={() => setShowReportDialog(false)}
			>
				<DialogTitle>Reports</DialogTitle>
				<DialogContent>
					<Box display="flex" flexDirection="column" gap={2}>
						<Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
							<FormControl>
								<InputLabel id="report">Report</InputLabel>
								<Select
									labelId="report"
									label="Report"
									value={newReport.report}
									onChange={(e) => {
										setNewReport({ ...newReport, report: e.target.value, reportLength: e.target.value === 'full' ? 1 : newReport.reportLength })
									}}
								>
									<MenuItem value='full'>All Call Data</MenuItem>
									<MenuItem value='count'>Dial Counts By Day</MenuItem>
									<MenuItem value='dispositionCount'>Disposition Counts</MenuItem>
									<MenuItem value='agentCount'>Agent Dial Counts</MenuItem>
									<MenuItem value='agentDispositions'>Disposition By Agent</MenuItem>
								</Select>
							</FormControl>

							<TextField
								label="Start Date"
								type="date"
								value={newReport.startDateTime}
								onChange={(e) => setNewReport({ ...newReport, startDateTime: e.target.value })}
							/>
							<TextField
								label="End Date"
								type="date"
								value={newReport.endDateTime}
								onChange={(e) => setNewReport({ ...newReport, endDateTime: e.target.value })}
							/>
							<FormControl>
								<InputLabel id="aggType">Aggregation Type</InputLabel>
								<Select
									labelId="aggType"
									label="Aggregation Type"
									value={newReport.aggType}
									onChange={(e) => setNewReport({ ...newReport, aggType: e.target.value })}
								>
									<MenuItem value='daily'>Daily</MenuItem>
									<MenuItem value='weekly'>Weekly</MenuItem>
									<MenuItem value='monthly'>Monthly</MenuItem>
								</Select>
							</FormControl>
						</Box>

						<Box display="grid" gridTemplateColumns="1fr">
							<TextField
								label="Email"
								variant='outlined'
								type='email'
								value={newReport.email}
								onChange={e => setNewReport({ ...newReport, email: e.target.value })}
							/>
						</Box>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setShowReportDialog(false)}>Cancel</Button>
					<Button onClick={() => {
					}}>Generate</Button>
				</DialogActions>

			</Dialog>
		</>
	);
};
export default CampaignStatus;
