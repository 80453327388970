import { StorageManager } from '@aws-amplify/ui-react-storage';
import { generateClient, post, del } from 'aws-amplify/api';
import { Typography, TextField, Button, FormControlLabel, InputAdornment, Tooltip, Tabs, Tab, Box, Switch } from '@mui/material';
import { InfoOutlined, DeleteOutline } from '@mui/icons-material';
import MaterialTable, { MTableAction } from '@material-table/core';
import React, { useEffect, useContext, useState, useRef, forwardRef } from 'react';
import { UserContext } from '../../contexts/UserContext';
import { useSnackbar } from 'notistack';
import phone from 'phone';

import _ from 'lodash';
import { IS_PROD } from '../../models/globals';
import { contactFieldSuppressionByList, getContactFieldSuppressionList } from 'src/graphql/queries';
import { fetchAuthSession } from 'aws-amplify/auth';
import { createContactFieldSuppression, deleteContactFieldSuppression } from 'src/graphql/mutations';
import { ConfirmDialog } from 'src/components/confirmDialog/confirmDialog';
import { useParams } from 'react-router-dom';
import { PageAppBar } from 'src/components/pageAppBar';
import { actionOneButtonStyle, actionTwoButtonStyle, tabStyle } from 'src/theme';
import { TextAreaField } from '@aws-amplify/ui-react';

export function ContactFieldSuppression() {
	const client = generateClient();
	const userContext = useContext(UserContext);
	const { id } = useParams();
	const displayMax = 1000;
	const { enqueueSnackbar } = useSnackbar();
	const [selSuppressionList, setSelSuppressionList] = useState({});
	const [values, setValues] = useState([]);
	const [loading, setLoading] = useState(false);
	const [delimiter, setDelimiter] = useState(';');
	const [currentTab, setCurrentTab] = useState(0);
	const [deleting, setDeleting] = useState(false);
	const [adding, setAdding] = useState(false);
	const [confirmDeleteSelection, setConfirmDeleteSelection] = useState(false);
	const [selectionToDelete, setSelectionToDelete] = useState([]);
	const [filterActive, setFilterActive] = useState(false);
	const nextToken = useRef('');
	const currentToken = useRef('');
	const [filterKeyword, setFilterKeyword] = useState('');
	const [filterOperation, setFilterOperation] = useState('beginsWith');
	const [uploading, setUploading] = useState(false);
	const multiBoxRef = useRef(null);
	const numberTableRef = useRef();
	const [newNumberTrigger, setNewNumberTrigger] = useState(false);

	useEffect(() => {
		async function getData() {
			// Get the list
			const result = await client.graphql({
				query: getContactFieldSuppressionList,
				variables: { id, tenant: userContext.tenantId }
			});
			console.log(result.data.getContactFieldSuppression);
			setSelSuppressionList(result.data.getContactFieldSuppressionList);
		}
		if (id) {
			getData();
		}

	}, [id, userContext.tenantId]);

	useEffect(() => {
		if (selSuppressionList?.id) {
			getNumbers();
		}

	}, [selSuppressionList, filterActive]);

	async function getNumbers() {
		setLoading(true);

		const variables = { limit: displayMax, contactFieldSuppressionList: selSuppressionList.id };
		if (currentToken.current) {
			variables.nextToken = currentToken.current;
		}

		if (filterKeyword && filterOperation && filterActive) {
			variables.phoneNumber = { [filterOperation]: filterKeyword };
		}

		let result = await client.graphql({
			query: contactFieldSuppressionByList,
			variables: variables
		});

		setValues(result.data.contactFieldSuppressionByList.items);
		nextToken.current = result?.data?.contactFieldSuppressionByList?.nextToken;
		setLoading(false);
	}


	function getMultiNumbers() {
		let separator = delimiter.trim();
		if (separator === '\\r\\n') {
			separator = '\n';
		}

		const numberStrings = multiBoxRef.current.split(separator);


		const numbers = [];
		const strings = [];

		for (let numberString of numberStrings) {
			numberString = numberString.trim();
			numbers.push(numberString);
			strings.push(numberString);
		}

		multiBoxRef.current = strings.join(separator);

		return [true, numbers];
	}

	function formatData() {
		let numbers = [];

		if (delimiter.trim() === '') {
			enqueueSnackbar("A delimiter is required", { variant: 'error' });
			return [false, []];
		}

		if (multiBoxRef.current.trim() === '') {
			enqueueSnackbar("Please provide at least one number", { variant: 'info' });
			return [false, []];
		}

		const result = getMultiNumbers();
		numbers = result[1];

		if (!result[0]) {
			enqueueSnackbar("Invalid number found.", { variant: 'error' });
			return [false, []];
		}


		if (numbers.length === 0) {
			enqueueSnackbar("No numbers to process", { variant: 'info' });
			return [false, []];
		}

		return [true, numbers];
	}

	async function handleAdd() {
		setAdding(true);
		const result = formatData();
		if (result[0]) {
			await post({
				apiName: 'cdyxoutreach',
				path: '/dnc/field-suppression',
				options: {
					headers: {
						Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken}`,
						'x-api-key': userContext.apiKey
					},
					body: {
						tenant: userContext.tenantId,
						values: result[1],
						listId: selSuppressionList.id
					}
				}
			}).response;
			await getNumbers();
		}
		setAdding(false);
		setCurrentTab(0);
	}

	async function handleRemove() {
		setDeleting(true);
		const result = formatData();
		if (result[0]) {
			const response = await unsafeDeleteListOfNumbers(result[1]);
			if (typeof (response) === 'object' && "numbers" in (response ?? {})) {
				enqueueSnackbar("Some numbers were unable to be deleted", { variant: 'warning' });
			}

			await getNumbers();
		}
		setDeleting(false);
	}

	async function unsafeDeleteListOfNumbers(numbers) {
		return await del({
			apiName: 'cdyxoutreach',
			path: '/dnc/field-suppression',
			options: {
				headers: {
					Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken}`,
					'x-api-key': userContext.apiKey
				}
			},
			body: {
				tenant: userContext.tenantId,
				numbers: numbers,
				listId: selSuppressionList.id
			}
		}).response;
	}

	async function handleUpload(path) {
		try {
			const urlObject = await getUrl({
				path,
				options: {
					level: 'private',
				},
			});

			const url = urlObject.url.toString();
			const session = await fetchAuthSession();

			await post({
				apiName: 'cdyxoutreach',
				path: '/dnc/field-suppression/import',
				options: {
					headers: {
						Authorization: `Bearer ${session.tokens.idToken}`,
						'x-api-key': userContext.apiKey,
					},
					body: {
						tenant: userContext.tenantId,
						url,
						email: (await fetchAuthSession()).attributes.email,
						listId: selSuppressionList.id
					}
				}
			}).response;

			enqueueSnackbar("File uploaded successfully. An email will be sent upon import completion.", { variant: 'success' });
			setCurrentTab(0);
		} catch (err) {
			console.log(err);
			enqueueSnackbar("There was an error uploading and importing the file.", { variant: 'error' });
		}

		setUploading(false);
	}

	async function handleConfirmDeleteSelection(data) {
		setLoading(true);
		setConfirmDeleteSelection(false);
		setDeleting(true);
		await unsafeDeleteListOfNumbers(data.map(x => x.phoneNumber));
		const deletedHash = new Set(data.map(x => x.id));
		setValues(values.filter(x => !deletedHash.has(x.id)));
		setDeleting(false);
		setLoading(false);
	}

	function handleAddNewNumber() {
		numberTableRef.current.state.showAddRow = true;
		setNewNumberTrigger(!newNumberTrigger);
	}

	return (
		<Box>
			<PageAppBar
				title={selSuppressionList?.name ?? 'Contact Field Suppression'}
				description={`Contact's will be suppressed from this list if their ${selSuppressionList?.contactField} field
				 matches the value`}
				actionOneText={currentTab === 0 ? '+ Value' : ''}
				actionOneHandler={currentTab === 0 ? handleAddNewNumber : undefined}
				actionTwoText='Close'
				actionTwoHandler={() => window.history.back()}
			/>
			<Box>
				<Box>
					<Tabs value={currentTab} onChange={(_, newTab) => setCurrentTab(newTab)} style={{ marginBottom: '10px' }}>
						<Tab sx={tabStyle} label="Suppressed Values" id={0} />
						<Tab sx={tabStyle} label='Upload Values' id={1} />
						<Tab sx={tabStyle} label='Quick Add/Remove' id={2} />
					</Tabs>
				</Box>
				<Box>
					{currentTab === 2 &&
						<Box>
							<Box>
								<TextAreaField
									sx={{ width: '100%', height: '150px' }}
									label='Suppression Values'
									placeholder='Enter values here'
									onChange={(e) => multiBoxRef.current = e.target.value}
								/>
							</Box>
							<Box>
								<Box>
									<TextField
										label='Delimiter'
										variant='standard'
										color='primary'
										value={delimiter}
										onChange={e => setDelimiter(e.target.value)}
										error={delimiter.trim() === ''}
										helperText={delimiter.trim() === '' && <>A delimiter is required</>}
										InputProps={{
											endAdornment: (
												<InputAdornment position='end'>
													<Tooltip title='Type \r\n to designate a newline as the delimiter'>
														<InfoOutlined color='primary' />
													</Tooltip>
												</InputAdornment>
											)
										}} />
								</Box>
							</Box>
							<Box display="flex">
								<Box >
									<Button sx={actionOneButtonStyle} onClick={handleAdd} disabled={adding || deleting || loading || !selSuppressionList}>Add</Button>
								</Box>
								<Box item>
									<Button sx={actionTwoButtonStyle} onClick={handleRemove} disabled={adding || deleting || loading || !selSuppressionList}>Remove</Button>
								</Box>
							</Box>
						</Box>}
					{currentTab === 1 &&
						<Box>
							<Box display='flex' gap={1} alignItems='center'>
								<InfoOutlined color='primary' />
								<Typography variant='body1'>Any column headers containing the word <i>phone</i> will be added</Typography>
							</Box>
							<Box>
								<StorageManager
									displayText={{
										dropFilesText: 'Drop file here or'
									}}
									acceptedFileTypes={['.csv']}
									path={({ identityId }) => `private/${identityId}/`}
									maxFileCount={1}
									autoUpload={false}
									onUploadSuccess={(file) => {
										handleUpload(file.key);
									}

									}
								/>
							</Box>
						</Box>}
					{currentTab === 0 &&
						<Box>
							<Box item container direction="row" spacing={2}>
								<Box item style={{ flexGrow: 1, height: '100%' }}>
									<MaterialTable
										tableRef={numberTableRef}
										title=""
										isLoading={loading}
										columns={[
											{ title: 'Value', field: 'contactFieldValue' },
											{ title: 'Created', field: 'createdAt', render: rowData => rowData?.createdAt ? (new Date(rowData.createdAt)).toLocaleString() : (new Date()).toLocaleString(), editable: 'never' }
										]}
										data={values}
										options={{
											selection: true,
											showSelectAllCheckbox: true,
											actionsColumnIndex: -1,
											pageSize: 10,
											padding: 'dense',
											addRowPosition: 'first',
											searchFieldStyle: {
												marginBottom: '16px',
												marginleft: '-28px',
											},
											headerStyle: {
												borderTop: '1px solid #e0e0e0',
												padding: '16px',
											},
											searchFieldVariant: 'outlined',
											paginationType: 'stepped',
										}}
										localization={{
											toolbar: {
												searchPlaceholder: 'Search first 1000 items...',
												searchTooltip: 'Search first 1000 items...'
											}
										}}
										components={{
											Action: props => {
												if (props.action.tooltip !== "Add") return <MTableAction {...props} />;
												else return null;
											},
											Container: (props) => <Box {...props} elevation={0} />,
										}}
										icons={{
											Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} color='primary' />),
										}}
										actions={[
											{
												disabled: !selSuppressionList,
												tooltip: 'Remove All Selected Numbers',
												icon: 'delete',
												onClick: async (_, data) => {
													setSelectionToDelete(data);
													setConfirmDeleteSelection(true);
												}
											}
										]}
										editable={{
											isEditable: selSuppressionList === null,
											onRowAdd: newData => new Promise(async (resolve, reject) => {
												try {
													const result = await client.graphql({
														query: createContactFieldSuppression,
														variables: {
															input: {
																contactFieldSuppressionList: selSuppressionList.id,
																contactFieldValue: newData.contactFieldValue,
																tenant: userContext.tenantId
															}
														}
													})

													setValues([...values, result.data.createContactFieldSuppression]);
													resolve();
												} catch (err) {
													console.log(err);
													reject();
												}
											}),
											onRowDelete: toDelete => new Promise(async (resolve, reject) => {
												try {
													await client.graphql({
														query: deleteContactFieldSuppression,
														variables: {
															input: {
																id: toDelete.id
															}
														}
													});

													enqueueSnackbar("Number deleted", { variant: 'success' });
													values.splice(toDelete.tableData.id, 1);
													setValues([...values]);
													resolve();
												} catch (err) {
													console.log(err);
													enqueueSnackbar("Unable to delete number", { variant: 'error' });
													reject();
												}
											})
										}}
									/>
								</Box>
							</Box>
						</Box>}
				</Box>
			</Box>
			<ConfirmDialog
				open={confirmDeleteSelection}
				title="Delete Selection"
				description="Are you sure you want to delete this selection of numbers?"
				actionOneText="Confirm"
				actionOneHandler={() => handleConfirmDeleteSelection(selectionToDelete)}
				actionTwoText="Cancel"
				actionTwoHandler={() => setConfirmDeleteSelection(false)} />
		</Box >
	);
}
